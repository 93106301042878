import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addPendingDeleteGarage, deleteGarageFromFB } from '../../firebase';
import {UserAuth} from "../Context/AuthContext";
import LoadingSpinner from '../LoadingWheel/LoadingWheel';

function DeleteGarageModal(props) {

  const [isSending, setIsSending] = useState(false)

  const { 
    garageName, 
    show, 
    setShowDeleteModal,
    setOpenSimpleSnackbar, 
    setSnackbarInfo, 
    companyForUpdate, 
    userName, 
    userCompany, 
    companiesAndGarages, 
    setCompaniesAndGarages
  } = props

  const {user, userStatusValue, setNumberOfNotifications} = UserAuth()

  const handleClose = async() => setShowDeleteModal(false);

  const confirmDeleteHandler = async (e) => {
    setIsSending(true)
    try{
        if(userStatusValue > 1){
          try{
          //delete from firebase
          deleteGarageFromFB(companyForUpdate, garageName, userName, userCompany)
          setIsSending(false)
          setShowDeleteModal(false)
          const filteredGarages = {
            ...companiesAndGarages,
            [companyForUpdate]: companiesAndGarages[companyForUpdate].filter(garage => garage !== garageName)
          };
          setCompaniesAndGarages(filteredGarages)
          return
          }catch(e){
            console.log(e)
          }
        }else{
        //for non super admins, a delete request will be added to later be viewed by a super-admin and accepted or rejected
        let response = await addPendingDeleteGarage(companyForUpdate, garageName, user.email, userName, userCompany)
        handleClose()
        if(response === true){
          setIsSending(false)
          // alert("Request Submitted for Garage Deletion!")
          setNumberOfNotifications(prevNumber => prevNumber + 1)
          setSnackbarInfo({
            text: "Delete Request Sent!",
            severity: 'success'
          })
        }else{
          // alert("Error: Couldn't send Delete Request")
          setSnackbarInfo({
            text: "Delete Request Could Not be Sent",
            serverity: 'error'
          })
        }
        setOpenSimpleSnackbar(true)
      }
    }catch(e){
        alert("Error:", e.message)
    }
    
  }
  
  return (

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      {
      isSending ?
      <>{
        userStatusValue > 1 ?
        'Deleting Garage'
        :
        'Sending Delete Garage Request...'
      }
        <LoadingSpinner/>
      </>
      :
      <>
      {userStatusValue > 1 ?
        <Modal.Body>Are you sure you want to delete: {garageName} Garage?</Modal.Body>
        :
        <Modal.Body>Are you sure you want to request to delete: {garageName} Garage?</Modal.Body>
      }
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={confirmDeleteHandler}>
          Confirm
        </Button>
      </Modal.Footer>
    </>}
    </Modal>
      
  );
}

export default DeleteGarageModal;