import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import SummaryPage from "./pages/SummaryPage/SummaryPage";
import ReportSelectPage from "./pages/ReportSelectPage/ReportSelectPage";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { AuthContextProvider } from "./components/Context/AuthContext";
import WaitTimePage from "./pages/WaitTimesPage/WaitTimePage";
import SchemehornFilteredPage from "./pages/SchemehornFilteredPage/SchemehornFilteredPage";
import SchemehornDailyPage from "./pages/SchemehornDailyPage/SchemehornDailyPage";
import AutomatedFilteredReportPage from "./pages/AutomatedFilteredReportPage/AutomatedFilteredReportPage";
import AutomatedDailyReportPage from "./pages/AutomatedDailyReportPage/AutomatedDailyReportPage";
import FilterByRatePage from "./pages/FilterByRatePage/FilterByRatePage"
import MonthliesPage from "./pages/MonthliesPage/MonthliesPage";
import CreateUserPage from "./pages/CreateUserPage/CreateUserPage";
import AdminMainPanelPage from "./pages/AdminMainPanelPage/AdminMainPanelPage";
import EditOrDeleteUserPage from "./pages/EditOrDeleteUserPage/EditOrDeleteUserPage";
import AdminGaragesPage from "./pages/AdminGaragesPage/AdminGaragesPage";
import UnauthorizedPage from "./pages/UnauthorizedPage/UnauthorizedPage";
import CarDetailsPage from "./pages/CarDetailsPage/CarDetailsPage";
import MonthliesRevenuePage from "./pages/MonthliesRevenuePage/MonthliesRevenuePage";
import SchemehornMonthlyRevenuePage from "./pages/SchemehornMonthlyRevenuePage/SchemehornMonthlyRevenuePage";
import FormFolderPage from "./pages/FormFolderPage/FormFolderPage";
import Forms from "./components/Forms/Forms";
import FormComponent from "./components/FormComponent/FormComponent";
import ResourcesFolderPage from "./pages/ResourcesFolderPage/ResourcesFolderPage";
import ResourceFiles from "./pages/ResourceFiles/ResourceFiles";
import CreateSchedulePage from "./pages/CreateSchedulePage/CreateSchedulePage";
import SchedulePanelPage from './pages/SchedulePanelPage/SchedulePanelPage';
import ViewSchedulesPage from "./pages/ViewSchedulesPage/ViewSchedulesPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import ErrorLogsPage from "./pages/ErrorLogsPage/ErrorLogsPage";
import LexHotelFilteredReportPage from "./pages/LexHotelReportPage/LexHotelFilteredReportPage";
import PalletDataPage from "./pages/PalletDataPage/PalletDataPage";
import NotificationPage from "./pages/NotificationsPage/NotificationPage";
import LogsPage from './pages/LogsPage/LogsPage';

function App() {

  return (
      <div className="App">
        <AuthContextProvider>
          <Routes>
            <Route exact path="/" element={<Navigate to="/login" />} />

            {/* login page */}
            <Route path="/login"
              element={<LoginPage />}
            />
            {/* list of form folders page */}
            <Route
              path="/forgotpassword"
              element={
                <ForgotPasswordPage />
              }
            />
            <Route path='unauthorized' element={<UnauthorizedPage />} />

            {/* list of form folders page */}
            <Route
              path="/forms"
              element={
                <ProtectedRoute>
                  <FormFolderPage
                  />
                </ProtectedRoute>
              }
            />

            {/* lists all forms inside selected folder */}
            <Route
              path="/forms/:folderName"
              element={
                <ProtectedRoute>
                  <Forms
                  />
                </ProtectedRoute>
              }
            />

            {/* navigate to actual form component inside folder*/}
            <Route
              path="/forms/:folderName/:formTitle"
              element={
                <ProtectedRoute>
                  <FormComponent
                  />
                </ProtectedRoute>
              }
            />

            {/* list of folders in resources page */}
            <Route
              path="/resources"
              element={
                <ProtectedRoute>
                  <ResourcesFolderPage
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <NotificationPage
                  />
                </ProtectedRoute>
              }
            />

            {/* lists all files inside selected resource folder */}
            <Route
              path="/resources/:folderName"
              element={
                <ProtectedRoute>
                  <ResourceFiles
                  />
                </ProtectedRoute>
              }
            />

            {/* list of garages to select*/}
            <Route
              path="/welcome"
              element={
                <ProtectedRoute>
                  <SummaryPage
                  />
                </ProtectedRoute>
              }
            />

            {/* create new admin */}
            <Route
              path="/admin/Create"
              element={
                <ProtectedRoute>
                  <CreateUserPage
                  />
                </ProtectedRoute>
              }
            />
            {/* create new admin */}
            <Route
              path="/admin/garages"
              element={
                <ProtectedRoute>
                  <AdminGaragesPage
                  />
                </ProtectedRoute>
              }
            />

            {/* update admin */}
            <Route
              path="/admin/update"
              element={
                <ProtectedRoute>
                  <EditOrDeleteUserPage
                  />
                </ProtectedRoute>
              }
            />

            {/* admin schedule */}
            <Route
              path="/admin/schedules"
              element={
                <ProtectedRoute>
                  <SchedulePanelPage
                  />
                </ProtectedRoute>
              }
            />
            {/* view logs */}
            <Route
              path="/admin/logs"
              element={
                <ProtectedRoute>
                  <LogsPage
                  />
                </ProtectedRoute>
              }
            />
            {/* admin create schedule */}
            <Route
              path="/admin/schedules/create"
              element={
                <ProtectedRoute>
                  <CreateSchedulePage
                  />
                </ProtectedRoute>
              }
            />

            {/* admin view schedules */}
            <Route
              path="/admin/schedules/view"
              element={
                <ProtectedRoute>
                  <ViewSchedulesPage
                  />
                </ProtectedRoute>
              }
            />

            {/* admin portal*/}
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminMainPanelPage
                  />
                </ProtectedRoute>
              }
            />

            {/* admin portal for garages*/}
            <Route
              path="/admin/garages"
              element={
                <ProtectedRoute>
                  <AdminGaragesPage
                  />
                </ProtectedRoute>
              }
            />

            {/* list of available reports for each automated garage */}
            <Route
              path="/reportSelect/:garageName"
              element={
                <ProtectedRoute>
                  <ReportSelectPage
                  />
                </ProtectedRoute>
              }
            />

            {/* schemehorn filtered report */}
            <Route
              exact
              path="/reportSelect/schemehorn/filtered"
              element={
                <ProtectedRoute>
                  <SchemehornFilteredPage
                  />
                </ProtectedRoute>
              }
            />

            {/* schemehorn daily report  (24 hour report)*/}
            <Route
              exact
              path="/reportSelect/schemehorn/daily"
              element={
                <ProtectedRoute>
                  <SchemehornDailyPage
                  />
                </ProtectedRoute>
              }
            />
            {/* schemehorn monthly revenue report */}
            <Route
              exact
              path="/reportSelect/schemehorn/revenue"
              element={
                <ProtectedRoute>
                  <SchemehornMonthlyRevenuePage
                  />
                </ProtectedRoute>
              }
            />

            {/* all automated garages (baxter, vanvorst, waverly, 24th Street) filtered reports */}
            <Route
              path="/reportSelect/:garageName/filtered"
              element={
                <ProtectedRoute>
                  <AutomatedFilteredReportPage
                  />
                </ProtectedRoute>
              }
            />

            {/* All automated Garages Daily report (24 hours) */}
            <Route
              path="/reportSelect/:garageName/daily"
              element={
                <ProtectedRoute>
                  <AutomatedDailyReportPage
                  />
                </ProtectedRoute>
              }
            />

            {/* Lex Hotel Report */}
            <Route
              path="/reportSelect/:garageName/lex"
              element={
                <ProtectedRoute>
                  <LexHotelFilteredReportPage
                  />
                </ProtectedRoute>
              }
            />

            {/* Pallet Report */}
            <Route
              path="/reportSelect/:garageName/pallet"
              element={
                <ProtectedRoute>
                  <PalletDataPage
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reportSelect/:garageName/daily/carDetails/:carId"
              element={
                <ProtectedRoute>
                  <CarDetailsPage
                  />
                </ProtectedRoute>
              }
            />

            {/* All automated Garages wait-times report */}
            <Route
              path='/reportSelect/:garageName/wait'
              element={
                <ProtectedRoute>
                  <WaitTimePage
                  />
                </ProtectedRoute>
              }
            />

            {/* All automated Garages wait-times report */}
            <Route
              path='/reportSelect/:garageName/errors'
              element={
                // <ProtectedRoute>
                <ErrorLogsPage
                />
                // </ProtectedRoute>
              }
            />

           

            {/* All automated Garages filter-by-rate report */}
            <Route
              path="/reportSelect/:garageName/filter"
              element={
                <ProtectedRoute>
                  <FilterByRatePage
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reportSelect/:garageName/monthlies"
              element={
                <ProtectedRoute>
                  <MonthliesPage
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="/reportSelect/:garageName/monthlies/carDetails/:carId"
              element={
                <ProtectedRoute>
                  <CarDetailsPage
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reportSelect/:garageName/revenue"
              element={
                <ProtectedRoute>
                  <MonthliesRevenuePage
                  />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthContextProvider>
      </div>
  );
}

export default App;
