import React, { useState, useEffect } from 'react'
import './EditOrDeleteUserPage.scss';
import Navigation from '../../components/Navigation/Navigation';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import editIcon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/deleteIcon.png';
import DeleteUsersModal from '../../components/DeleteUsersModal/DeleteUsersModal';
import EditUserModal from '../../components/EditUserModal/EditUserModal';
import { getAllUsersForAllCompanies } from '../../firebaseHelpers/userHelpers';
import { UserAuth } from '../../components/Context/AuthContext';

export default function EditOrDeleteUserPage() {
    const [isLoading, setIsLoading] = useState(true)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [userData, setUserData] = useState({})
    const [userToDelete, setUserToDelete] = useState(null)
    const [userToEdit, setUserToEdit] = useState(null)

    const { userName, userCompany } = UserAuth()

    const getUserData = async () => {
        let userData;
        try {
            userData = await getAllUsersForAllCompanies()
            console.log(userData)
            setUserData(userData)
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        //get the users from from the Firestore DB
        getUserData()
    }, [])

    const handleEditUser = (user) => {
        console.log(user)
        setUserToEdit(user)
        setShowEditModal(true);
    }

    const handleDeleteUser = (user) => {
        setUserToDelete(user)
        setShowDeleteModal(true);
    }

    const handleClose = () => {
        setShowDeleteModal(false)
    }

    return (
        isLoading ? <LoadingSpinner /> :
            <div>
                <Navigation />
                <div className='report__container'>
                    {Object.entries(userData)?.map(([company, userTypes]) => (
                        ((userCompany === "Automotion Parking LLC") || (company === userCompany)) &&
                        <ul className='user__list' key={company}>
                            <li>{company}</li>
                            {Object.entries(userTypes)?.map(([userType, users]) => (
                                <ul key={userType}>
                                    {(company === 'Automotion Parking LLC' || userType !== 'Super Admins') && <li>{userType}</li>}
                                    <ul>
                                        {users?.map((user) => (
                                            <div key={user.username} className='edit__user'>
                                                <li>{user.username}</li>
                                                <div>
                                                    <img onClick={() => handleEditUser(user)} src={editIcon} alt="Edit" className='edit__user__button' />
                                                    <img onClick={() => handleDeleteUser(user)} src={deleteIcon} alt="Delete" className='edit__user__button' />
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </ul>
                            ))}
                        </ul>
                    ))}
                    <DeleteUsersModal
                        show={showDeleteModal}
                        handleClose={handleClose}
                        userToDelete={userToDelete}
                        userData={userData}
                        setUserData={setUserData}
                        userName={userName}
                        userCompany={userCompany}
                    />
                    {userToEdit &&
                        <EditUserModal
                            show={showEditModal}
                            setShow={setShowEditModal}
                            userToEdit={userToEdit}
                            userName={userName}
                            userCompany={userCompany}
                        />
                    }
                </div>
            </div>
    )
}