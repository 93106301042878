import React from "react";
import OverParkedTable from "../../components/OverParked/OverParkedTable";
import "../../pages/AutomatedDailyReportPage/AutomatedDailyReport.scss";
import MonthlyOnlyDailyHeader from "../../components/MonthlyOnlyDailyHeader/MonthlyOnlyDailyHeader";
import MonthlyOnlyTransactionTable from "../../components/MonthlyOnlyTransactionTable/MonthlyOnlyTransactionTable";

function MonthlyOnlyDailyPage({
  currentMonthliesIn,
  spaces,
  hourlyTransactionTable,
  overParkedData,
  garageName
}) {

  return (
    <>
      <MonthlyOnlyDailyHeader
        currentMonthliesIn={currentMonthliesIn}
        spaces={spaces}
      />
      <MonthlyOnlyTransactionTable
        hourlyTransactionTable={hourlyTransactionTable}
      />
      <OverParkedTable
        overParkedData={overParkedData}
        garageName={garageName} />
    </>
  );
}

export default MonthlyOnlyDailyPage;
