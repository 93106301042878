import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './SchemehornDailyPage.scss'
import Navigation from "../../components/Navigation/Navigation";
import LoadingSpinner from "../../components/LoadingWheel/LoadingWheel";
import SchemehornRevenueSummaryComponent from "../../components/SchemehornRevenueSummaryComponent/SchemehornRevenueSummaryComponent";
import SchemehornDiscountComponent from "../../components/SchemehornDiscountComponent/SchemehornDiscountComponent";
import SchemehornTicketRangesComponent from "../../components/SchemehornTicketRangesComponent/SchemehornTicketRangesComponent";
import SchemehornPaymentTypeComponent from "../../components/SchemehornPaymentTypeComponent/SchemehornPaymentTypeComponent";
import SchemehornVolumeComponent from "../../components/SchemehornVolumeComponent/SchemehornVolumeComponent";
import { formatDate } from "../AutomatedDailyReportPage/AutomatedDailyReportHelpers";
import { PDFExport } from "@progress/kendo-react-pdf";
import ToolBar from '../../components/ToolBar/ToolBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EmailComponent from '../../components/EmailComponent/EmailComponent';

function SchemehornDailyPage() {

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedDate = formatDate(yesterday);

  const inDate = formattedDate;
  const outDate = formattedDate;
  const [data, setData] = useState(null);
  const [discounts, setDiscounts] = useState(null);
  const [ticketRanges, setTicketRanges] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState(null);
  const [volume, setVolume] = useState(null)
  const [loading, setLoading] = useState(true);

  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false)

  const displayHandler = () => {
    setDisplayForm(!displayForm)
  }

  const container = React.useRef(null);

  const nav = useNavigate()

  const getSchemehornData = async () => {
    const token = sessionStorage.getItem('token');

    try {
      const [response, discountResponse, ticketRangesResponse, paymentTypesResponse, volumeResponse] = await Promise.all([
        axios.post(
          process.env.REACT_APP_SCHEMEHORN_URL,
          {
            inDate,
            outDate,
            inTime: "12:00 AM",
            outTime: "11:59:59 PM",
          },
          {
            headers: {
              'authorization': 'Bearer ' + token
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
            }
          })
        ,
        axios.post(
          process.env.REACT_APP_SCHEMEHORN_DISCOUNTS_URL,
          {
            inDate,
            outDate,
            inTime: "12:00 AM",
            outTime: "11:59:59 PM",
          },
          {
            headers: {
              'authorization': 'Bearer ' + token
            }
          }
        ).catch(error => {
          if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
          }
        }),
        axios.post(
          process.env.REACT_APP_SCHEMEHORN_TICKETS_URL,
          {
            inDate,
            outDate,
            inTime: "12:00 AM",
            outTime: "11:59:59 PM",
          },
          {
            headers: {
              'authorization': 'Bearer ' + token
            }
          }
        ).catch(error => {
          if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
          }
        }),
        axios.post(
          process.env.REACT_APP_SCHEMEHORN_PAYMENTS_URL,
          {
            inDate,
            outDate,
            inTime: "12:00 AM",
            outTime: "11:59:59 PM",
          },
          {
            headers: {
              'authorization': 'Bearer ' + token
            }
          }
        ).catch(error => {
          if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
          }
        }),
        axios.post(
          process.env.REACT_APP_SCHEMEHORN_VOLUME_URL,
          {
            inDate,
            outDate,
            inTime: "12:00 AM",
            outTime: "11:59:59 PM",
          },
          {
            headers: {
              'authorization': 'Bearer ' + token
            }
          }
        )
          .catch(error => {
            if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
            }
          })
      ]);

      setData(response.data);
      setDiscounts(discountResponse.data);
      setTicketRanges(ticketRangesResponse.data);
      setPaymentTypes(paymentTypesResponse.data);
      setVolume(volumeResponse.data);

      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const getData = () => {
    getSchemehornData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Navigation />
      {isSending ? <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box> : ""}
      <div className="report__container">
        <ToolBar
          displayHandler={displayHandler}
          displayForm={displayForm}
          container={container}
          garageName="Schemerhorn"
          formattedDate={formattedDate}
          report="Daily"
        />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            {displayForm ?
              <EmailComponent
                setIsSending={setIsSending}
                fileName={`Shemerhorn_Daily_Report_${formattedDate}`}
              />
              : ''
            }
            <div id="PDFExport">
              <PDFExport
                fileName={`Shemerhorn_Daily_Report_${formattedDate}`}
                forcePageBreak=".page-break"
                scale={0.68} paperSize="Letter"
                margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                ref={container}>
                <table id="table-to-xls" className="custom-table">
                  <SchemehornVolumeComponent
                    dateRange={volume.dateRanges}
                    header={volume.header}
                    hoursRows={volume.hourRows}
                    totals={volume.totals}
                  />
                  <SchemehornRevenueSummaryComponent
                    inDate={inDate}
                    outDate={outDate}
                    data={data}
                  />
                  <SchemehornDiscountComponent discounts={discounts} />
                  <SchemehornTicketRangesComponent ticketRanges={ticketRanges} />
                  <SchemehornPaymentTypeComponent paymentTypes={paymentTypes} />
                </table>
              </PDFExport>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default SchemehornDailyPage;
