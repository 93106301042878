import * as Yup from 'yup';
import { usersEmailExists } from '../firebaseHelpers/userHelpers';

export const emailAddressValidationSchema = Yup.object().shape({

    email: Yup.string()
        .email('Invalid email address')
        .required('Email address is required')
        .test(
            'user-email-exists',
            'User with that email does not exist',
            async function (value) {
              if (value === '') {
                return false;
              }
              const emailExists = await usersEmailExists(value);
              return emailExists;
            }
          ),
        })