import React, { useState, useEffect } from 'react';
import Navigation from '../../components/Navigation/Navigation';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from '../../components/DatePicker/DatePicker';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import Table from "react-bootstrap/Table";
import './LexHotelFilteredReportPage.scss';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { PDFExport } from "@progress/kendo-react-pdf";
import ToolBar from '../../components/ToolBar/ToolBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";
import EmailComponent from '../../components/EmailComponent/EmailComponent';


export default function LexHotelFilteredReportPage() {
  const date = new Date().toISOString().split('T')[0];
  const [isLoading, setIsLoading] = useState(false);
  const [hotelData, setHotelData] = useState(null);
  const [dateSortDirection, setDateSortDirection] = useState("asc");
  const [fobSortDirection, setFobSortDirection] = useState("asc");
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const displayHandler = () => {
    setDisplayForm(!displayForm);
  };

  const container = React.useRef(null);

  const nav = useNavigate()

  const getHotelData = async () => {
    try {
      //get the JWT token from session storage
      const token = sessionStorage.getItem('token');
      setIsLoading(true);
      const response = await axios.get("https://automotion-heroku-server.herokuapp.com/lexHotel", {
        params: {
          inDate: startDate,
          outDate: endDate,
        },
        headers: {
          authorization: 'Bearer ' + token
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
        }
      })

      setHotelData(response.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const totalDateDiff = hotelData?.reduce((total, item) => total + item.DateDiff, 0);
  const totalCharge = hotelData?.reduce((total, item) => total + item.charge, 0);

  const sortByInDateTime = () => {
    const sortedData = hotelData.sort((a, b) => {
      const dateA = new Date(a.InDateTime);
      const dateB = new Date(b.InDateTime);

      if (dateSortDirection === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setHotelData(sortedData);
    setDateSortDirection(dateSortDirection === "asc" ? "desc" : "asc");
  };

  const sortByFob = () => {
    const sortedData = hotelData.sort((a, b) => {
      const fobA = a.Fob;
      const fobB = b.Fob;

      if (fobSortDirection === "asc") {
        return fobA - fobB;
      } else {
        return fobB - fobA;
      }
    });

    setHotelData(sortedData);
    setFobSortDirection(fobSortDirection === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    getHotelData();
  }, []);

  const today = new Date();
  today.setDate(today.getDate());
  const formattedDate = formatDate(today);
  return (
    <div>
      <Navigation />
      {isSending ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        ""
      )}
      <div className='report__container'>
        <ToolBar
          displayHandler={displayHandler}
          displayForm={displayForm}
          container={container}
          garageName="24thStreet"
          formattedDate={formattedDate}
          report={"LexHotel"}
        />
        {displayForm ? (
          <EmailComponent
            setIsSending={setIsSending}
            fileName={`Lex_Hotel_Report_${formattedDate}`}
          />
        ) : (
          ""
        )}
        <div className='lex__hotel__container'>
          <h4 className='lex__hotel__container__title'>Lex Hotel Data</h4>
          <DatePicker
            label={"Start-Date 12:00AM"}
            setDate={setStartDate}
          />
          <DatePicker
            label={"End-Date 11:59PM"}
            setDate={setEndDate}
          />
          <div className='lex__hotel__container__button'>
            <Button
              onClick={getHotelData}
              variant="contained">Get Hotel Data
            </Button>
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div id="PDFExport">
                <PDFExport
                  fileName={`Lex_Hotel_Report_${formattedDate}`}
                  forcePageBreak=".page-break"
                  scale={0.68}
                  paperSize="Letter"
                  margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                  ref={container}
                >
                  <div className='lex__hotel__table__container'>
                    <p className='lex__hotel__table__container__title'>Lex Hotel FOB Data</p>
                    <p className='lex__hotel__table__container__title'>{startDate} 12AM - {endDate} 11:59PM</p>
                    <table id="table-to-xls" className="custom-table">
                      <Table striped bordered className="monthlies report table-sm">
                        <thead>
                          <tr className="table-warning">
                            <th className='lex__hotel__table__container__header'>
                              InDate/Time
                              {<ArrowUpwardIcon sx={{ fontSize: 15 }}
                                onClick={sortByInDateTime}
                                style={{
                                  transform:
                                    dateSortDirection === "asc"
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                  cursor: "pointer",
                                }}
                              />}
                            </th>
                            <th>OutDate/Time</th>
                            <th className='lex__hotel__table__container__header'>
                              Fob
                              {<ArrowUpwardIcon sx={{ fontSize: 15 }}
                                onClick={sortByFob}
                                style={{
                                  transform:
                                    fobSortDirection === "asc"
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                  cursor: "pointer",
                                }}
                              />}
                            </th>
                            <th>Hours</th>
                            <th>Days</th>
                            <th>Charge</th>
                          </tr>
                        </thead>
                        <tbody>
                          {hotelData?.map((record, index) => (
                            <tr key={index}>
                              <td>{new Date(record.InDateTime).toLocaleString()}</td>
                              <td>{new Date(record.OutDateTime).toLocaleString()}</td>
                              <td>{record.Fob}</td>
                              <td>{record.length?.toFixed(2)}</td>
                              <td>{record.DateDiff}</td>
                              <td>{record.charge}</td>
                            </tr>
                          ))}
                          <tr className='table-success'>
                            <th>Totals:</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <th>{totalDateDiff}</th>
                            <th>{totalCharge}</th>
                          </tr>
                        </tbody>
                      </Table>
                    </table>
                  </div>
                </PDFExport>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
