import React from "react";
import ReportCard from "../../components/ReportCard/ReportCard";
import { useParams } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import "./ReportSelectPage.scss";
import { UserAuth } from "../../components/Context/AuthContext";

function ReportSelectPage() {
  
  let { garageName } = useParams();

  const schemehornReports = ["Daily report", "Filtered Report", "Revenue for Monthlies"];
  const automatedGarageReports = ["Daily Report", "Errors", "Filtered Report", "Filter By Rate", "Monthlies", "Pallet Data", "Revenue for Monthlies", "Wait Times Report"];
  const lexHotelReports = ["Lex Hotel"];
  const reportsForTechs = ["Errors", "Monthlies", "Pallet Data", "Wait Times Report"];

  const { userStatusValue } = UserAuth();

  return (
    <>
      <Navigation />
      <h4 className="garage__title">{garageName} Garage Reports</h4>
      <div className="cards d-flex justify-content-center">
        {garageName === "schemehorn" ? (
          schemehornReports.map((report, index) => (
            <ReportCard key={index} name={garageName} title={report} />
          ))
        ) : (
          <>
            {userStatusValue < 1 ? (
              reportsForTechs.map((report) => (
                <ReportCard key={report} name={garageName} title={report} />
              ))
            ) : (
              automatedGarageReports.map((report) => (
                <ReportCard key={report} name={garageName} title={report} />
              ))
            )}
          </>
        )}
        {(garageName === "24th Street" && userStatusValue > 0) &&
          lexHotelReports.map((report, index) => (
            <ReportCard key={index} name={garageName} title={report} />
          ))}
      </div>
    </>
  );
}

export default ReportSelectPage;
