import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function GarageFeedback({ openSimpleSnackbar, setOpenSimpleSnackbar, snackbarInfo }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSimpleSnackbar(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={openSimpleSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        TransitionComponent={(props) => (
          <Slide {...props} direction="right" /> // Slide in from the left
        )}
      >
        <Alert onClose={handleClose} severity={snackbarInfo.severity} sx={{ width: '100%' }}>
          {snackbarInfo.text}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
