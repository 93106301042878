import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {UserAuth} from "../Context/AuthContext";
import './EmailComponent.scss'
import Button from "react-bootstrap/Button";
import {base64Email} from "../EmailSender/EmailPdf";

export default function EmailComponent(props) {
    const {setIsSending, fileName} = props
    const [to , setTo] = useState("")
    const [body , setBody] = useState("")
    const currentUser = UserAuth().user.email

    const nav = useNavigate()
    
  return (
      <div>
          <form className="emailForm">
              <p className='emailForm_text'>From: {currentUser}</p>
              <p className='emailForm_text'>To: <input onChange={e=>setTo(e.target.value)} value={to} className='emailForm_to' type="text" placeholder="Enter email address" /></p>
              <textarea onChange={e=>setBody(e.target.value)} value={body} className='emailForm_text_input'name="comment" placeholder='Add message...'></textarea>
              <Button onClick={()=>base64Email(currentUser,to,body,setIsSending, fileName, nav)} className="emailForm_button">
                  Send Email
              </Button>
          </form>
      </div>
  );
}
