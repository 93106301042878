import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { deletePendingRequest, sendRejectPendingRequestEmail } from '../../firebase';
import { UserAuth } from '../Context/AuthContext';

export default function RejectRequestModal({ show, setShow, rowData, requestId, company, setNotifications, dataWithRemovedItem, selectedRowObject }) {
    const [rejectionNote, setRejectionNote] = useState(''); // Step 1: State variable for rejection note

    const { setNumberOfNotifications, userName, userCompany } = UserAuth()
    const handleSave = async () => {
        console.log(company);
        setShow(false);
        deletePendingRequest(requestId, company, userName, userCompany, selectedRowObject);
        setNotifications(dataWithRemovedItem);

        // Step 3: Send the rejection email with the rejection note
        await sendRejectPendingRequestEmail(selectedRowObject, rejectionNote);
        handleClose();
        setNumberOfNotifications(prevNumber => prevNumber - 1)
    }

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setRejectionNote('')
        setShow(false)
    }

    // Step 2: Event handler to update the rejection note
    const handleRejectionNoteChange = (e) => {
        setRejectionNote('')
        setRejectionNote(e.target.value);
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reject Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {rowData}
                    <Form.Label>Rejection Note:</Form.Label>

                    {/* Input to enter the rejection note */}
                    <Form.Control
                        value={rejectionNote} // Controlled component
                        onChange={handleRejectionNoteChange} // Step 2: Update rejection note
                        as="textarea"
                        rows={3}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
