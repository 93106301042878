import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function BarChart({ title, data }) {
  const chartRef = useRef(null); // Create a ref to store the chart instance

  useEffect(() => {
    // Extracting data from the given format
    const labels = data.map(item => item[0]);
    const dataSet1 = data.map(item => item[1]);
    const dataSet2 = data.map(item => item[2]);

    // Create the chart data
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: title == "All Cars In/Out" ? 'Parked In' : 'Monthlies',
          data: dataSet1,
          backgroundColor: 'rgba(0, 61, 122, 0.4)',
          borderColor: 'rgba(0, 61, 122, 1)',
          borderWidth: 1,
          barPercentage: 0.7, // Adjust the width of the bars for dataset 1
          categoryPercentage: 0.7,
        },
        {
          label: title == "All Cars In/Out" ? 'Parked Out' : 'Transients',
          data: dataSet2,
          backgroundColor: 'rgba(128, 128, 128, 0.3)',
          borderColor: 'rgba(128, 128, 128, 1)',
          borderWidth: 1,
          barPercentage: 0.7, // Adjust the width of the bars for dataset 2
          categoryPercentage: 0.7,
        },
      ],
    };

    // Destroy the previous chart instance, if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Create a new chart instance
    const ctx = document.getElementById('bar-chart').getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false, // Remove fixed aspect ratio
        scales: {
          x: {
            stacked: false, // Change to 'false' to display bars side by side
          },
          y: {
            stacked: false, // Change to 'false' to display bars side by side
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
    });

    // Cleanup: Destroy the chart when the component is unmounted
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data]);

  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      <canvas id="bar-chart" width="100%" height="200"></canvas>
    </div>
  );
}
