import React, { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation/Navigation';
import { auth } from "../../firebase";
import { UserAuth } from '../../components/Context/AuthContext';
import Table from "react-bootstrap/Table";
import Collapse from 'react-bootstrap/Collapse';
import deleteIcon from '../../assets/deleteIcon.png';
import editIcon from '../../assets/editIcon.png';
import EditScheduleModal from '../../components/EditScheduleModal/EditScheduleModal';
import DeleteScheduleModal from '../../components/DeleteScheduleModal/DeleteScheduleModal';
import './ViewSchedulesPage.scss'
import downButton from '../../assets/downButton.png';
import LoadingSpinner from "../../components/LoadingWheel/LoadingWheel"
import { collection, query, getDocs } from "firebase/firestore";
import { firestoreDB } from '../../firebase';
import { convertTime } from './ViewSchedulePageHelpers';

export default function ViewSchedulesPage() {

    //holds loading state for the schedules data
    const [isLoading, setIsLoading] = useState(null)

    //list of schedules
    const [allSchedules, setAllSchedules] = useState(null)

    //pop-up after clicking the edit icon, boolean
    const [showEditModal, setShowEditModal] = useState(false)

    //pop-up after clicking the delete icon, boolean
    const [showDeleteScheduleModal, setShowDeleteScheduleModal] = useState(false)

    //object to hold the recipients, time, and subject of the schedule being edited
    const [infoToEdit, setInfoToEdit] = useState({})

    //object to hold data for schedule being deleted
    const [scheduleToDelete, setScheduleToDelete] = useState({})

    //state for the expanded row, hold row number of row being expanded
    const [expandedRow, setExpandedRow] = useState(null);

    //firebase auth state
    const { userName, userCompany, userStatusValue } = UserAuth()

    //function to create a reference to the DB that stores scheduled email jobs
    const q = query(collection(firestoreDB, "tasks"))

    const getSchedules = async () => {
        setIsLoading(true)
        let allSchedules = []

        //gets all the documents in the firestoreDB
        let querySnapshot = await getDocs(q);

        //Add all the documents into the allSchedules array
        querySnapshot.forEach((doc) => {
            allSchedules.push({ id: doc.id, ...doc.data() })
        })

        //sort from earliest schedule to latest (by hour of day)
        allSchedules.sort((a, b) => (a.time > b.time) ? 1 : -1);
        setAllSchedules(allSchedules)
        setIsLoading(false)
    }

    //React hook to automatically load all schedules from Firebase when page renders
    useEffect(() => {
        getSchedules()
    }, [])

    // Define a function to handle clicking the expand button
    const handleExpand = (index) => {

        // if the row ind index already matches the expanded row index, that means its already open, 
        // therefore, change the expanded row to null which will collapse it
        if (index === expandedRow) {
            setExpandedRow(null);
        } else {
            // by setting the expanded row index to the same as the index, it will expand the row
            setExpandedRow(index);
        }
    };

    //function to handle closing editSchedule modal
    const handleClose = () => setShowEditModal(false);

    //function to handle showing the current data for the schedule the user clicks edit on, 
    //and displays that data in a modal
    const editHandler = (id, time, recipients, subject) => {
        setInfoToEdit({
            time,
            recipients,
            id,
            subject
        })
        setShowEditModal(true)
    }

    //function to handle showing the current data for the schedule the user clicks delete on, 
    //and displays that data in a modal
    const deleteHandler = (id, selectedSchedule) => {
        setScheduleToDelete({
            id,
            email: auth.currentUser.email,
            selectedSchedule
        })
        setShowDeleteScheduleModal(true)
    }

    return (
        <div>
            {/* if showEditModal state == true, the edit modal will display over the page */}
            {
                showEditModal && <EditScheduleModal
                    showEditModal={showEditModal}
                    handleClose={handleClose}
                    id={infoToEdit.id}
                    time={infoToEdit.time}
                    recipients={infoToEdit.recipients}
                    subject={infoToEdit.subject}
                    userName={userName}
                    userCompany={userCompany}
                />
            }

            {/* if showDeleteScheduleModal state == true, the delete confirmation modal will display over the page */}
            {
                showDeleteScheduleModal && <DeleteScheduleModal
                    setShowDeleteScheduleModal={setShowDeleteScheduleModal}
                    showDeleteScheduleModal={showDeleteScheduleModal}
                    id={scheduleToDelete.id}
                    email={scheduleToDelete.email}
                    scheduleTitle={scheduleToDelete.selectedSchedule}
                    userName={userName}
                    userCompany={userCompany}
                />
            }

            {/* the page layout without any modals covering the page */}
            <Navigation />
            <div className='report__container'>
                <h4 className='scheduled__emails__header'>Scheduled Emails</h4>
                {/* show loading spinner while the schedules load */}
                {isLoading ? <LoadingSpinner /> :

                    <Table striped className="table-sm">
                        {/* table showing all the scheduled emails and their details */}
                        {/* table headings, 6 columns */}
                        <thead>
                            <tr className="table-warning">
                                <th>info:</th>
                                <th>Hr</th>
                                <th>Created By:</th>
                                <th>Type</th>
                                <th>Del.</th>
                                <th>Edit</th>
                            </tr>
                        </thead>

                        {/* table body */}
                        <tbody>
                            {/* once all schedules load, the map function displays tthem in the table */}
                            {allSchedules && (allSchedules).map((schedule, index) => {
                                // the index (starting at 0) is used for expanding the rows
                                return (
                                    <React.Fragment key={index}>
                                        {/* each table row */}
                                        <tr onClick={() => handleExpand(index)} style={{ cursor: 'pointer' }}>
                                            <td>
                                                {/* the expand icon, toggles between up and down when expanded and closed */}
                                                <img className='expandIcon'
                                                    src={downButton}
                                                    style={{ transform: index == expandedRow ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                                />
                                            </td>
                                            <td style={{ fontSize: "14px" }}>{convertTime(schedule.time)}</td>
                                            <td style={{ fontSize: "14px" }}>{schedule.createdBy}</td>
                                            <td style={{ fontSize: "14px" }}>{schedule.type}</td>

                                            {/* 
                                            the delete/edit icons in each schedule row are shown or hidden. It's 
                                            shown depending on the status of the logged in user compared to the 
                                            status of the person who created hte schedule    
                                        */}
                                            <td>
                                                {userStatusValue > schedule.userStatusValue || schedule.createdBy === userName ?
                                                    <img style={{ cursor: 'pointer' }}
                                                        onClick={() => deleteHandler(schedule.id, `${schedule.subject}. Time: ${schedule.time}`)}
                                                        src={deleteIcon}>
                                                    </img> : ""}
                                            </td>
                                            <td>
                                                {userStatusValue > schedule.userStatusValue || schedule.createdBy === userName ?
                                                    <img style={{ cursor: 'pointer' }}
                                                        onClick={() => editHandler(schedule.id, schedule.time, schedule.recipients, schedule.subject)}
                                                        src={editIcon}></img> : ""}
                                            </td>
                                            {/* end of row */}
                                        </tr>

                                        {/* this is the expanded part that shows when clicking the expand icon */}
                                        <tr>
                                            <td className='expanded-row' colSpan="5">
                                                {/* show the expanded row when the index of the row matches the state of expandedRow */}
                                                <Collapse in={expandedRow === index}>
                                                    <ul>
                                                        <h6>Subject: {schedule.subject}</h6>
                                                        {schedule.recipients.split(',').map((email, emailIndex) => (
                                                            <li key={emailIndex}>{email}</li>
                                                        ))}
                                                    </ul>
                                                </Collapse>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                }
            </div>
        </div>
    )
} 