import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './EditGarageDataModal.scss';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import { getGarageMetaData, updateGarage } from '../../firebase';
import { getGarageImage } from '../../firebaseStorage';
import Default_Garage_Image from '../../assets/Default_Garage_Image.png';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { saveAction } from '../../firebase';

function EditGarageDataModal({ garage, company, showEditGarageDataModal, handleCloseEditGarageDataModal, userName, userCompany }) {
    const [garageMetaData, setGarageMetaData] = useState({});
    const [editedImage, setEditedImage] = useState('');
    const [editedConnectionString, setEditedConnectionString] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [garageImageURL, setGarageImageURL] = useState('');
    const [validationError, setValidationError] = useState('');

    const validationSchema = Yup.object().shape({
        editedConnectionString: Yup.string().required('Connection string is required'),
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log(file);
        setEditedImage(file);
    };

    const handleSave = async () => {
        try {
            await validationSchema.validate({ editedConnectionString }, { abortEarly: false });
            setIsLoading(true)

            const actionDetails = {
                action: "Garage Edited",
                details: {
                    Garage: garage,
                },
                createdBy: {
                    userName,
                    userCompany
                },
                dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
            }

            try {
                await saveAction(actionDetails)
            } catch (e) {
                console.log(e)
            }
            // Save the edited values and close the modal
            await updateGarage({
                ...garageMetaData,
                databaseConnection: editedConnectionString,
                selectedImage: editedImage === "" ? garageImageURL : editedImage
            }, editedImage === "");

            handleCloseEditGarageDataModal();
            localStorage.clear()
        } catch (error) {
            // Handle validation errors
            console.error('Validation error:', error);
            setValidationError(error.errors[0]);
        }
    };

    const getData = async () => {
        setIsLoading(true);
        const garageData = await getGarageMetaData(garage, company);
        const garageImage = garageData.data.selectedImage
        setGarageImageURL(garageImage);
        setGarageMetaData(garageData.data);
        setEditedConnectionString(garageData.data.databaseConnection);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Modal centered show={showEditGarageDataModal} onHide={handleCloseEditGarageDataModal}>
            <Modal.Header closeButton>
                <Modal.Title>{garage} Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <p>Company: {company}</p>
                        <p>Garage Type: {garageMetaData?.garageType}</p>
                        <p>Spaces: {garageMetaData?.spaces}</p>

                        <Form.Label>Databse Connection</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Connection"
                            autoFocus
                            className='edited__database-connection__input'
                            value={editedConnectionString}
                            onChange={(e) => setEditedConnectionString(e.target.value)}
                            isInvalid={!!validationError}
                        />
                        {validationError && (
                            <Form.Control.Feedback type='invalid'>
                                {validationError}
                            </Form.Control.Feedback>)
                        }

                        {editedImage ? (
                            <div>
                                <h6>Selected Image Preview:</h6>
                                <img
                                    src={URL.createObjectURL(editedImage)}
                                    alt="Selected"
                                    style={{ width: '240px', height: '120px' }}
                                />
                            </div>
                        ) : (
                            <div>
                                <h6>Current Image:</h6>
                                <img
                                    src={garageImageURL || Default_Garage_Image}
                                    alt="Default"
                                    style={{ width: '240px', height: '120px' }}
                                />
                            </div>
                        )}
                        <Form.Group controlId="formFile" className="m-3">
                            <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                        </Form.Group>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleCloseEditGarageDataModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditGarageDataModal;
