import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import defaultGarageImage from '../../assets/Default_Garage_Image.png';

function GarageImageUpload({ garageForm, setGarageForm, setValidationErrors }) {

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setGarageForm({
      ...garageForm,
      selectedImage: file,
    });
    setValidationErrors((prevErrors) => {
      return { ...prevErrors, selectedImage: '' };
    });
  };

  return (
    <div className="container">
      <div className="form-group">
        <label htmlFor="imageUpload">Select an image:</label>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
        </Form.Group>
      </div>
      {garageForm.selectedImage ? (
        <div>
          <h6>Selected Image Preview:</h6>
          <img
            src={URL.createObjectURL(garageForm?.selectedImage)}
            alt="Selected"
            style={{ width: '240px', height: '120px' }} // Set the desired dimensions
          />
        </div>
      ) : (
        <div>
          <h6>Default Image:</h6>
          <img
            src={defaultGarageImage} // Use the default image path or URL
            alt="Default"
            style={{ width: '240px', height: '120px' }} // Set the desired dimensions
          />
        </div>
      )}
    </div>
  );
}

export default GarageImageUpload;
