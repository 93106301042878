import React, { useState }from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { editSchedule } from '../../firebase'
import {auth} from "../../firebase";
import { saveAction } from '../../firebase';

export default function EditScheduleModal(props) {
    const { handleClose, showEditModal, id, time, recipients, subject, userName, userCompany } = props
   
    const [ newTime, setNewTime ] = useState(time)
    const [ newRecipients, setNewRecipients ] = useState(recipients)
    const [ newSubject, setNewSubject ] = useState(subject)

    const handleSubmit = async (e) => {
      e.preventDefault()
      const actionDetails = {
        action: "Schedule Edited",
        details: {
          Subject: subject,
          newTime,
          newRecipients, 
          newSubject,
        },
        createdBy: {
          userName,
          userCompany
        },
        dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
      }
  
      try {
        await saveAction(actionDetails)
      } catch (e) {
        console.log(e)
      }
      await editSchedule(id, newTime, newRecipients, newSubject)
      handleClose()
      window.location.reload()

    }
  return (
    <div>
        <Modal className="special_modal" show={showEditModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form className='my-2 mx-auto' style={{width: 300}}>        
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Recipients</Form.Label>
                <Form.Control type="text" placeholder="Enter email" value={newRecipients} 
                onChange={ e => setNewRecipients(e.target.value) } 
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Time</Form.Label>
                <Form.Control type="time" placeholder="Time" value={newTime} 
                onChange={ e => setNewTime(e.target.value) } 
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" placeholder="Subject" value={newSubject} 
                onChange={ e => setNewSubject(e.target.value) } 
                />
            </Form.Group>
      </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
