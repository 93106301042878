import React, { useEffect, useState } from 'react'
import Navigation from '../../components/Navigation/Navigation'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import { makeSchedule } from '../../firebase';
import CRUDConfirmationModal from '../../components/CRUDConfirmationModal/CRUDConfirmationModal';
import { UserAuth } from '../../components/Context/AuthContext';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import { newScheduleValidationSchema } from '../../Validations/newScheduleValidations';
import { saveAction } from '../../firebase';

export default function CreateSchedulePage() {

    //boolean to display the pop up modal
    const [show, setShow] = useState(false)

    //the field values (type, email subject, time, and recipients)
    const [form, setForm] = useState({
        type: "",
        subject: "",
        time: "",
        recipients: "",
    })

    //the selected garages that the schedule is created for
    const [selectedGarages, setSelectedGarages] = useState({})

    const [isLoading, setIsLoading] = useState(true)

    //react state to handle form validation errors
    const [errors, setErrors] = useState({
        type: null,
        selectedGarages: null,
        subject: null,
        time: null,
        recipients: null,
    })

    //for firebase re authentication on page refresh
    const { userName, userStatusValue, userCompany } = UserAuth()

    const handleClose = () => {
        setShow(false)
        window.location.reload()
    }

    // function to set the report type that was selected (daily or partial), email subject, time, and recipients
    const setField = (field, value) => {
        //This will update our state to keep all the current form values, 
        //then add the newest form value to the correct key location
        setForm({
            ...form,
            [field]: value
        })
    }

    //submits the form and saves in firebase
    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            //validate the form 
            await newScheduleValidationSchema.validate(form, { abortEarly: false });

            // If validation passes, submit the form
            //function to upload the schedule details into firebase database
            const actionDetails = {
                action: "New Schedule Created",
                details: {...form},
                createdBy: {
                    userName,
                    userCompany
                },
                dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
            }
        
            try {
                await saveAction(actionDetails)
            } catch (e) {
                console.log(e)
            }
            await makeSchedule(selectedGarages, form.type, form.time, form.subject, form.recipients, userName, userStatusValue)

            //sets confirmation modal to true, showing the modal
            setShow(true)

        } catch (error) {
            // If validation fails, display error messages
            //object to hold error messages
            const validationErrors = {};
            //loop through the errors and add to the errors object
            error.inner.forEach((e) => {
                validationErrors[e.path] = e.message;
            });
            setErrors({
                ...validationErrors
            })
        }
    }

    const getAllUserGarages = () => {
        let garages = localStorage.getItem('garageData');
        // Check if 'garageData' exists in localStorage and is not null
        let garageList = {};
        if (garages) {
            garages = JSON.parse(garages);
            for (const key in garages) {
                garageList[key] = {
                    includeInReport: false
                }
            }
            setSelectedGarages({
                ...garageList
            })
        } else {
            console.log("No garage data found in localStorage");
        }
        setIsLoading(false)
    }

    const setGarages = (garage, value) => {
        const garageInfo = JSON.parse(localStorage.getItem(`${garage}Info`))
        console.log(garageInfo)
        setSelectedGarages(prevState => ({
            ...prevState,
            [garage]: {
                includeInReport: value,
                ...garageInfo
            }
        }));
    };

    const garageList = Object.keys(selectedGarages);

    useEffect(() => {
        getAllUserGarages()
    }, [])

    return (
        <div>
            {/* confirms a new schedule was created */}
            <CRUDConfirmationModal
                show={show}
                handleClose={handleClose}
                body={"New Email Schedule Created!"}
            />
            <Navigation />
            <Form className='my-2 mx-auto' style={{ width: 300 }}
                noValidate
                onSubmit={handleSubmit}
            >
                {/* Select report type */}
                <Form.Group className="m-4" controlId="formBasicEmail">
                    <Form.Control
                        as="select" // Use a select dropdown
                        value={form.type}
                        onChange={(e) => setField("type", e.target.value)}
                        name='type'
                        isInvalid={!!errors.type}
                    >
                        <option value="">Select Type</option>
                        <option value="daily">Daily Report</option>
                        <option value="partial">Partial Report</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        {errors.type}
                    </Form.Control.Feedback>
                </Form.Group>

                {/* Toggle buttons for selecting garages */}
                <Form.Group>
                    {isLoading ? <LoadingSpinner /> :
                        garageList.map((garage, index) => (
                            garage !== "schemehorn" && <Form.Check
                                key={index}
                                onChange={(e) => setGarages(garage, e.target.checked)}
                                type="switch"
                                id={`custom-switch${index + 1}`}
                                label={garage}
                                value={selectedGarages[garage] || false}
                                className='mb-4'
                            />
                        ))}
                    {userCompany === "Automotion Parking LLC" && <Form.Check
                        onChange={(e) => setGarages('schemehorn', !selectedGarages.schemehorn)}
                        type="switch"
                        id="schem-switch"
                        label="Schemehorn Garage"
                        value={selectedGarages.schemehorn}
                        className='mb-4'
                    />}
                </Form.Group>

                {/* Email Subject */}
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Email Subject:</Form.Label>
                    <Form.Control type="text" placeholder="Subject"
                        onChange={e => setField('subject', e.target.value)}
                        isInvalid={!!errors.subject}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {errors.subject}
                    </Form.Control.Feedback>
                </Form.Group>

                {/* Email scheduled time */}
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Time:</Form.Label>
                    <Form.Control type="time" placeholder="Times"
                        isInvalid={!!errors.time}
                        onChange={e => setField('time', e.target.value)}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {errors.time}
                    </Form.Control.Feedback>
                </Form.Group>

                {/* Input email(s) for the recipients of the report */}
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Recipients:</Form.Label>
                    <Form.Control type="email" placeholder="Recipients Emails" autoComplete="on"
                        onChange={e => setField('recipients', e.target.value)}
                        isInvalid={!!errors.recipients}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {errors.recipients}
                    </Form.Control.Feedback>
                </Form.Group>

                {/* Submit button */}
                <Button style={{ width: 300 }} variant="primary" type="submit">
                    Create Schedule
                </Button>
            </Form>
        </div>
    );
}