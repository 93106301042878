import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Card from 'react-bootstrap/card';
import Navigation from '../Navigation/Navigation';
export default function Forms() {
    const { folderName } = useParams()

    let folders = sessionStorage.getItem('form-folders')
    const currentFolder = JSON.parse(folders).filter(folder => folder.name === folderName)
    console.log(currentFolder)
    console.log(currentFolder[0].forms.length)
    let formsExist = currentFolder[0].forms.length !== 0
    console.log(formsExist)
    let nav = useNavigate()
    const clickHandler = (title) => {
        // navigates to forms/:folderName/formTitle
        nav(`${title}`)
    }
    return (
        <div className='report'>
        <Navigation/>
        <div className='cards d-flex justify-content-center'>
            <h5>{folderName} Forms</h5>
            {

                formsExist ?
                    currentFolder[0].forms.map((form, index) => {
                        return (
                            <button key={index} onClick={()=>clickHandler(form.title)}>
                            <Card className="m-2" style={{ width: '15rem' }}>
                                <Card.Body>
                                    <Card.Title>{form.title}</Card.Title>
                                </Card.Body>
                            </Card>
                            </button>

                        )
                    })
                    :
                    <p>NO FORMS EXIST</p>
            }
        </div>
    </div>
    )
}
