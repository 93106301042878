import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DatePicker from '../DatePicker/DatePicker';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import './TransientData.scss';
import PieChart from '../PieChart/PieChart';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import TransientFrequencyTable from '../TransientFrequencyTable/TransientFrequencyTable';
import ToolBar from '../ToolBar/ToolBar';
import EmailComponent from '../EmailComponent/EmailComponent';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";
import { PDFExport } from "@progress/kendo-react-pdf";

//component tot display transient data pie charts and frequency 
export default function TransientData({setIsSending}) {

    //start date, used for the date pickers
    const [fromDate, setFromDate] = useState(null)

    //end date, used for the date pickers
    const [toDate, setToDate] = useState(null)

    //object to store the transient data response from the API call
    const [data, setData] = useState({
        transientCustomers: null,
        transientReciepts: null,
        transientFrequency: null
    })

    //the contact type counts of email, phone and null values
    const [contactCounts, setContactCounts] = useState(null)

    //the receipt type counts
    const [receiptTypeCount, setReceiptTypeCount] = useState(null)

    //is Loading state for the loading spinner
    const [isLoading, setIsLoading] = useState(false)

    //state to display email form
    const [displayForm, setDisplayForm] = useState(false)

    //grab the garage name from the URL
    const { garageName } = useParams()

    //used for PDF
    const container = React.useRef(null);

    const nav = useNavigate();

    //function to make the GET request for the transients data
    const getTransientData = async () => {
        //get the JWT token from session storage
        const token = sessionStorage.getItem('token');
        const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
        const databaseConnection = garageInfo.databaseConnection;
        
        setIsLoading(true)
        const transientData = await axios.get(
            process.env.REACT_APP_TRANSIENTS_URL
            //"http://localhost:8080/garagedata/transients"
            , {
                params: {
                    garageName,
                    startDate: fromDate,
                    endDate: toDate,
                    connection: databaseConnection
                }, 
                headers: {
                    authorization: 'Bearer ' + token
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    // Redirect to the login page if it's an authentication error
                    nav('/login');
                }
            })

        //set the data in the state
        setData({
            transientCustomers: transientData.data.transientCustomers,
            transientReciepts: transientData.data.transientReceipts,
            transientFrequency: transientData.data.transientFrequency
        })

        //count the occurances of each type
        const transientContactCounts = countContactInfoTypes(transientData.data.transientCustomers)
        setContactCounts(transientContactCounts)

        const transientRecieptTypeCount = countContactInfoTypes(transientData.data.transientReceipts)
        setReceiptTypeCount(transientRecieptTypeCount)
        setIsLoading(false)
    }

    function countContactInfoTypes(dataArray) {
        let atSymbolCount = 0;
        let phoneNumberCount = 0;
        let neitherCount = 0;

        dataArray.forEach(item => {
            console.log(item)
            const contactInfo = item.ContactInfo ? item.ContactInfo.trim() : '';

            if (contactInfo.includes('@')) {
                atSymbolCount++;
            } else if (contactInfo && /\d{3}-\d{3}-\d{4}/.test(contactInfo)) {
                phoneNumberCount++;
            } else {
                neitherCount++;
            }
        });

        return [
            { label: "Email", data: atSymbolCount },
            { label: "Phone", data: phoneNumberCount },
            { label: "None", data: neitherCount }
        ];
    }

    const displayHandler = () => {
        setDisplayForm(!displayForm)
    }

    const today = new Date();
    today.setDate(today.getDate());
    const formattedDate = formatDate(today);

    return (
        <div>
            <ToolBar
                displayHandler={displayHandler}
                displayForm={displayForm}
                container={container}
                csvData={data.transientCustomers}
                formattedDate={formattedDate}
                garageName={garageName}
                report={"Transients_Report"}
            />
            {displayForm ?
                <EmailComponent
                    setIsSending={setIsSending}
                    fileName={`${garageName}_Transient_Report_${formattedDate}`}
                />
                : ""}
            <DatePicker
                label={"From:"}
                setDate={setFromDate}
            />
            <DatePicker
                label={"To:"}
                setDate={setToDate}
            />
            <Button className='m-2' variant="contained" color="primary" onClick={getTransientData}>
                Submit
            </Button>
            <PDFExport
                fileName={`${garageName}_Transient_Data_${formattedDate}`}
                forcePageBreak=".page-break" scale={0.68}
                paperSize="Letter"
                margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                ref={container}>
                <div id="PDFExport">
                    <h3 className='transient__data__title'>{garageName} Transients Report</h3>
                    {isLoading ? <LoadingSpinner /> :
                        <div className='transients__container'>
                            <div className='pie__chart__container'>
                                {contactCounts && <PieChart
                                    title={"Contact Info Type"}
                                    data={contactCounts}
                                    chartId={"Chart1"}
                                />}
                                {receiptTypeCount && <PieChart
                                    title={"Receipt Type"}
                                    data={receiptTypeCount}
                                    chartId={"chart2"}
                                />}
                            </div>
                            <div>
                                {data.transientFrequency && <TransientFrequencyTable
                                    isLoading={isLoading}
                                    data={data.transientFrequency} />}
                            </div>
                        </div>
                    }
                </div>
            </PDFExport>
        </div>
    )
}
