import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Navigation from '../Navigation/Navigation';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import deleteIcon from '../../assets/deleteIcon.png';
import DeleteModal from '../DeleteModal/DeleteModal';
import { UserAuth } from '../../components/Context/AuthContext';
import './ResourceFilesComponent.scss'

export default function ResourceFilesComponent(props) {
    const { files, handleShow, clickHandler, folderName } = props

    const [deleteClicked, setDeleteClicked] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null)

    const { userStatusValue } = UserAuth()

    const handleDelete = (file) => {
        setFileToDelete(file)
        setShowDeleteModal(true)
    }

    return (
        <>
            <DeleteModal
                folderName={folderName}
                file={fileToDelete}
                show={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
            />
            <Navigation />
            <div className='cards d-flex justify-content-center'>
                <h5>{folderName} Files</h5>
                {userStatusValue > 1 &&
                <div className='resource__file__options'>
                    <Button
                        onClick={handleShow}
                        variant="success"
                        type="submit"
                        className='upload-btn'
                    >
                        Upload New File
                    </Button>
                    <Button variant="danger" onClick={() => setDeleteClicked(!deleteClicked)}>
                        {deleteClicked ? "X Cancel" : "Delete a File"}
                    </Button>
                </div>    
                }
                {files === null ? (
                    <LoadingSpinner />
                ) : files !== null && files.length === 0 ? (
                    <p>NO FILES EXIST</p>
                ) : (
                    files.map((file, index) => {
                        return (
                            <div key={index}>
                                {deleteClicked && (
                                    <img
                                        onClick={() => handleDelete(file[0])}
                                        className="card_with_delete_icon"
                                        src={deleteIcon}
                                    />
                                )}
                                <button
                                    className="report-card__button"
                                    onClick={() => clickHandler(file[1])}
                                >
                                    <Card className="m-2" style={{ width: '15rem' }}>
                                        <Card.Body>
                                            <Card.Title>{file[0]}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </button>
                            </div>
                        )
                    })
                )}
            </div>
        </>
    )
}

