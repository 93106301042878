export function convertTime(timeString) {
    const [hours, minutes] = timeString.split(':');
    let period = 'AM';
  
    // convert hours to 12-hour format
    let hours12 = parseInt(hours, 10);
    if (hours12 > 12) {
      hours12 -= 12;
      period = 'PM';
    } else if (hours12 === 12) {
      period = 'PM';
    } else if (hours12 === 0) {
      hours12 = 12;
    }
  
    return `${hours12}:${minutes} ${period}`;
  }