import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import './PalletDataPage.scss'
import axios from 'axios';
import Navigation from "../../components/Navigation/Navigation";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { PDFExport } from "@progress/kendo-react-pdf";
import ToolBar from '../../components/ToolBar/ToolBar';
import LinearProgress from '@mui/material/LinearProgress';
import EmailComponent from '../../components/EmailComponent/EmailComponent';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";

export default function PalletDataPage() {

    const [palletData, setPalletData] = useState([])
    const [dataByLevelAndSide, setDataByLevelAndSide] = useState([])
    const [selectedLevel, setSelectedLevel] = useState('all');
    const [selectedSide, setSelectedSide] = useState('all');
    const [displayForm, setDisplayForm] = useState(false);
    const [isSending, setIsSending] = useState(false)
    const [loading, setLoading] = useState(true)

    const displayHandler = () => {
        setDisplayForm(!displayForm)
    }

    const container = React.useRef(null);

    const handleLevelChange = async (event) => {
        setSelectedLevel(event.target.value);
        filterByShelfLevelAndSide(event.target.value, selectedSide)
    };

    const handleSideChange = (event) => {
        setSelectedSide(event.target.value);
        filterByShelfLevelAndSide(selectedLevel, event.target.value)
    };


    const { garageName } = useParams()

    const levels24thStreet = {
        levelOne: [1, 2, 3, 4, 5, 6, 7, 8, 32, 33, 34, 35, 36, 37, 38],
        levelTwo: [9, 10, 11, 12, 13, 39, 40, 41, 42, 43],
        levelThree: [14, 15, 16, 17, 18, 44, 45, 46, 47, 48],
        levelFour: [19, 20, 21, 22, 23, 49, 50, 51, 52, 53],
        levelFive: [24, 25, 26, 54, 55, 56],
        levelSix: [27, 28, 29, 30, 31, 57, 58, 59, 60, 61]
    }

    const sides24thStreet = {
        1: [1, 3, 5, 7, 9, 10, 12, 14, 15, 17, 19, 20, 22, 24, 25, 26, 27, 29, 30, 31],
        2: [2, 4, 6, 8, 11, 13, 16, 18, 21, 23, 28],
        3: [32, 34, 35, 37, 39, 40, 42, 44, 45, 47, 49, 50, 52, 54, 55, 56, 57, 59, 60, 61],
        4: [33, 36, 38, 41, 43, 46, 48, 51, 53, 58]
    }

    function get24thStreetLevelKey(number) {
        const levels = levels24thStreet;

        for (const levelKey in levels) {
            if (levels[levelKey].includes(number)) {
                return levelKey.replace('level', '');
            }
        }

        return -1; // Return -1 if the number is not found in any level
    }

    const getNumberOfLevelsAndSides = (garageName) => {
        if (garageName === "Baxter") {
            const levels = 2;
            const sides = 3;
            return [levels, sides]
        } else if (garageName === "VanVorst") {
            const levels = 4;
            const sides = 4;
            return [levels, sides]
        } else if (garageName === "Waverly") {
            const levels = 3;
            const sides = 4;
            return [levels, sides]
        } else if (garageName === "24th Street") {
            const levels = 6;
            const sides = 4;
            return [levels, sides]
        }
    }

    const filterByShelfLevelAndSide = (selectedLevel, selectedSide) => {
        let filteredByLevelAndSideData = []
        if (selectedLevel === "all" && selectedSide === "all") {
            filteredByLevelAndSideData = palletData
        } else if (selectedLevel !== "all" && selectedSide !== "all") {
            filteredByLevelAndSideData = palletData.filter((data) => {
                return (data.shelfLevel === selectedLevel) && (data.shelfSide === selectedSide)
            })
        } else if (selectedLevel !== "all" && selectedSide === "all") {
            filteredByLevelAndSideData = palletData.filter((data) => {
                return data.shelfLevel === selectedLevel
            })
        } else if (selectedLevel === "all" && selectedSide !== "all") {
            filteredByLevelAndSideData = palletData.filter((data) => {
                return data.shelfSide === selectedSide
            })
        }
        setDataByLevelAndSide(filteredByLevelAndSideData)
    }

    const getShelfLevel = (shelfNumber) => {
        if (garageName === "Baxter") {
            if (shelfNumber >= 1 && shelfNumber <= 37) {
                return 1
            } else if (shelfNumber >= 38 && shelfNumber <= 67) {
                return 2
            } else {
                return "N/A"
            }
        } else if (garageName === "VanVorst") {
            if (shelfNumber >= 1 && shelfNumber <= 62) {
                return 1
            } else if (shelfNumber >= 63 && shelfNumber <= 124) {
                return 2
            } else if (shelfNumber >= 125 && shelfNumber <= 189) {
                return 3
            } else if (shelfNumber >= 190 && shelfNumber <= 254) {
                return 4
            } else {
                return "N/A"
            }
        } else if (garageName === "Waverly") {
            if (shelfNumber >= 1 && shelfNumber <= 11) {
                return 1
            } else if (shelfNumber >= 12 && shelfNumber <= 22) {
                return 2
            } else if (shelfNumber >= 23 && shelfNumber <= 33) {
                return 3
            } else {
                return "N/A"
            }
        } else if (garageName === "24th Street") {
            let level = get24thStreetLevelKey(shelfNumber)
            const levelToInt = {
                "One": 1, "Two": 2, "Three": 3, "Four": 4, "Five": 5, "Six": 6
            }
            return levelToInt[level]
        }
    }

    const getShelfSide = (shelfNumber) => {
        if (garageName === "Baxter") {
            if ((shelfNumber >= 1 && shelfNumber <= 13) || (shelfNumber >= 38 && shelfNumber <= 46)) {
                return 1
            } else if ((shelfNumber >= 14 && shelfNumber <= 26) || (shelfNumber >= 47 && shelfNumber <= 59)) {
                return 2
            } else if ((shelfNumber >= 27 && shelfNumber <= 37) || (shelfNumber >= 60 && shelfNumber <= 67)) {
                return 3
            } else {
                return "N/A"
            }
        } else if (garageName === "Waverly") {
            if ((shelfNumber >= 1 && shelfNumber <= 3) || (shelfNumber >= 12 && shelfNumber <= 14) || (shelfNumber >= 23 && shelfNumber <= 25)) {
                return 1
            } else if ((shelfNumber >= 4 && shelfNumber <= 6) || (shelfNumber >= 15 && shelfNumber <= 17) || (shelfNumber >= 26 && shelfNumber <= 28)) {
                return 2
            } else if ((shelfNumber === 7 || shelfNumber === 8) || (shelfNumber === 18 || shelfNumber === 19) || (shelfNumber === 29 || shelfNumber === 30)) {
                return 3
            } else if ((shelfNumber >= 9 && shelfNumber <= 11) || (shelfNumber >= 20 && shelfNumber <= 22) || (shelfNumber >= 31 && shelfNumber <= 33)) {
                return 4
            }
            else {
                return "N/A"
            }
        } else if (garageName === "VanVorst") {
            if ((shelfNumber >= 1 && shelfNumber <= 21)
                || (shelfNumber >= 63 && shelfNumber <= 83)
                || (shelfNumber >= 125 && shelfNumber <= 145)
                || (shelfNumber >= 190 && shelfNumber <= 210)
            ) {
                return 1
            } else if ((shelfNumber >= 22 && shelfNumber <= 38)
                || (shelfNumber >= 84 && shelfNumber <= 100)
                || (shelfNumber >= 146 && shelfNumber <= 162)
                || (shelfNumber >= 211 && shelfNumber <= 227)) {
                return 2
            } else if ((shelfNumber >= 39 && shelfNumber <= 59)
                || (shelfNumber >= 101 && shelfNumber <= 121)
                || (shelfNumber >= 163 && shelfNumber <= 183)
                || (shelfNumber >= 228 && shelfNumber <= 248)) {
                return 3
            } else if ((shelfNumber >= 60 && shelfNumber <= 62)
                || (shelfNumber >= 122 && shelfNumber <= 124)
                || (shelfNumber >= 184 && shelfNumber <= 189)
                || (shelfNumber >= 249 && shelfNumber <= 254)) {
                return 4
            } else {
                return "N/A"
            }
        } else if (garageName === "24th Street") {

            for (const key in sides24thStreet) {

                if (sides24thStreet[key].includes(shelfNumber)) {
                    return Number(key)
                }
            }

        }
    }
    const getShelfSize = (shelf) => {
        for (const key in shelfSizes) {
            if (key === garageName) {
                if (shelfSizes[key].sedans.includes(shelf)) {
                    return "S"
                } else if (shelfSizes[key].SUVs.includes(shelf)) {
                    return "O"
                } else {
                    return "N/A"
                }
            }
        }
    }
    const shelfSizes = {
        Baxter: {
            sedans: [1, 2, 3, 6, 7, 27, 30, 31],
            SUVs: [
                4, 5,
                ...Array.from({ length: 26 - 8 + 1 }, (_, i) => i + 8),
                28, 29, 32, 33, 34, 35, 36, 37,
                ...Array.from({ length: 67 - 38 + 1 }, (_, i) => i + 38)
            ]
        },

        VanVorst: {
            sedans: [
                ...Array.from({ length: 62 - 1 + 1 }, (_, i) => i + 1),
                ...Array.from({ length: 189 - 125 + 1 }, (_, i) => i + 125),
            ],
            SUVs: [
                ...Array.from({ length: 124 - 63 + 1 }, (_, i) => i + 63),
                ...Array.from({ length: 254 - 190 + 1 }, (_, i) => i + 190),
            ]
        },

        Waverly: {
            sedans: [
                4, 5, 6, 9, 10, 11, 13,8,
                15, 16, 17, 20, 21, 22,
                26, 27, 28, 31, 32, 33
            ],
            SUVs: [
                1, 2, 3, 7, 8,
                18, 19, 12, 24, 14,
                23, 24, 25, 29, 30
            ]
        },

        "24th Street": {
            sedans: [
                14, 15, 17, 44, 45, 47,
                19, 20, 22, 49, 50, 52,
                24, 25, 26, 54, 55, 56,
                ...levels24thStreet.levelSix
            ],
            SUVs: [
                ...levels24thStreet.levelOne,
                ...levels24thStreet.levelTwo,
                16, 18, 46, 48,
                21, 23, 51, 53
            ]
        }
    }

    const status = {
        "0": "No Pallet",
        "1": "Empty",
        "2": "Occupied",
        "3": "Locked"
    }

    const nav = useNavigate()
    
    const getPalletData = async () => {
        //get the JWT token from session storage
        const token = sessionStorage.getItem('token');

        const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
        const databaseConnection = garageInfo.databaseConnection;
        
        const { data } = await axios.get("https://automotion-heroku-server.herokuapp.com/pallet",
            //"http://localhost:8080/pallet", 
            {
                params: {
                    garageName, 
                    connection: databaseConnection
                }, 
                headers: {
                    authorization: 'Bearer ' + token
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    // Redirect to the login page if it's an authentication error
                    nav('/login');
                }
            })

        let modifiedData = await data.map((element) => {
            const shelfSize = getShelfSize(element.ShelfNumber)
            return {
                ...element,
                PalletStatus: status[element.PalletStatus[0]],
                ShelfSize: shelfSize,
                shelfLevel: getShelfLevel(element.ShelfNumber),
                shelfSide: getShelfSide(element.ShelfNumber)
            };
        })
        console.log(modifiedData)
        setPalletData(modifiedData)
        setDataByLevelAndSide(modifiedData)
        setLoading(false)
    }

    useEffect(() => {
        getPalletData()
    }, [])

    const columns = useMemo(
        () => [
            {
                header: 'Shelf',
                accessorKey: 'ShelfNumber', //simple accessorKey pointing to flat data
                width: 40,
            },
            {
                header: 'FOB',
                accessorKey: 'Fob', //simple accessorKey pointing to flat data
                width: 10,
            },
            {
                header: 'Status',
                accessorKey: 'PalletStatus', //simple accessorKey pointing to flat data
                width: 10,
            },
            {
                header: 'Type',
                accessorKey: 'CustomerType', //simple accessorKey pointing to flat data
                width: 10,
            },
            {
                header: 'Car Size',
                accessorKey: 'VehicleHeight', //simple accessorKey pointing to flat data
                width: 10,
            },
            {
                header: 'Shelf Size',
                accessorKey: 'ShelfSize', //simple accessorKey pointing to flat data
                width: 10,
            },

        ],
        [],
    );

    const [levels, sides] = getNumberOfLevelsAndSides(garageName)

    const levelsArray = Array.from({ length: levels }, (_, index) => index + 1);
    const sidesArray = Array.from({ length: sides }, (_, index) => index + 1);

    dataByLevelAndSide && console.log(dataByLevelAndSide)
    const today = new Date();
    today.setDate(today.getDate());
    const formattedDate = formatDate(today);
    return (

        <>
        <Navigation />
            {isSending ? <Box sx={{ width: '100%' }}>
            <LinearProgress />
            </Box> : ""}
            <div className="report__container">
            <ToolBar
                displayHandler={displayHandler}
                displayForm={displayForm}
                container={container}
                csvData={dataByLevelAndSide}
                garageName={garageName}
                formattedDate={formattedDate}
                report={"Pallet_Data"}
            />
            {displayForm ?
                <EmailComponent
                    setIsSending={setIsSending}
                    fileName={`${garageName}_Pallet_Data_${formattedDate}`}
                />
                : ''
            }
            <PDFExport
                fileName={`${garageName}_Pallet_Data_${formattedDate}`}
                forcePageBreak=".page-break" scale={0.68}
                paperSize="Letter"
                margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                ref={container}>
                <div id="PDFExport">
                    <h4 className="pallet__table__title">{garageName} Garage Pallet Data</h4>
                    <div className="side__level__selectors">
                        <Box sx={{ minWidth: 100 }}>
                            <FormControl fullWidth>
                                <InputLabel id="level-select-label">Level</InputLabel>
                                <Select
                                    labelId="level-select-label"
                                    id="demo-simple-select"
                                    value={selectedLevel}
                                    label="Level"
                                    onChange={handleLevelChange}
                                    className="level__selector"
                                >
                                    <MenuItem value="all">All Levels</MenuItem>
                                    {levelsArray.map((level) => (
                                        <MenuItem key={level} value={level}>
                                            {level}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box style={{ marginLeft: "2rem" }} sx={{ minWidth: 100 }}>
                            <FormControl fullWidth>
                                <InputLabel id="side-select-label">Side</InputLabel>
                                <Select
                                    labelId="side-select-label"
                                    id="demo-simple-select"
                                    value={selectedSide}
                                    label="Side"
                                    onChange={handleSideChange}
                                    className="level__selector"
                                >
                                    <MenuItem value="all">All Sides</MenuItem>
                                    {sidesArray.map((side) => (
                                        <MenuItem key={side} value={side}>
                                            {side}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <MaterialReactTable 
                    state={{ isLoading: loading }} 
                    columns={columns} 
                    data={dataByLevelAndSide} 
                    enableDensityToggle={false}
                        initialState={{ density: 'compact' }}
                        defaultColumn={{
                            maxSize: 20,
                        }}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',
                            },
                        }}
                    />
                </div>
            </PDFExport>
        </div>
        </>
    )

}
