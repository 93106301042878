import React from 'react'
import Table from "react-bootstrap/Table";

//table for payments recorded in Quick Books but not Google Sheets
export default function MonthliesRevenueConflictsGSTable(props) {

    const { payments, fob, name, type, rate, fobStatusIndex, QBNameIndex, QBRateIndex, QBFobIndex } = props

    //set to store all FOBs in QBs
    let fobsInQuickBooks = new Set()
    payments.slice(1).forEach(payment => {
        //if the columns go beyond AA (AA = index 27) i.e. lengths > 28 (index 27) on Google Sheets are QBs Entries
        if (payment.length > 28) {
            //29 is column AD where the FOB is stored
            fobsInQuickBooks.add(payment[QBFobIndex])
        }
    });
    console.log("fobsInQuickBooks", fobsInQuickBooks)

    //set to store all FOBs in Google Sheets
    let fobsInGoogleSheets = new Set()
    payments.slice(1).forEach(row => {
        if ((row[fobStatusIndex] === "Active") || row[fobStatusIndex] === 'Last Month')
            fobsInGoogleSheets.add(row[fob])
    });
    console.log("fobsinSheets", fobsInGoogleSheets)

    //filter for payments in QBs that are NOT in Google Sheets (GS)
    let filteredFobs = Array.from(fobsInQuickBooks).filter((fob) => !fobsInGoogleSheets.has(fob))
    console.log(filteredFobs)
    let conflictTotals = 0

    let QBPaymentsNotInGS = payments.filter(payment=>{
        return filteredFobs.includes(payment[QBFobIndex])
    })

    QBPaymentsNotInGS.forEach(payment => {
        conflictTotals += parseFloat(payment[QBRateIndex])
    })

    return (
        <Table striped bordered className="table-sm report">
            <thead>
                <tr className="table-success">
                    <th></th>
                    <th></th>
                    <th>Total:</th>
                    <th>${conflictTotals.toLocaleString()}</th>
                </tr>
            </thead>
            <thead>
                <tr className="table-warning">
                    <th>Name</th>
                    <th>FOB</th>
                    <th>Type</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {QBPaymentsNotInGS.map((payment, i) => {
                    console.log(payment)
                        return (
                            <tr key={i}>
                                <td>{payment[QBNameIndex]}</td>
                                <td>{payment[QBFobIndex]}</td>
                                <td>{payment[type]}</td>
                                <td>${payment[QBRateIndex]}</td>
                            </tr>
                        );
                }
                )}
            </tbody>
        </Table>
    
    )
}
