import React from 'react'
import Table from "react-bootstrap/Table";

export default function MonthliesRevenueTable(props) {

    const { payments, fobStatusIndex, fob, name, type, rate } = props

    console.log("payments",payments)
    let activeSum = 0.00
    for(let i = 1; i<payments.length; i++){
        if((payments[i][fobStatusIndex] === "Active") || payments[i][fobStatusIndex] === "active" || payments[i][fobStatusIndex]==='Last Month') {
            if(payments[i][rate]?.length > 0){
                console.log(payments[i][rate])
                activeSum += (parseFloat(payments[i][rate].replace(/[^0-9.]/g, '')))
            }    
        }
    }
    return (
        <Table striped bordered className="table-sm report">
            <thead>
                <tr className="table-success">
                    <th></th>
                    <th></th>
                    <th>Total:</th>
                    <th>${activeSum.toLocaleString()}</th>
                </tr>
            </thead>
            <thead>
                <tr className="table-warning">
                    <th>Name</th>
                    <th>FOB</th>
                    <th>Type</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {payments.slice(1).map((payment, i) => {
                    if((payment[fobStatusIndex] === "Active") || payment[fobStatusIndex]==='Last Month') {
                        return (
                            <tr key={i}>
                                <td>{payment[name]}</td>
                                <td>{payment[fob]}</td>
                                <td>{payment[type]}</td>
                                <td>{payment[rate]}</td>
                            </tr>
                        );
                    }
                })}
            </tbody>
        </Table>
    )
}
