import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RatePicker.scss'
export default function RatePicker(props) {

    const { garage, setRate, setData } = props
    console.log(garage)

    const [rates, setRates] = useState([])

    const getGarageRates = async (garage) => {
        try {
            const garageInfo = JSON.parse(localStorage.getItem(`${garage}Info`))
            const databaseConnection = garageInfo.databaseConnection;
            const response = await axios
                .get('https://automotion-heroku-server.herokuapp.com/rates/rateList'
                , {
                    params: {
                        garageName: garage,
                        connection: databaseConnection,
                    }
                })
            console.log(response.data)
            setRates(response.data)
        } catch (e) {
            alert(e)
        }
    }

    useEffect(() => {
        getGarageRates(garage)
    }, [])

    const setVals = (e) => {
        setData(null)
        setRate(e.target.value)
    }
    return (
        <div className='ratePicker'>
            <select onChange={(e) => setVals(e)}>
                <option value="">Select Rate</option>
                <option value="All">All Tickets</option>
                <option value="Other">Other</option>
                <option value="NC/0">NC/0</option>
                {
                    rates.map((rate, i) => {
                        return rate.Category === "Special" ? <option key={i} value={rate.Name.split(" ")[0]}>{rate.Name} - ${rate.Rate.toFixed(2)}</option>
                        : <option key={i} value={rate.Rate.toFixed(2)}>{rate.Name} - ${rate.Rate.toFixed(2)}</option>
                    })
                }
            </select>
        </div>
    )
}
