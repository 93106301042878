import * as React from 'react';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function NotificationBadge({ count, notificationsClickHandler }) {

  return (
    <div
      className='m-auto p-1'
      onClick={notificationsClickHandler}
    >
      <Badge style={{ cursor: 'pointer' }} badgeContent={count} overlap='circular' color="success">
        <NotificationsNoneIcon sx={{ color: 'white' }} />
      </Badge>
    </div>
  )
}