import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import excelIcon from '../../assets/excelIcon.png';
import excelIconBlue from '../../assets/excelIconBlue.png';
import { pdfExport } from "../../components/EmailSender/EmailPdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { Button } from "@mui/material";
import { ExportToCsv } from 'export-to-csv';
import ExcelDownloadButton from '../ExcelDownloadButton/ExcelDownloadButton';


export default function ToolBar({ displayHandler, displayForm, container, csvData, garageName, formattedDate, report }) {

    const userStatusValue = localStorage.getItem('userStatusValue');
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        filename: `${garageName}_${report}_${formattedDate}`,
        title: `${garageName}_${report}_${formattedDate}`, // Formatted title
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };


    const csvExporter = new ExportToCsv(options);

    const handleExportData = (data) => {
        csvExporter.generateCsv(data);
    };

    const openPDF = (container) => {

        try {
            let gridElement = document.querySelector('#PDFExport');
            drawDOM(gridElement, {
                paperSize: "Letter",
                scale: 0.68,
                forcePageBreak: ".page-break",
                margin: { top: 15, right: 45, left: 45 }
            })
                .then((group) => {
                    return exportPDF(group);
                })
                .then(async (dataUri) => {
                    console.log("dataURI", dataUri)
                    let formattedURI = dataUri.split(',')[1]
                    const binaryPdf = atob(formattedURI);
                    const length = binaryPdf.length;
                    const uint8Array = new Uint8Array(new ArrayBuffer(length));
                    for (let i = 0; i < length; i++) {
                        uint8Array[i] = binaryPdf.charCodeAt(i);
                    }
                    const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    window.open(pdfUrl, "_blank");
                })
        } catch (e) {
            console.log(e)
            alert(e)
        }
    };

    const printPDF = (container) => {
        try {
            let gridElement = document.querySelector('#PDFExport');
            drawDOM(gridElement, {
                paperSize: "Letter",
                scale: 0.68,
                forcePageBreak: ".page-break",
                margin: { top: 15, right: 45, left: 45 }
            })
                .then((group) => {
                    return exportPDF(group);
                })
                .then(async (dataUri) => {
                    console.log("dataURI", dataUri)
                    let formattedURI = dataUri.split(',')[1]
                    const binaryPdf = atob(formattedURI);
                    const length = binaryPdf.length;
                    const uint8Array = new Uint8Array(new ArrayBuffer(length));
                    for (let i = 0; i < length; i++) {
                        uint8Array[i] = binaryPdf.charCodeAt(i);
                    }
                    const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    const pdfWindow = window.open(pdfUrl, "_blank")

                    pdfWindow.onload = () => {
                        pdfWindow.print();
                    }
                })
        } catch (e) {
            console.log(e)
            alert(e)
        }
    }

    let len = window.location.pathname.split('/').length
    console.log(len)
    let path = (window.location.pathname.split('/')[len - 1])
    let pathsNotUsingExportToCSV = ["Daily", "Filtered", "Errors", "Filter", "Revenue", "Lex"]

    return (
        <>
            {userStatusValue > 0
                && <div>
                    <div className="toolbar__icons">
                        <Button onClick={displayHandler} color="primary" variant="contained" startIcon={<EmailIcon />}>{displayForm ? "Close" : "Email"}</Button>
                        <Button onClick={() => printPDF(container)} disabled={false} color="primary" variant="contained" startIcon={<LocalPrintshopIcon />}>Print</Button>
                        <Button onClick={() => openPDF(container)} disabled={false} color="primary" variant="contained" startIcon={<PictureAsPdfIcon />}>Open PDF</Button>
                        <Button onClick={() => pdfExport(container)} color="primary" variant="contained"
                            startIcon={<DownloadIcon />}
                        >
                            Download PDF
                        </Button>
                        {
                            (report !== "Transients_Report" && pathsNotUsingExportToCSV.includes(path)) ?
                                <ExcelDownloadButton
                                    garageName={garageName}
                                    formattedDate={formattedDate}
                                    report={report}
                                />
                                :
                                <Button onClick={() => handleExportData(csvData)} color="primary" variant="contained">
                                    Download Excel
                                </Button>
                        }
                    </div>
                    <div className="toolbar__icons__mobile">
                        <Button onClick={displayHandler}><EmailIcon /></Button>
                        <Button onClick={() => printPDF(container)} disabled={false}><LocalPrintshopIcon /></Button>
                        <Button onClick={() => openPDF(container)} disabled={false}><PictureAsPdfIcon /></Button>
                        <Button><DownloadIcon onClick={() => pdfExport(container)} /></Button>
                        {pathsNotUsingExportToCSV.includes(path) ?
                            <ExcelDownloadButton
                                garageName={garageName}
                                formattedDate={formattedDate}
                                report={report}
                            />
                            :
                            <Button onClick={() => handleExportData(csvData)}>
                                <img src={excelIconBlue} />
                            </Button>}
                    </div>
                </div>
            }</>
    )
}
