import * as Yup from 'yup';

export const newGarageValidationSchema = Yup.object().shape({
  garageName: Yup.string()
    .required('Garage Name is required')
    .notOneOf([''], 'Garage Name is required'),

  selectedCompany: Yup.string()
    .required('Company Name is required')
    .notOneOf([''], 'Company Name is required'),

  garageType: Yup.string()
    .required('Garage Type is required')
    .notOneOf([''], 'Garage Type is required'),

  databaseConnection: Yup.string()
    .required('Database Connection is required')
    .notOneOf([''], 'Database Connection is required'),

  spaces: Yup.number()
    .typeError('Number of Spaces must be a number')
    .required('Number of Spaces is required')
    .min(1),
});


