import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { saveNewCompany } from '../../firebase';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';

function AddCompanyModal(props) {
    const { showAddCompanyModal, setShowAddCompanyModal, user, userName, userCompany } = props

    const [companyName, setCompanyName] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleClose = () => setShowAddCompanyModal(false);
    const handleShow = () => setShowAddCompanyModal(true);

    const handleChange = (e) => {
        setCompanyName(e.target.value)
    }

    const handleSave = async() => {
        setIsLoading(true)
       let companyExists = await saveNewCompany(companyName, userName, userCompany)
       setCompanyName("")
       handleClose()

       setIsLoading(false)
       if(!companyExists){
        alert("New Company Created")
       }else{
        alert("Company Already Exists")
       }
       
    }

    return (
        <>

            <Modal
                show={showAddCompanyModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Company</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <LoadingSpinner/> :
                    <Form.Group className="mb-3" controlId="companyName">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" value={companyName} placeholder="New Co. LLC" onChange={handleChange}/>
                    </Form.Group>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddCompanyModal;