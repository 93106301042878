import './SummaryPage.scss'
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import schemehorn from "../../assets/schemehorn.png"
import Navigation from '../../components/Navigation/Navigation'
import { UserAuth } from '../../components/Context/AuthContext';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import Charts from '../../components/Charts/Charts';
import SchemChart from '../../components/SchemChart/SchemChart';
import { formatDate } from '../AutomatedDailyReportPage/AutomatedDailyReportHelpers';
import { getAllGarages } from '../../firebase';
import Default_Garage_Image from '../../assets/Default_Garage_Image.png';

function SummaryPage() {

  //loading state for all the summary data set to true on login
  const [isLoading, setIsLoading] = useState(true);

  //array to store all garage names 
  const [allGaragesArray, setAllGaragesArray] = useState([])

  //array to store all garage Data (spaces, imageURL, database, etc)
  const [allGaragesData, setAllGaragesData] = useState([])

  //state that stores the current tab
  const [key, setKey] = useState(null);

  //all the garage summary for all automated garages
  const [data, setData] = useState({});

  //time displayed on summary heading
  const [time, setTime] = useState("")

  //the hourly data for all automated garages
  const [occupancyData, setOccupancyData] = useState(null)

  // ========= Schemerhorn data starts here ================================================================ 
  //Schemerhorn tickets summary data
  const [schemTickets, setSchemTickets] = useState(null)

  //schmerhorn garage summary volume data (this data is only used as a prop passed to the schemChart component)
  const [schemVolume, setSchemVolume] = useState(null)

  //schmerhorn garage summary payments data (this data is only used as a prop passed to the schemChart component)
  const [schemPayments, setSchemPayments] = useState(null)
  //=========== End schemerhorn data ==========================================================

  //get the users value from firebase to determine the users authorization level (0 = tech, 1 = admin, 2 = super admin)
  const { userStatusValue, user, userCompany } = UserAuth();

  console.log(user, userCompany)

  //get the date and format it into mm/dd/yyyy, hh:mm:ss AM/PM
  const today = new Date();
  const formattedDate = formatDate(today);

  //get the JWT token from session storage
  const token = sessionStorage.getItem('token');

  const cachingFunction = (cachedDataName, cachedTimestampName, set) => {
    try {
      //get the data stored under 'garageData' key in localStorage
      const cachedData = localStorage.getItem(cachedDataName);

      //timestamp used for comparison to see if the data is old, if its old (10 mins) make a new API call
      const cachedTimestamp = localStorage.getItem(cachedTimestampName);

      //if data data exists
      if (cachedData && cachedTimestamp) {

        //get the data from local storage
        const parsedData = JSON.parse(cachedData);

        //get the timestamp from localstorage
        const timestamp = parseInt(cachedTimestamp);

        // Check if cached data is within the expiration time (10 minutes)
        const currentTime = Date.now();
        const expirationTime = 10 * 60 * 1000; // 10 minutes in milliseconds

        //if the data was fetched within the past 10 minutes
        if (currentTime - timestamp <= expirationTime) {
          //set the data using the data from local storage
          set(parsedData);
          return true;
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Function to fetch garage data for a single garage
  const fetchGarageData = async (garage) => {
    try {
      const response = await axios.get(
        //"http://localhost:8080/garagedata/metrics", 
        'https://automotion-heroku-server.herokuapp.com/garageData/metrics',
        {
          params: {
            garage: garage[0],
            inDate: formattedDate,
            outDate: formattedDate,
            connection: garage[1].databaseConnection
          },
          headers: {
            authorization: 'Bearer ' + token
          }
        });
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to the login page if it's an authentication error
        nav('/login');
      } else {
        // Log other types of errors
        console.error('Error fetching garage data:', error.message);
        return { Error: "Error fetching data from database" }
      }
    }
  };

  // Function to process responses and handle errors
  const processResponses = (responses, allGarages) => {
    const data = {};
    allGarages.forEach((garage, index) => {
      const garageName = garage[0];
      const response = responses[index];
      if (response && response.data && response.data.data) {
        data[garageName] = response.data.data;
      } else {
        data[garageName] = response
      }
    });
    setData(data);
    //Since this is new data, Cache the data in local storage along with timestamp
    const currentTimestamp = Date.now();
    localStorage.setItem('garageData', JSON.stringify(data));
    localStorage.setItem('garageDataTimestamp', currentTimestamp.toString());
    return data;
  };

  const nav = useNavigate()

  //API call for each garage
  const getGarageData = async (allGarages) => {
    //before making an API call, first check to see if there is recent data already stored locally
    const dataExists = cachingFunction('garageData', 'garageDataTimestamp', setData)
    //if theres no data or its more than 10 minutes old, make a new API call for all garages
    if (!dataExists) {
      try {
        // Make API calls for all garages in parallel
        const promiseArray = allGarages.map(garage => fetchGarageData(garage));
        const responses = await Promise.all(promiseArray);
        // Process responses and handle errors
        const data = processResponses(responses, allGarages);
        // Cache the data in local storage along with timestamp
        const currentTimestamp = Date.now();
        localStorage.setItem('garageData', JSON.stringify(data));
        localStorage.setItem('garageDataTimestamp', currentTimestamp.toString());
      } catch (error) {
        console.error('Error fetching garage data:', error.message);
      }
    }
  }

  //function for garage Occupancy for automated Garages. 
  const getOccupancyData = async (allGarages, garageDataObject) => {

    console.log("allgarageData:", garageDataObject)
    //function to check if the garage Occupancy data is stored in local memory
    const dataExists = cachingFunction('occupancyData', 'occupancyDataTimestamp', setOccupancyData)
    let garageNames = allGarages.map(garage => garage[0]);
    //only make a new API call for the ocupancy data if there's no recent occupancy data in local memory
    if (dataExists === false) {
      //API call with Axios
      const response = await axios.get(
        'https://automotion-heroku-server.herokuapp.com/occupancyData',
        //'http://localhost:8080/occupancyData',
        {
          params: {
            // garageNames,
            garageDataObject

          },
          headers: {
            authorization: 'Bearer ' + token
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
          }
        })

      //object to store occupancy data for each garage
      let occupancy = {};
      const occupancyData = response.data
      const garageNames = Object.keys(garageDataObject)
      garageNames.forEach(garageName => {

        occupancy[garageName] = occupancyData[garageName];
      });

      //set the state to the values stored in the occupancy object
      setOccupancyData(occupancy)

      //get the curent time
      const currentTimestamp = Date.now();
      //store the occupancy data locally to be used for future requests
      localStorage.setItem('occupancyData', JSON.stringify(occupancy));

      //store the current time locally. This will be used to calculate if the occupancy data stored locally is expired
      localStorage.setItem('occupancyDataTimestamp', currentTimestamp.toString());
    }
  }

  //function to get all the schem data needed for summary page and other Schemerhorn reports
  const getSchemehornData = async () => {

    //for each piece of data, first check the local storage to know if a new API call is needed
    const cachedTickets = localStorage.getItem('schemTickets');
    const cachedVolume = localStorage.getItem('schemVolume');
    const cachedPayments = localStorage.getItem('schemPayments');
    const cachedTimestamp = localStorage.getItem('schemTimestamp');

    //if all the data we need already exists locally, grab the data
    if (cachedTickets && cachedVolume && cachedPayments && cachedTimestamp) {
      const parsedTickets = JSON.parse(cachedTickets);
      const parsedVolume = JSON.parse(cachedVolume);
      const parsedPayments = JSON.parse(cachedPayments);
      const timestamp = parseInt(cachedTimestamp);

      // Check if cached data is within the expiration time (10 minutes)
      const currentTime = Date.now();
      const expirationTime = 10 * 60 * 1000; // 10 minutes in milliseconds

      //if the data is recent data, set the schemerhorn state to the data taken from local storage
      if (currentTime - timestamp <= expirationTime) {
        setSchemTickets(parsedTickets);
        setSchemVolume(parsedVolume);
        setSchemPayments(parsedPayments);
        return;
      }
    }

    //if the data doesnt exist or its too old, 
    //get the JWT token from local storage and use it to make a new Schemerhorn data API call
    const token = sessionStorage.getItem('token');

    try {
      const response = await axios.get(
        'https://automotion-heroku-server.herokuapp.com/schemehorn/summary',
        // 'http://localhost:8080/schemehorn/summary',
        {
          //auth header is used to authorize the API request
          headers: {
            authorization: 'Bearer ' + token,
          },

          //the data param is todays date, it will query the SChemerhorn API for todays data only
          params: {
            date: formattedDate,
          },
        })
        .catch(error => {
          if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
          }
        })

      // Update state with API response
      const { ticketResponse, volumeData, payments } = response.data;
      setSchemTickets(ticketResponse);
      setSchemVolume(volumeData);
      setSchemPayments(payments.schemPayments);

      // Cache the data in local storage along with timestamp
      const currentTimestamp = Date.now();
      localStorage.setItem('schemTickets', JSON.stringify(ticketResponse));
      localStorage.setItem('schemVolume', JSON.stringify(volumeData));
      localStorage.setItem('schemPayments', JSON.stringify(payments.schemPayments));
      localStorage.setItem('schemTimestamp', currentTimestamp.toString());

    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  const fetchData = async () => {
    if (userCompany && Number.isInteger(userStatusValue) && user) {
      try {
        // Start the loading state
        setIsLoading(true);

        // Run all functions at the same time and await the response until the data for all of them is returned
        let garages = await getAllGarages(userCompany)
        setKey(garages[0][0])

        for (const garage of garages) {
          localStorage.setItem(`${garage[0]}Info`, JSON.stringify(garage[1]));
        }

        setAllGaragesArray(garages.map(garage => garage[0]));
        
        const garageDataObject = garages.reduce((acc, garage) => {
          // Assuming garage[0] is the garage name and garage[1] is the garage data
          acc[garage[0]] = garage[1];
          return acc;
        }, {});
        
        setAllGaragesData(garageDataObject);

        await Promise.all([getGarageData(garages), getSchemehornData(), getOccupancyData(garages, garageDataObject)]);

        const options = {
          timeZone: 'America/New_York',
          hour12: true, // Use 12-hour format
        };
        const nycTime = new Date().toLocaleString('en-US', options);
        setTime(nycTime);

      } catch (error) {
        console.log(error);
      } finally {
        // End the loading state regardless of success or failure
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [userCompany, userStatusValue, user]);

  console.log(allGaragesArray)

  return (
    <>
      {/* display the nav bar */}
      <Navigation />
      {isLoading ? (
        //while the data is loading, display the loading spinner
        <LoadingSpinner />
      ) : (
        // the tabs on the main summary page
        <div className="cards d-flex justify-content-center">
          <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            {allGaragesArray.map((garage) => {
              console.log("Key:", key, "Garage:", garage);

              return (
                <Tab eventKey={garage} title={garage} key={garage}>
                  {/* the summary page by default, is set the the key === 'Baxter' so that its the default summary data shown */}
                  {key === garage && data[garage] && <Charts
                    userStatusValue={userStatusValue}
                    time={time}
                    spaces={allGaragesData[garage].spaces}
                    garageImage={allGaragesData[garage].selectedImage || Default_Garage_Image}
                    data={data[garage]}
                    occupancyData={occupancyData[garage]}
                    urlParam={key}
                  />}
                </Tab>
              )
            })}

            {/* only show schem tab to users above the tech level */}
            {(userStatusValue > 0 && userCompany === "Automotion Parking LLC") &&
              // currentUser.otherGarages.includes("Schemehorn") && 
              <Tab eventKey="Schemehorn" title="Schemehorn">
                {key === 'Schemehorn' && (schemTickets && schemVolume) && <SchemChart
                  garageImage={schemehorn}
                  tickets={schemTickets}
                  volume={schemVolume}
                  payments={schemPayments}
                  time={time}
                />}
              </Tab>}
          </Tabs>
        </div>
      )}
    </>
  );
}

export default SummaryPage;
