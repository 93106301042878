import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './CRUDConfirmationModal.scss'

//Creates a pop-up confirmation modal whwever a user is created, updated, or deleted. Also used for schedule creation
function CRUDConfirmationModal(props) {
 
  const {
    show, //true/false if the modal should appear
    handleClose, //function to handle closing the modal
    body //the text of the modal
  } = props

  return (
    <>
      <Modal className="special_modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{body}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body === 'User Updated' ? <p>Note: If you changed your personal credentials, you will be required to login again using your new credentials</p>:""}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CRUDConfirmationModal;





