import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import { getStorage, ref, uploadBytes } from "firebase/storage";

function UploadModal(props) {

  const {show, handleClose, folderName} = props 

  const [fileToUpload, setFileToUpload] = useState(null)

  const addFileHandler = (e) => {
    e.preventDefault()

    const storage = getStorage();
    const storageRef = ref(storage, `${folderName}/${fileToUpload.name}`);

    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, fileToUpload).then((snapshot) => {
        
        handleClose()
        // alert('file uploaded!');
        window.location.reload()
    });
 }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose File to Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" onChange={e=> setFileToUpload(e.target.files[0])} />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
        
          <Button variant="primary" onClick={addFileHandler} >
            Upload
          </Button> 
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadModal