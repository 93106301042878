import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import AddRateModalBody from '../AddRateModalBody/AddRateModalBody';
import { saveNewRate } from '../../firebase';
import { UserAuth } from '../Context/AuthContext';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import { saveAction } from '../../firebase';

export default function AddRateModal({
  showAddRateModal,
  setShowAddRateModal,
  garageName,
  setSnackbarInfo,
  setOpenSimpleSnackbar,
  companyForUpdate,
  userName,
  userCompany
}) {

  const [isSending, setIsSending] = useState(false)
  const [inputs, setInputs] = useState({
    rateType: 'Monthlies',
    amount: '',
    rateName: '',
    //rateUnit: 'minute'
  });

  const { user, setNumberOfNotifications } = UserAuth()

  const closeModal = () => setShowAddRateModal(false);

  const handleSave = async () => {
    try {
      if (inputs.amount === "" || inputs.rateName === "") {
        return alert("Please provide the Rate Name and the Rate Amount")
      }
      setIsSending(true)
      const companyId = companyForUpdate;
      const garageId = garageName;
      console.log(inputs)
      const newRateObject = inputs;
      console.log(companyId, garageId, newRateObject)
      const actionDetails = {
        action: "Add Rate Request",
        details: {
          Name: newRateObject.rateName,
          Garage: garageName,
          Amount: parseFloat(newRateObject.amount).toFixed(2)
        },
        createdBy: {
          userName,
          userCompany
        },
        dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
      }

      try {
        await saveAction(actionDetails)
      } catch (e) {
        console.log(e)
      }
      let success = await saveNewRate(companyId, garageId, newRateObject, user.email)
      if (success === true) {
        setSnackbarInfo({ text: "New rate submitted for review", severity: "success" })
      } else {
        setSnackbarInfo({ text: "Error: Could not submit new rate for review", severity: "error" })
      }
      setInputs({
        rateName: "",
        rateType: "Monthly",
        amount: ""
      })
      setOpenSimpleSnackbar(true)
      setShowAddRateModal(false)
      setIsSending(false)
      setNumberOfNotifications(prevNumber => prevNumber + 1)
    } catch (e) {
      //alert(e)
      setSnackbarInfo({ text: "Rate Couldn't be Added", severity: "error" })
    }
  }

  return (
    <div>
      <Modal show={showAddRateModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isSending ? <>
              Sending Rate Request...
              <LoadingSpinner />
            </>
              :
              <Form>
                <AddRateModalBody
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={closeModal}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleSave}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
