import React from 'react';
import Login from '../../components/Login/Login';

function LoginPage(){

    return (
        <Login/>
    );
}

export default LoginPage;