import React, { useState, useContext } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import {UserAuth} from "../Context/AuthContext";

const ProtectedRoute = ({children})=> {
    const {user, userStatusValue, userName } = UserAuth();
    console.log("user", user)
    const token = sessionStorage.getItem('token');
    let path = (window.location.pathname)
    const {garageName,carId, folderName, formTitle} = useParams()
    
    let folder = folderName && folderName.replace(/ /g,"%20");
    let form = formTitle && formTitle.replace(/ /g,"%20"); 
    
    let techAllowedRoutes = [
        '/welcome',
        `/reportSelect/Baxter`, 
        `/reportSelect/VanVorst`, 
        `/reportSelect/Waverly`, 
        `/reportSelect/24th%20Street`, 
        `/reportSelect/${garageName}/Monthlies`, 
        `/reportSelect/${garageName}/Wait`,
        `/reportSelect/24th%20Street/Monthlies`, 
        `/reportSelect/24th%20Street/Wait`,
        `/reportSelect/${garageName}/monthlies/carDetails/${carId}`,
        `/forms`,
        `/forms/${folder}`,
        `/forms/${folder}/${form}`,
        `/resources`,
        `/resources/${folder}`
    ]
    if(!user || !token){
        return <Navigate to='/' />;
    }
  
    return (
            
        userStatusValue && userStatusValue < 1 ?
            techAllowedRoutes.includes(path)?
                children
            :<Navigate to='/unauthorized'/>
        :
        children    
    )
}

export default ProtectedRoute;
