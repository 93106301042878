import * as Yup from 'yup';

export const updateUserValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .test('password', 'Password must be at least 6 characters long', function(value) {
      if (value === '') {
        return true; // Allow empty string
      }
      return Yup.string().min(6).isValidSync(value); // Check if the string is at least 6 characters long
    })
    .nullable()
    .notRequired(),
  confirmPassword: Yup.string()
    .when('password', {
      is: val => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
      otherwise: Yup.string().nullable()
    }),
  userType: Yup.string().required('Please select a user type'),
});
