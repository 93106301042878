import React from 'react'
import { useNavigate } from "react-router-dom";
import '../Charts/Charts.scss';
import './SchemChart.scss'
import PieChart from '../PieChart/PieChart';
import BarChart from '../BarChart/BarChart';

export default function SchemChart(props) {

    const {
        garageImage,
        tickets,
        volume,
        payments,
        time
    } = props

    const nav = useNavigate();

    const clickHandler = () => {
        nav(`/reportSelect/schemehorn`);
    };

    const hoursElapsedToday = new Date().getHours()
    const convertTo2DArray = (volume) => {
        const result = [];

        for (let i = 0; i <= hoursElapsedToday; i++) {
            const hour = volume.hourRows[i].hour
            const parkIn = volume.hourRows[i].parkIn
            const parkOut = volume.hourRows[i].parkOut

            result.push([hour, parkIn, parkOut]);
        }
        console.log(result)
        return result;
    }

    const barChartData = [...convertTo2DArray(volume)]

    let pieChartData = [
        {
            label: "M.",
            data: 0
        },
        {
            label: "T.",
            data: 0
        },
        {
            label: "Valet",
            data: 0
        }
    ]
    tickets.schemTicketsArray.forEach((series) => {
        if (series.TicketStart?.slice(0, 1) === '1') {
            pieChartData[1].data = series?.TotalIssued
        } else if (series.TicketStart?.slice(0, 1) === '5') {
            pieChartData[0].data = series?.TotalIssued
        } else if (series.TicketStart?.slice(0, 1) === '6') {
            pieChartData[2].data = series?.TotalIssued
        }
    })

    const getSeries = (data) => {
        let series = data?.slice(0, 1);
        if (series) {
            if (series === '1') {
                return "Transients";
            } else if (series === '5') {
                return "Monthlies";
            } else if (series === '6') {
                return "Valet";
            }
        }
        return null;
    };

    return (
        <div className='full__container'>
            <div className='top__container'>
                <div className='left__container'>
                    <h5 className='m-0'>{time}</h5>
                    {/* garage logo */}
                    <img onClick={clickHandler} className="card__image" src={garageImage} />
                    {/* green total reenue background */}
                    <div className='table__metrics__revenue__container schem__rev__container'>
                        <p className='table__metrics__revenue__text'>Today's Total Revenue: ${(isNaN(Number(payments?.slice(-1)[0]?.total)) ? '0.00' : Number(payments?.slice(-1)[0]?.total).toLocaleString('en-US', { minimumFractionDigits: 2 }))}</p>
                    </div>
                    {/* daily metrics table */}
                    <table className="table table-striped table-sm table-bordered w-auto table_stats">
                        <tbody className='small'>
                            <tr>
                                <td>{""}</td>
                                <td>{getSeries(tickets.schemTicketsArray[0]?.TicketStart) || "T."}</td>
                                <td>{getSeries(tickets.schemTicketsArray[1]?.TicketStart) || "Valet"}</td>
                                <td>{getSeries(tickets.schemTicketsArray[2]?.TicketStart) || "Other"}</td>
                            </tr>
                            <tr>
                                <td>Starting Ticket:</td>
                                <td>{tickets.schemTicketsArray[0]?.TicketStart || "N/A"}</td>
                                <td>{tickets.schemTicketsArray[1]?.TicketStart || "N/A"}</td>
                                <td>{tickets.schemTicketsArray[2]?.TicketStart || "N/A"}</td>
                            </tr>
                            <tr>
                                <td>Ending Ticket:</td>
                                <td>{tickets.schemTicketsArray[0]?.TicketEnd || "N/A"}</td>
                                <td>{tickets.schemTicketsArray[1]?.TicketEnd || "N/A"}</td>
                                <td>{tickets.schemTicketsArray[2]?.TicketEnd || "N/A"}</td>
                            </tr>
                            <tr>
                                <td>Left Over:</td>
                                <td>{tickets.schemTicketsArray[0]?.TicketsLeftOver || 0}</td>
                                <td>{tickets.schemTicketsArray[1]?.TicketsLeftOver || 0}</td>
                                <td>{tickets.schemTicketsArray[2]?.TicketsLeftOver || 0}</td>
                            </tr>
                            <tr>
                                <td>Issued:</td>
                                <td>{tickets.schemTicketsArray[0]?.TotalIssued || 0}</td>
                                <td>{tickets.schemTicketsArray[1]?.TotalIssued || 0}</td>
                                <td>{tickets.schemTicketsArray[2]?.TotalIssued || 0}</td>
                            </tr>
                            <tr>
                                <td>Redeemed:</td>
                                <td>{tickets.schemTicketsArray[0]?.Redeemed || 0}</td>
                                <td>{tickets.schemTicketsArray[1]?.Redeemed || 0}</td>
                                <td>{tickets.schemTicketsArray[2]?.Redeemed || 0}</td>
                            </tr>
                            <tr>
                                <td>Out Standing:</td>
                                <td>{tickets.schemTicketsArray[0]?.OutStanding || 0}</td>
                                <td>{tickets.schemTicketsArray[1]?.OutStanding || 0}</td>
                                <td>{tickets.schemTicketsArray[2]?.OutStanding || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* schem summary left side closing div */}
                </div>

                {/* schem summary right side */}
                <div className='right__container'>
                    <div className='right__container__upper'>
                        <PieChart
                            data={pieChartData}
                            title="Schemerhorn Ticket Types"
                            chartId={"Schem Pie Chart"}
                        />
                    </div>
                    <div className="right__container__lower schem__right__lower">
                        <BarChart
                            data={barChartData}
                            title={"All Cars In/Out"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

