import React from 'react'
import Navigation from '../../components/Navigation/Navigation'
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";

export default function SchedulePanelPage() {

    //navigate to the selected page
    const nav = useNavigate();
    
    const createSchedule = async() => {
        nav(`create`);
    }

    const showSchedules = async() => {
        nav(`view`);
    }
    
    return (
    <div>
        <Navigation/>
        <div className='cards d-flex justify-content-center'>
        {/* create schedule button */}
        <button className="report-card__button" onClick={createSchedule}>
        <Card className="m-2" style={{ width: '15rem'}}>
            <Card.Body>
                <Card.Title>Create Schedule</Card.Title>
            </Card.Body>
        </Card>
        </button>

        {/* view schedule button */}
        <button className="report-card__button" onClick={showSchedules}>
        <Card className="m-2" style={{ width: '15rem'}}>
            <Card.Body>
                <Card.Title>View Schedules</Card.Title>
            </Card.Body>
        </Card>
        </button>
        </div>
    </div>
  )
}
