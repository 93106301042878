import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deleteCompany } from '../../firebase';

function DeleteCompanyModal(props) {
  const { 
    show,
    setShowDeleteModal,
    companyName,
    userName, 
    userCompany
  } = props

  const handleClose = async() => setShowDeleteModal(false);

  const confirmDeleteHandler = async (e) => {
    try{
        console.log(companyName)
        await deleteCompany(companyName, userName, userCompany)
        handleClose()
        window.location.reload()
    }catch(e){
        alert("Error:", e)
    }
  }
  
  return (

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete {companyName} ?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={confirmDeleteHandler}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteCompanyModal;