import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './ExcelDownloadButton.scss';
import { Button } from '@mui/material';
import excelIconBlue from '../../assets/excelIconBlue.png';

const ExcelDownloadButton = ({garageName, formattedDate, report}) => {
  const isSmallScreen = window.innerWidth < 770;

  return (
    <ReactHTMLTableToExcel
      className="xlsButton"
      id="test-table-xls-button"
      table="table-to-xls"
      filename={`${garageName}_${report}_${formattedDate}`}
      sheet="tablexls"
      buttonText={
        <div className="buttonWrapper">
          {isSmallScreen ? (
            <img src={excelIconBlue} alt="Excel Icon" />
          ) : (
            <Button variant="contained" color="primary">
              Download as Excel
            </Button>
          )}
        </div>
      }
    />
  );
};

export default ExcelDownloadButton;
