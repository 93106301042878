import Table from 'react-bootstrap/Table';
import '../TransactionTable/TransactionTable.scss';

function MonthlyOnlyTransactionTable({ hourlyTransactionTable }) {
    return (
        <>
            <Table striped bordered className='table-sm'>
                <thead>
                    <tr className='table-warning'>
                        <th className='hours'>Hour</th>
                        <th>M. In</th>
                        <th>M. Out</th>
                    </tr>
                </thead>
                <tbody>
                    {hourlyTransactionTable.map((row, index) => {
                        return (
                            <tr key={index} className={index === hourlyTransactionTable.length - 1 ? 'table-success' : ""}>
                                <th>{index === hourlyTransactionTable.length - 1 ? 'Totals' : row['TimeOfDay']}</th>
                                <td>{row['MInCount']}</td>
                                <td>{row['MOutCount']}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
}

export default MonthlyOnlyTransactionTable;
