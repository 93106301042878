import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deletePendingRequest } from '../../firebase';
import { sendAcceptPendingRequestEmail } from '../../firebase';
import { addAcceptRequestToLogs, deleteGarageFromFB } from '../../firebase';
import { UserAuth } from '../Context/AuthContext';

export default function ApproveRequestModal({show, setShow, selectedRowObject ,rowData, requestId, company, setNotifications, dataWithRemovedItem}) {

    const { setNumberOfNotifications, userName, userCompany } = UserAuth()

    const handleSave = async () => {
      console.log(selectedRowObject)
        console.log(company)
        setShow(false);
        deletePendingRequest(requestId, company)
        setNotifications(dataWithRemovedItem)
        if(selectedRowObject.Request === 'Delete Garage'){
          await deleteGarageFromFB(selectedRowObject.company, selectedRowObject.Garage, userName, userCompany)
        }else {
          addAcceptRequestToLogs(selectedRowObject, userName, userCompany)
        }
        await sendAcceptPendingRequestEmail(selectedRowObject, userName, userCompany)
        handleClose()
        setNumberOfNotifications(prevNumber => prevNumber - 1)
    }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Approve Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {rowData}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}