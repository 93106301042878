import { getStorage, ref, listAll, getDownloadURL, uploadBytes } from "firebase/storage";

//get garage image from firebase storage
async function getGarageImage(garageName) {
  // Create a reference to the file we want to download
  try {
    const storage = getStorage();
    const starsRef = ref(storage, `garageImages/${garageName}`);

    // Get the download URL
    const imageURL = await getDownloadURL(starsRef)

    console.log(imageURL)
    return imageURL
  } catch (error) {
    console.log(error)
  }
}

async function listFolders() {
  const storage = getStorage();
  let folderArray = []
  // Create a reference under which you want to list
  const storageRef = ref(storage,)
  // // Find all the prefixes and items.
  try {
    let res = await listAll(storageRef)
    res.prefixes.forEach((folderRef) => {
      console.log(folderRef.name)
      folderArray.push(folderRef.name)
    })
  } catch (e) {
    console.log(e)
  }
  return folderArray
}

async function listFiles(folder) {
  let fileArray = []
  const storage = getStorage();
  // Create a reference under which you want to list
  const storageRef = ref(storage, `${folder}/`)

  console.log(storageRef)
  // Find all the prefixes and items.
  try {
    let res = await listAll(storageRef)
    let promises = res.items.map(async (itemRef) => {
      let downloadURL = await getDownloadURL(itemRef)
      return [itemRef.name, downloadURL]
    })

    fileArray = await Promise.all(promises)

  } catch (error) {
    console.log(error)
  };
  return (fileArray);
}

async function addImageToFirebaseStorage(garageName, selectedImage) {
  if(selectedImage === ""){
    return null
  }
  const storage = getStorage();
  let storageRef = ref(storage, `garageImages/${garageName}`);

  // Upload the selectedImage to Firebase Storage
  try {
    await uploadBytes(storageRef, selectedImage)
      console.log('Uploaded a blob or file!');
      const imageURL = await getDownloadURL(storageRef)

      return (imageURL)
  } catch (error) {
    console.error('Error uploading image to Firebase Storage:', error);
    // Handle the error as needed (e.g., show an error message to the user).
  }
}

export { listFolders, listFiles, addImageToFirebaseStorage, getGarageImage }
