import React, { useState, useMemo } from "react";
import "./WaitTimePage.scss";
import { useParams, useNavigate } from "react-router-dom";
import { Typography } from '@mui/material';
import axios from "axios";
import DatePicker from "../../components/DatePicker/DatePicker";
import TypePicker from "../../components/TypePicker/TypePicker";
import TicketSelect from "../../components/TicketSelect/TicketSelect";
import LoadingSpinner from "../../components/LoadingWheel/LoadingWheel";
import Navigation from "../../components/Navigation/Navigation";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Button } from "@mui/material";
import EmailComponent from "../../components/EmailComponent/EmailComponent";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { MaterialReactTable } from 'material-react-table';
import ToolBar from "../../components/ToolBar/ToolBar";
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";

function WaitTimePage() {
  const [waitTimeData, setWaitTimeData] = useState(null);
  const [inDate, setIndate] = useState("");
  const [outDate, setOutDate] = useState("");
  const [type, setType] = useState("M");
  const [num, setNum] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false)

  const container = React.useRef(null);
  const { garageName } = useParams();

  const displayHandler = () => {
    setDisplayForm(!displayForm)
  }

  const nav = useNavigate()

  async function fetchData() {
    let response = [];
    if (inDate === "" && outDate === "" && num !== "") {
      // axios call to get all records of specified fob num
      const token = sessionStorage.getItem('token');

      const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
      const databaseConnection = garageInfo.databaseConnection;

      setIsLoading(true);
      response = await axios.get(
        process.env.REACT_APP_ALL_RETRIEVAL_TIME_URL,
        // "http://localhost:8080/retrievaltime/allFobData",
        {
          params: {
            garage: garageName,
            fobNum: num,
            type,
            connection: databaseConnection
          },
          headers: {
            authorization: 'Bearer ' + token
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
        })

      setIsLoading(false);
      setWaitTimeData(response.data);
    }
    else if (inDate !== "" && outDate !== "") {
      const token = sessionStorage.getItem('token');

      const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
      const databaseConnection = garageInfo.databaseConnection;

      setIsLoading(true);
      response = await axios.get(
        process.env.REACT_APP_RETRIEVAL_TIME_URL,
        //"http://localhost:8080/retrievaltime",
        {
          params: {
            garage: garageName,
            inDate,
            outDate,
            type,
            num,
            connection: databaseConnection
          },

          headers: {
            authorization: 'Bearer ' + token
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
        })

      setIsLoading(false);
      setWaitTimeData(response.data);
      sessionStorage.setItem(`${garageName}-waitTimeData`, JSON.stringify(response.data))
    } else {
      alert("provide a ticket/FOB number, date range, or both")
    }
  }

  const columns = useMemo(
    () => [
      {
        header: 'FOB',
        accessorKey: 'STOPAKey2', //simple accessorKey pointing to flat data
        size: 10,
      },
      // {
      //   header: 'Out',
      //   accessorKey: 'OutDateTime', //simple accessorKey pointing to flat data
      //   // size: 10,
      // },
      {
        header: 'Retrieval',
        accessorKey: 'LastRetrievalDateTime', //simple accessorKey pointing to flat data
        // size: 10,
      },
      {
        header: 'Wait',
        accessorKey: 'waitTime', //simple accessorKey pointing to flat data
        size: 8,
        muiTableBodyCellProps: ({
          cell
        }) => ({
          sx: {
            backgroundColor: cell.getValue() < 6 ? 'rgba(22, 184, 44, 0.5)' : cell.getValue() < 11 ? 'rgba(255, 158, 62, 0.8)' : 'rgba(255, 58, 62, 0.8)',
          }
        })
      },
    ],
    [],
  );

  const today = new Date();
  today.setDate(today.getDate());
  const formattedDate = formatDate(today);

  return (
    <div>
      <Navigation />
      {isSending ? <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box> : ""}
      <div className="report__container">
        <p className="heading">{garageName} Garage Wait Times Report</p>
        <ToolBar
          displayHandler={displayHandler}
          displayForm={displayForm}
          container={container}
          csvData={waitTimeData}
          garageName={garageName}
          formattedDate={formattedDate}
          report={"waitTimes"}
        />
        {displayForm ?
          <EmailComponent
            setIsSending={setIsSending}
            fileName={`${garageName}_WaitTime_Report_${formattedDate}`}
          /> : ""
        }
        <div className="wait-times-pickers">
          <DatePicker value={inDate} label={"In-Date 12:00AM"} setDate={setIndate} />
          <DatePicker value={outDate} label={"Out-Date 11:59PM"} setDate={setOutDate} />
          <div className="selectors">
            <TicketSelect label={"Ticket Num/FOB"} num={num} setNum={setNum} />
            <TypePicker label={"Type"} type={type} setType={setType} />
            <div className="button__container">
              <Button className="m-2" variant="contained" onClick={fetchData}>
                Submit
              </Button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div id="PDFExport">
            <PDFExport fileName={`${garageName}_WaitTime_Report+${formattedDate}`} forcePageBreak=".page-break" scale={0.65} paperSize="Letter" margin={{ top: 5, left: 5, right: 5, bottom: 5 }} ref={container}>
              <div className="wait-table">
                <div className='rate-table-header'>
                  <p className='rate-table-header__text'>{garageName} Wait Time Report</p>
                  <p className='rate-table-header__text'><b>From: </b>{inDate} <b>To: </b>{outDate} </p>
                </div>
                {waitTimeData && <MaterialReactTable
                  columns={columns}
                  data={waitTimeData}
                  enableDensityToggle={false}
                  initialState={{ density: 'compact' }}
                  enableColumnActions={false}
                  renderDetailPanel={({ row }) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: "column",
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                      }}
                    >
                      <Typography>In: {row.original.InDateTime}</Typography>
                      <Typography>Out: {row.original.OutDateTime}</Typography>
                      <Typography>Type: {row.original.Type}</Typography>
                      <Typography>Size: {row.original.Oversize}</Typography>
                      {type === 'M' ?
                        <>
                          <Typography>Customer Name: {row.original.CustomerName}</Typography>
                          <Typography>Customer Number: {row.original.CustomerNumber}</Typography>
                          <Typography>Car Bar Code: {row.original.CarBarCode}</Typography>
                          <Typography>Rate: {row.original.Rate}</Typography>
                          <Typography>License Plate: {row.original.LicensePlate}</Typography>
                          <Typography>Year, Make, Model: {row.original.Year} {row.original.Make} {row.original.Model}</Typography>
                          <Typography>Color: {row.original.Color}</Typography>
                          <Typography>Street Address: {row.original.StreetAddress}</Typography>
                          <Typography>City, State, Zip: {row.original.City} {row.original.State} {row.original.Zip}</Typography>
                          <Typography>Main Phone: {row.original.MainPhone}</Typography>
                          <Typography>Alt Phone: {row.original.AltPhone}</Typography>
                          <Typography>Num Allowed To Park: {row.original.NumAllowedToPark}</Typography>
                          <Typography>Alt Phone: {row.original.NumCurrentlyParked}</Typography>
                        </> :
                        <Typography>Contact Info: {row.original?.ContactInfo || "None"}</Typography>
                      }
                    </Box>
                  )}
                />
                }
              </div>
            </PDFExport>
          </div>
        )}
      </div>
    </div>
  );
}

export default WaitTimePage;