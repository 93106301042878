import * as Yup from 'yup';

export const newScheduleValidationSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
//  selectedGarages: Yup.object().required('Garages are required'),
  subject: Yup.string().required('Subject is required'),
  time: Yup.string().required('Time is required'),
  recipients: Yup.string()
    .matches(
      /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(, ?|$))+$/,
      'Invalid email format'
    )
    .required('Recipients are required'),
});

