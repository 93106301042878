import { initializeApp } from "firebase/app";
import { doc, deleteDoc, setDoc, getFirestore, collection, getDocs } from "firebase/firestore";
import { saveAction } from '../firebase';
import { update } from "firebase/database";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(app);

//function to add user to firestore database
async function writeUserData(userId, name, email, userType, companyName, userName, userCompany) {
    let userStatus =
        (userType === 'Admin') ?
            1
            : (userType === 'Super Admin') ?
                2
                : 0;
    const actionDetails = {
        action: "User Created",
        details: {name},
        createdBy: {
            userName,
            userCompany
        },
        dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
    }

    try {
        await saveAction(actionDetails)
    } catch (e) {
        console.log(e)
    }
    const lowerCaseEmail = email.toLowerCase()
    console.log(lowerCaseEmail)
    setDoc(doc(firestoreDB, `companies/${companyName}/${userType}s`, userId), {
        company: companyName,
        email: lowerCaseEmail,
        status: userStatus,
        type: userType,
        username: name,
        id: userId
    });
}

//function to remove user from firestore database
async function removeUserData(company, userType, userId) {
    // Delete the file
    await deleteDoc(doc(firestoreDB, `companies/${company}/${userType}s/${userId}`));
}

//function to modify user in the real-time database
async function UpdateUserData(updatedUser) {
    await deleteDoc(doc(firestoreDB, `companies/${updatedUser.company}/${updatedUser.oldUserType}s/${updatedUser.id}`))
    setDoc(doc(firestoreDB, `companies/${updatedUser.company}/${updatedUser.newUserType}s`, updatedUser.id), {
        company: updatedUser.company,
        email: updatedUser.newEmail,
        id: updatedUser.id,
        status: updatedUser.status,
        type: updatedUser.newUserType,
        username: updatedUser.username,
    });
}

//function returns array of all companies
async function getListOfAllCompanies() {
    const query = collection(firestoreDB, `companies`);
    let companies = []
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach((doc) => {
        doc.data()
        companies.push(doc.id);
    });
    return companies
}

//function to get all users by Company 
async function getAllUsersByCompany(companyName) {
    const queryForAdmins = collection(firestoreDB, `companies/${companyName}/Admins`);
    const queryForTechs = collection(firestoreDB, `companies/${companyName}/Techs`);
    const queryForSuperAdmins = collection(firestoreDB, `companies/${companyName}/Super Admins`);

    let promises = [
        getDocs(queryForAdmins),
        getDocs(queryForTechs),
        getDocs(queryForSuperAdmins)
    ];

    let [admins, techs, superAdmins] = await Promise.all(promises);

    let users = {
        'Super Admins': [],
        Admins: [],
        Techs: [],
    };

    admins.forEach((doc) => {

        users.Admins.push({ ...doc.data(), id: doc.id });
    });

    techs.forEach((doc) => {
        users.Techs.push(doc.data());
    });

    superAdmins.forEach((doc) => {
        users['Super Admins'].push(doc.data());
    });

    return users;
}

async function getAllUsersForAllCompanies() {
    const companies = await getListOfAllCompanies();
    let promises = companies.map(company => getAllUsersByCompany(company));
    let users = await Promise.all(promises);

    let allUsers = {};
    companies.forEach((company, index) => {
        allUsers[company] = users[index];
    });

    return allUsers;
}

async function isATechEmail(email) {
    try {
        let isTech = false;
        let allUsers = await getAllUsersForAllCompanies()
        for (const company in allUsers) {
            for (const userType in allUsers[company]) {
                allUsers[company][userType].forEach((user) => {
                    if (user.email === email) {
                        if (user.type === "Tech") {
                            isTech = true
                        }
                    }
                })
            }
        }
        return isTech;
    } catch (error) {
        console.log(error);
        return false;
    }
}

//function to check if the provided email is a current users email from firestore database
async function usersEmailExists(email) {
    let emailExists = false;
    let allUsers;
    try {
        allUsers = await getAllUsersForAllCompanies()
    } catch (e) {
        console.log(e)
    }
    for (const company in allUsers) {
        for (const userType in allUsers[company]) {
            allUsers[company][userType].forEach((user) => {
                if (user.email === email) {
                    emailExists = true
                }
            })
        }
    }
    console.log(emailExists)
    return emailExists;
}

export {
    writeUserData,
    removeUserData,
    UpdateUserData,
    usersEmailExists,
    isATechEmail,
    getAllUsersByCompany,
    getAllUsersForAllCompanies,
}
