import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import axios from "axios";

const base64Email = (from, to, body, setIsSending, fileName, nav) => {
    
    try {
        const token = sessionStorage.getItem('token');
        let gridElement = document.querySelector('#PDFExport');
        drawDOM(gridElement, {
            paperSize: "Letter",
            scale: 0.68,
            forcePageBreak: ".page-break",
            margin: { top: 15, right: 45, left: 45 }
        })
            .then((group) => {
                return exportPDF(group);
            })
            .then(async (dataUri) => {
                setIsSending(true)
                await axios.post(
                    //"http://localhost:8080/emailGenerator",
                    process.env.REACT_APP_EMAIL,
                    {
                        dataUri,
                        from: from,
                        to: to,
                        body: body,
                        fileName
                    },
                    {
                        headers: {
                            authorization: 'Bearer ' + token
                        }
                    })
                    .then(response => {
                        if (response.status === 200) {
                            alert(response.data)
                        }
                        setIsSending(false)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            // Redirect to the login page if it's an authentication error
                            nav('/login');
                        }
                    })
                
            })
    } catch (e) {
        setIsSending(false)
        console.log(e)
        alert(e)
    }
};

function pdfExport(container) {
    if (container.current) {
        container.current.save();
    }
}

export { base64Email, pdfExport }
