import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { deleteSchedule } from '../../firebase';
import { saveAction } from '../../firebase';

export default function DeleteScheduleModal(props) {

  const { id, email, scheduleTitle, showDeleteScheduleModal, setShowDeleteScheduleModal, userName, userCompany } = props
  const handleClose = () => setShowDeleteScheduleModal(false);

  const deleteHandler = async (e) => {
    const title = scheduleTitle.split('.')
    const subject = title[0]
    const time = title[1]
    const actionDetails = {
      action: "Schedule Deleted",
      details: {
        Subject: subject,
        Time: time
      },
      createdBy: {
        userName,
        userCompany
      },
      dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
    }

    try {
      await saveAction(actionDetails)
    } catch (e) {
      console.log(e)
    }
    await deleteSchedule(id, email)
    handleClose()
    window.location.reload()
  }

  return (
    <div>

      <Modal show={showDeleteScheduleModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete: "{scheduleTitle}"?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteHandler}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
