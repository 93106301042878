import { createContext, useContext, useEffect, useState } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
} from 'firebase/auth';
import { getDatabase, ref, get, child } from "firebase/database";
import { auth, getAllNotifications } from "../../firebase";
import { getAllUsersForAllCompanies } from '../../firebaseHelpers/userHelpers';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [userStatusValue, setUserStatusValue] = useState(null)
    const [userName, setUserName] = useState(null)
    const [userCompany, setUserCompany] = useState('')
    const [numberOfNotifications, setNumberOfNotifications] = useState(0)
    const [initialized, setInitialized] = useState(false)

    const getUserStatus = async (email) => {
        let allUsers = await getAllUsersForAllCompanies()
        for(const company in allUsers){
            for(const userType in allUsers[company]){
                allUsers[company][userType].forEach((user) => {
                    if(user.email === email){
                        setUserStatusValue(user.status)
                        setUserName(user.username)
                        setUserCompany(user.company)
                    }
                })
            }
        }
    }

    const createUser = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logout = () => {
        return signOut(auth)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setUser(currentUser);

            if (currentUser) {
                const email = currentUser.email;
                await getUserStatus(email);
                // Set userStatusValue in local storage
                localStorage.setItem('userStatusValue', JSON.stringify(userStatusValue));
            } else {
                setUserStatusValue(null);
                // Remove userStatusValue from local storage when the user is not logged in
                localStorage.removeItem('userStatusValue');
            }

            // Set initialized to true once userStatusValue and userCompany are defined
            if (userStatusValue !== null && userCompany !== '') {
                setInitialized(true);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [userStatusValue, userCompany]); // Add userStatusValue to the dependency array to update it when it changes

    useEffect(() => {
        const storedUserStatusValue = localStorage.getItem('userStatusValue');
        if (storedUserStatusValue) {
            setUserStatusValue(JSON.parse(storedUserStatusValue));
        }
    }, []);

    async function notificationsLabel() {
        const notifications = await getAllNotifications(userStatusValue, userCompany);
        let count = notifications.length;
        setNumberOfNotifications(count);
    }

    useEffect(() => {
        if (initialized) {
            notificationsLabel();
        }
    }, [initialized]); // Run notificationsLabel when initialized is true

    return (
        <UserContext.Provider 
            value={{ 
                createUser, 
                user, 
                userName, 
                userCompany, 
                logout, 
                signIn, 
                userStatusValue, 
                numberOfNotifications, 
                setNumberOfNotifications 
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};