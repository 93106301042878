import React, { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation/Navigation';
import { getAllNotifications } from '../../firebase';
import { UserAuth } from '../../components/Context/AuthContext';
import NotificationTable from '../../components/NotificationsTable/NotificationTable';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default function NotificationPage() {
    const { userStatusValue, userCompany, setNumberOfNotifications } = UserAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState(null);


    const getNotificationData = async (userStatusValue, userCompany) => {
        setIsLoading(true);
        const response = await getAllNotifications(userStatusValue, userCompany);
        setNotifications(response);
        setNumberOfNotifications(prevNumber => prevNumber != response.length ? response.length : prevNumber)
        setIsLoading(false);
    };

    useEffect(() => {
        getNotificationData(userStatusValue, userCompany);
    }, [userStatusValue, userCompany]);

    return (
        <div>
            <Navigation />
            <div className="report__container">
                <NotificationTable data={notifications} userStatusValue={userStatusValue} isLoading={isLoading} setNotifications={setNotifications} />
            </div>
        </div>
    );
}
