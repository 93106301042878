import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navigation from "../../components/Navigation/Navigation";
import LoadingSpinner from "../../components/LoadingWheel/LoadingWheel";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Button } from "react-bootstrap";
import SchemehornRevenueSummaryComponent from "../../components/SchemehornRevenueSummaryComponent/SchemehornRevenueSummaryComponent";
import SchemehornDiscountComponent from "../../components/SchemehornDiscountComponent/SchemehornDiscountComponent";
import SchemehornTicketRangesComponent from "../../components/SchemehornTicketRangesComponent/SchemehornTicketRangesComponent";
import SchemehornPaymentTypeComponent from "../../components/SchemehornPaymentTypeComponent/SchemehornPaymentTypeComponent";
import EmailFormDisplayToggler from "../../components/EmailFormToggler/EmailFormDisplayToggler";
import { pdfExport } from "../../components/EmailSender/EmailPdf";
import SchemehornVolumeComponent from "../../components/SchemehornVolumeComponent/SchemehornVolumeComponent";
import { PDFExport } from "@progress/kendo-react-pdf";
import ToolBar from '../../components/ToolBar/ToolBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EmailComponent from '../../components/EmailComponent/EmailComponent';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";

function SchemehornFilteredPage() {
  const [inDate, setInDate] = useState(null);
  const [outDate, setOutDate] = useState(null);
  const [data, setData] = useState(null);
  const [discounts, setDiscounts] = useState(null);
  const [ticketRanges, setTicketRanges] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [volume, setVolume] = useState(null)

  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false)

  const displayHandler = () => {
    setDisplayForm(!displayForm)
  }

  const today = new Date();
  today.setDate(today.getDate());
  const formattedDate = formatDate(today);

  const container = React.useRef(null);

  const nav = useNavigate()

  const getSchemehornData = async () => {
    setLoading(true);
    const token = sessionStorage.getItem('token');

    try {
      const [
        response,
        discountResponse,
        ticketRangesResponse,
        paymentTypesResponse,
        volumeResponse,
      ] = await Promise.all([
        axios.post(process.env.REACT_APP_SCHEMEHORN_URL, {
          inDate,
          outDate,
          inTime: '12:00 AM',
          outTime: '11:59:59 PM',
        }, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
      }),
        axios.post(process.env.REACT_APP_SCHEMEHORN_DISCOUNTS_URL, {
          inDate,
          outDate,
          inTime: '12:00 AM',
          outTime: '11:59:59 PM',
        }, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
      }),
        axios.post(process.env.REACT_APP_SCHEMEHORN_TICKETS_URL, {
          inDate,
          outDate,
          inTime: '12:00 AM',
          outTime: '11:59:59 PM',
        }, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        }).catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
      }),
        axios.post(process.env.REACT_APP_SCHEMEHORN_PAYMENTS_URL, {
          inDate,
          outDate,
          inTime: '12:00 AM',
          outTime: '11:59:59 PM',
        }, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        }).catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
      }),
        axios.post(process.env.REACT_APP_SCHEMEHORN_VOLUME_URL, {
          inDate,
          outDate,
          inTime: '12:00 AM',
          outTime: '11:59:59 PM',
        }, {
          headers: {
            'authorization': 'Bearer ' + token
          }
        }).catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
      }),
      ]);

      setData(response.data);
      setDiscounts(discountResponse.data);
      setTicketRanges(ticketRangesResponse.data);
      setPaymentTypes(paymentTypesResponse.data);
      setVolume(volumeResponse.data);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };


  const getData = () => {
    if ((inDate !== null && outDate !== null) && outDate >= inDate) {
      getSchemehornData();
    } else {
      alert("please provide proper in and out dates");
    }

  };

  return (
    <div>
      <Navigation />
      {isSending ? <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box> : ""}
      <div className="report__container">
        <ToolBar
          displayHandler={displayHandler}
          displayForm={displayForm}
          container={container}
          garageName={"Schemerhorn"}
          report={"Filtered"}
          formattedDate={formattedDate}
        />
        {displayForm ?
          <EmailComponent
            setIsSending={setIsSending}
            fileName={`Schemerhorn_Filtered_Report_${formattedDate}`}
          />
          : ''
        }
        <DatePicker label={"In-Date 12:00AM"} setDate={setInDate} />
        <DatePicker label={"Out-Date 11:59PM"} setDate={setOutDate} />
        <Button className="button" onClick={getData}>
          Generate Report
        </Button>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div id="PDFExport">
              <PDFExport
                fileName={`Schemerhorn_Filtered_Report_${formattedDate}`}
                forcePageBreak=".page-break"
                scale={0.68} paperSize="Letter"
                margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                ref={container}>
                <table id="table-to-xls" className="custom-table">
                  {volume && <SchemehornVolumeComponent
                    dateRange={volume.dateRanges}
                    header={volume.header}
                    hoursRows={volume.hourRows}
                    totals={volume.totals}
                  />}
                  <SchemehornRevenueSummaryComponent
                    inDate={inDate}
                    outDate={outDate}
                    data={data}
                  />
                  <SchemehornDiscountComponent discounts={discounts} />

                  <SchemehornTicketRangesComponent ticketRanges={ticketRanges} />
                  <SchemehornPaymentTypeComponent paymentTypes={paymentTypes} />
                </table>
              </PDFExport>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SchemehornFilteredPage;
