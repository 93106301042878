import { useEffect, useState } from "react";
import axios from 'axios';
import Table from "react-bootstrap/Table";

function RateTable(props) {
  const { inDate, outDate, garageName } = props;

  const [rateBucketsFullData, setRateBucketsFullData] = useState([])

  const getRates = async () => {
    const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
    const databaseConnection = garageInfo.databaseConnection;
    const { data } = await axios.get(
      'https://automotion-heroku-server.herokuapp.com/rates/buckets'
      //'http://localhost:8080/rates/buckets'
      , {
        params: {
          garageName,
          inDate,
          outDate,
          connection: databaseConnection
        }
      });

    setRateBucketsFullData(data);
  };

  useEffect(() => {
    getRates()
  }, [])

  return (
    <>
      <Table striped bordered className="report table-sm table__data" >
        <thead>
          <tr className="table-warning">
            <th>Type of Rate</th>
            <th>Number of Cars</th>
            <th>Rate Price</th>
            <th className="table-success">Revenue</th>
          </tr>
        </thead>
        <tbody>
          {rateBucketsFullData?.map((bucket, index) => {
            const rate = typeof bucket.Rate === 'number' ? bucket.Rate : 0;

            return (
              <tr key={index}>
                <th>{bucket.Name}</th>
                <td>{bucket.TotalCount}</td>
                <td>${rate.toLocaleString(undefined, {minimumFractionDigits: 2})}</td>
                <td className="table-success">{`$${bucket.Revenue ? (bucket.Revenue).toLocaleString(undefined, {minimumFractionDigits: 2}) : 0}`}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default RateTable;
