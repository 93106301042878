import React, { useState, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ApproveRequestModal from '../ApproveRequestModal/ApproveRequestModal';
import RejectRequestModal from '../RejectRequestModal/RejectRequstModal';

export default function NotificationsTable({ userStatusValue, data, isLoading, setNotifications }) {
    const [showApproveRequestModal, setShowApproveRequestModal] = useState(false);
    const [showRejectRequestModal, setShowRejectRequestModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedRowObject, setSelectedRowObject] = useState({})
    const [requestId, setRequestId] = useState(null)
    const [company, setCompany] = useState(null)
    const [dataWithRemovedItem, setDataWithRemovedItem] = useState([])

    const showDataInTable = (rowData) => {
        const keysToDisplay = ['Request', 'Garage', 'company'];

        return (
            <table>
                <tbody>
                    {keysToDisplay.map((key) => (
                        <tr key={key}>
                            <th>{key}</th>
                            <td>{rowData[key]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const columns = useMemo(() => {
        const commonColumns = [
            {
                accessorKey: 'Request',
                header: 'Request',
                size: 100,
            },
            {
                accessorKey: 'Garage',
                header: 'Garage',
                size: 100,
            },
            {
                accessorKey: 'company',
                header: 'Company',
                size: 100,
            },
        ];

        return commonColumns;
    });

    const handleApproveClick = (rowData) => {
        setDataWithRemovedItem(data.filter(row => row.docId !== rowData.docId))

        console.log(rowData)
        setCompany(rowData.company)
        const table = showDataInTable(rowData)
        setRequestId(rowData.docId)
        setSelectedRowData(table) // Set the selected row data
        setSelectedRowObject(rowData)
        setShowApproveRequestModal(true)
    }

    const handleRejectClick = (rowData) => {
        setDataWithRemovedItem(data.filter(row => row.docId !== rowData.docId))

        console.log(rowData)
        setCompany(rowData.company)
        const table = showDataInTable(rowData)
        setRequestId(rowData.docId)
        setSelectedRowData(table) // Set the selected row data
        setSelectedRowObject(rowData)
        setShowRejectRequestModal(true)
    }

    return (
        <div>
            <ApproveRequestModal
                show={showApproveRequestModal}
                setShow={setShowApproveRequestModal}
                rowData={selectedRowData} // Pass the selected row data to the modal
                requestId={requestId}
                company={company}
                setNotifications={setNotifications}
                dataWithRemovedItem={dataWithRemovedItem}
                selectedRowObject={selectedRowObject}

            />

            <RejectRequestModal
                show={showRejectRequestModal}
                setShow={setShowRejectRequestModal}
                rowData={selectedRowData} // Pass the selected row data to the modal
                requestId={requestId}
                company={company}
                setNotifications={setNotifications}
                dataWithRemovedItem={dataWithRemovedItem}
                selectedRowObject={selectedRowObject}
            />
            <MaterialReactTable
                enableDensityToggle={false}
                enableRowActions={(userStatusValue > 1)}
                initialState={{ density: 'compact' }}
                data={data ?? []}
                state={{ isLoading: isLoading }}
                columns={columns}
                muiTablePaginationProps={{
                    rowsPerPageOptions: false,
                }}
                enableColumnActions={false}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                        key={0}
                        onClick={() => {
                            console.log(row)
                            handleApproveClick(row.original); // Pass the row data to the handler
                            closeMenu();
                        }}
                        sx={{ m: 0 }}
                    >
                        <ListItemIcon>
                            <ThumbUpIcon sx={{ color: 'green' }} />
                        </ListItemIcon>
                        Approve Request
                    </MenuItem>,
                    <MenuItem
                        key={1}
                        onClick={() => {
                            // reject logic...
                            handleRejectClick(row.original); // Pass the row data to the handler
                            closeMenu();
                        }}
                        sx={{ m: 0 }}
                    >
                        <ListItemIcon>
                            <ThumbDownIcon sx={{ color: 'red' }} />
                        </ListItemIcon>
                        Reject Request
                    </MenuItem>,
                ]}
                renderDetailPanel={({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Typography >Date Requested: {row.original.dateSubmitted}</Typography>
                        <Typography>Requested By: {row.original.requestedBy}</Typography>
                        <Typography>Rate Name: {row.original.rateName || 'N/A'}</Typography>
                        <Typography>Price: ${row.original.Price || 'N/A'}</Typography>
                        <Typography>Old Price: ${row.original.oldPrice || 'N/A'}</Typography>
                        <Typography>New Price: ${row.original.newPrice || 'N/A'}</Typography>
                        <Typography>Rate Type: {row.original.rateType || 'N/A'}</Typography>
                    </Box>
                )}
            />
        </div>
    );
}
