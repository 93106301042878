import React, { useState, useEffect, useRef } from "react";
import "./Navigation.scss";
import { useNavigate } from "react-router-dom";
import { UserAuth } from '../Context/AuthContext';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import LoadingSpinner from "../LoadingWheel/LoadingWheel";
import NotificationBadge from "../NotificationBadge/NotificationBadge";

export default function Navigation() {

  const [showLogoutDropdown, setShowLogoutDropdown] = useState(false);
  const [error, setError] = useState("");

  const {
    logout,
    userStatusValue,
    userName, 
    numberOfNotifications
  } = UserAuth();

  localStorage.setItem('userName', JSON.stringify(userName));

  const dropdownRef = useRef(null);
  const nav = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogoutDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      sessionStorage.clear();
      localStorage.clear()
      await logout();
      nav('/login');
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const openWiki = () => {
    window.open("https://wiki.automotiondashboard.com", "_blank");
  };

  const redirect = () => {
    nav('/welcome');
  };

  const adminRoute = () => {
    nav('/admin');
  };

  const openForms = () => {
    nav('/forms');
  };

  const openResources = () => {
    nav('/resources');
  };

  const notificationsClickHandler = () => {
    console.log("notifications");
    nav('/notifications');
  };

  const user = JSON.parse(localStorage.getItem('userName'))?.split(" ");
  const firstInitial = user && user[0]?.slice(0, 1);
  const lastInitial = user && user[1]?.slice(0, 1);

  return (
    userStatusValue === null ? <LoadingSpinner /> :
      <>
        <Navbar sticky="top" bg="dark" variant="dark">
          <Container>
            <Nav>
              <Nav.Link onClick={redirect}>Home</Nav.Link>
              {userStatusValue > 0 &&
                <Nav.Link
                  onClick={adminRoute}>Admin
                </Nav.Link>
              }
              <NavDropdown title="More" id="collapsible-nav-dropdown">
                <NavDropdown.Item onClick={openForms}>Forms</NavDropdown.Item>
                <NavDropdown.Item onClick={openResources}>Resources</NavDropdown.Item>
                <NavDropdown.Item onClick={openWiki}>Wiki</NavDropdown.Item>
              </NavDropdown>
              {userStatusValue > 0 && 
                <NotificationBadge
                count={numberOfNotifications} 
                notificationsClickHandler={notificationsClickHandler} 
                />}
            </Nav>
            <Dropdown
              className="userNameBadge"
              onClick={() => setShowLogoutDropdown(!showLogoutDropdown)}
              align="end"
              show={showLogoutDropdown}
              ref={dropdownRef}
            >
              <Dropdown.Toggle as="div">
                <p className="initials">{firstInitial}{lastInitial}</p>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </Navbar>
      </>
  );
}
