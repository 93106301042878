import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Button from "react-bootstrap/esm/Button";
import loginLogo from '../../assets/loginLogo.png'
import { emailAddressValidationSchema } from '../../Validations/emailAddressValidation';
import CRUDConfirmationModal from '../../components/CRUDConfirmationModal/CRUDConfirmationModal';
import { isATechEmail } from '../../firebaseHelpers/userHelpers';

import '../../components/Login/Login.scss'

function ForgotPasswordPage() {

    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})
    const [show, setShow] = useState(false)
    const [isTech, setIsTech] = useState(false)

    let nav = useNavigate()
    const handleClose = () => {
        setShow(false)
        nav('/login')
    }
    const sendPasswordReset = async (e) => {
        e.preventDefault()
        //catch errors in email 
        try {
            await emailAddressValidationSchema
                .validate({ email }, { abortEarly: false });
            //if validation passed, submit email form for password reset
            const auth = getAuth();
            if (await isATechEmail(email) === true) {
                console.log(isATechEmail(email))
                setIsTech(true)
                setShow(true)
            } else {
                sendPasswordResetEmail(auth, email)
                    .then(() => {
                        setErrors({})
                        setIsTech(false)
                        setShow(true)
                    })
                    .catch((error) => {
                        console.log(error)
                        setErrors({})
                        //alert(error.message)
                    });
            }
        } catch (error) {
            // If validation fails, display error messages
            const errors = {};
            error.inner.forEach((e) => {
                errors[e.path] = e.message;
            });
            console.log(error)
            setErrors(errors);
        }

    }

    return (
        <>
        <CRUDConfirmationModal
        show={show}
        handleClose={handleClose}
        body={isTech ? "Please contact your admin to reset passsword.": "Reset Email Sent!"}
        />
        <div className="login">
            <img className="logo" src={loginLogo} />
            <Form className='my-2 mx-auto' style={{ width: 300 }} noValidate>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)} isInvalid={!!errors.email} />
                    <Form.Control.Feedback type='invalid'>
                        {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
            <Button onClick={sendPasswordReset} style={{ width: 300 }} variant="secondary" type="forgotPassword">
                Send Reset Email
            </Button>
        </div>
        </>
    );
}

export default ForgotPasswordPage;