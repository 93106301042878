import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import Navigation from '../../components/Navigation/Navigation';
import MonthliesRevenueTable from '../../components/MonthliesRevenueTable/MonthliesRevenueTable';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import MonthliesRevenueConflictsQBTable from '../../components/MonthliesRevenueConflictsQBTable/MonthliesRevenueConflictsQBTable';
import MonthliesRevenueConflictsGSTable from '../../components/MonthliesRevenueConflictsGSTable/MonthliesRevenueConflictsGSTable';
import { PDFExport } from "@progress/kendo-react-pdf";
import ToolBar from '../../components/ToolBar/ToolBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EmailComponent from '../../components/EmailComponent/EmailComponent';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";

export default function MonthliesRevenuePage() {

  const [payments, setPayments] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [type, setType] = useState(null)
  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false)

  const displayHandler = () => {
    setDisplayForm(!displayForm)
  }

  const container = React.useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const today = new Date();
  today.setDate(today.getDate());
  const formattedDate = formatDate(today);

  const getMonthliesRevenue = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios
        .get(process.env.REACT_APP_GOOGLE_SHEETS_URL,
          {
            params: {
              garageName: "Schermerhorn"
            },
            headers: {
              authorization: 'Bearer ' + token
            }
          })
      sessionStorage.setItem(`Schermerhorn-monthliesRevenue`, JSON.stringify(response.data))
      setPayments(response.data)
      setIsLoading(false)

    } catch (err) {
      console.log(err)
    }
  }

  const typeParam = searchParams.get('type');
  useEffect(() => {

    setType(typeParam)
    const data = JSON.parse(sessionStorage.getItem(`Schermerhorn-monthliesRevenue`))

    if (data) {
      setPayments(data);
      setIsLoading(false)
    } else {
      getMonthliesRevenue();
    }
  }, [typeParam]);

  const typeHandler = (e) => {
    setType(e.target.value)
    setSearchParams({ type: e.target.value })

  }

  let fobIndex = 0;
  let statusIndex = 1;
  let nameIndex = 2;
  let typeIndex = 10;
  let rateIndex = 15;
  let QBNameIndex = 26;
  let QBRateIndex = 27;
  let QBFobIndex = 28


  return (
    <div>
      <Navigation />
      {isSending ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        ''
      )}
      <div className='report__container'>
        <ToolBar
          displayHandler={displayHandler}
          displayForm={displayForm}
          container={container}
          garageName={'Schemerhorn'}
          report={'Monthlies_Revenue'}
          formattedDate={formattedDate}
        />
        {displayForm ? <EmailComponent setIsSending={setIsSending} fileName={`Shemerhorn_Monthlies_Revenue_Report_${formattedDate}`} /> : ''}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className='monthlies-header'>
            <select name='reports' onChange={(e) => typeHandler(e)}>
              <option value='Select'>Select Report</option>
              <option value='Active'>Active</option>
              <option value='Conflicts (Not in QuickBooks)'>Conflicts - not on QB</option>
              <option value='Conflicts (Not in GoogleSheets)'>Conflicts - not on GS</option>
            </select>
          </div>
        )}
        <div id='PDFExport'>
          <PDFExport
            fileName={`Shemerhorn_Monthlies_Revenue_Report_${formattedDate}`}
            forcePageBreak='.page-break'
            scale={0.68}
            paperSize='Letter'
            margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
            ref={container}
          >
            <div className='monthlies_revenue_header'>
              <p className='monthlies_revenue_header__text'>Schermerhorn</p>
              <p className='monthlies_revenue_header__text'>{type}</p>
            </div>
            <table id='table-to-xls' className='custom-table'>
              {type === 'Active' ? (
                <div className='monthlies'>
                  <MonthliesRevenueTable payments={payments} fobStatusIndex={statusIndex} fob={fobIndex} name={nameIndex} type={typeIndex} rate={rateIndex} />
                </div>
              ) : type === 'Conflicts (Not in QuickBooks)' ? (
                <div className='monthlies'>
                  <MonthliesRevenueConflictsQBTable
                    payments={payments}
                    fobStatusIndex={statusIndex}
                    fob={fobIndex}
                    name={nameIndex}
                    type={typeIndex}
                    rate={rateIndex}
                    QBFobIndex={QBFobIndex}
                    QBNameIndex={QBNameIndex}
                  />
                </div>
              ) : type === 'Conflicts (Not in GoogleSheets)' ? (
                <div className='monthlies'>
                  <MonthliesRevenueConflictsGSTable
                    payments={payments}
                    fobStatusIndex={statusIndex}
                    fob={fobIndex}
                    name={nameIndex}
                    type={typeIndex}
                    rate={rateIndex}
                    QBFobIndex={QBFobIndex}
                    QBRateIndex={QBRateIndex}
                    QBNameIndex={QBNameIndex}
                  />
                </div>
              ) : (
                ''
              )}
            </table>
          </PDFExport>
        </div>
      </div>
    </div>
  );
}