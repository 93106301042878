import React, { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';

export default function TransientFrequencyTable(props) {

const {data} = props  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'contactInfoCount', //access nested data with dot notation
        header: 'Count',
        size: 150,
      },
      {
        accessorKey: 'ContactInfo',
        header: 'Contact Info',
        size: 150,
      },
    ],
    [],
  );
  return (
    <div>
        <MaterialReactTable
        enableDensityToggle={false}
        initialState={{ density: 'compact' }}
        muiTablePaginationProps={{
            rowsPerPageOptions: false,
          }}
        data={data}
        columns={columns}
        />
    </div>
  )
}
