import Table from 'react-bootstrap/Table';
import './TransactionTable.scss';

function TransactionTable({ hourlyTransactionTable, total }) {
    return (
        <>
            <Table striped bordered className='table-sm table__data'>
                <thead>
                    <tr className='table-warning'>
                        <th className='hours'>Hour</th>
                        <th>T. In</th>
                        <th>T. Out</th>
                        <th>M. In</th>
                        <th>M. Out</th>
                        <th>Tot.</th>
                    </tr>
                </thead>
                <tbody>
                    {hourlyTransactionTable.map((row, index) => {
                        return (
                            <tr key={index} className={index === hourlyTransactionTable.length - 1 ? 'table-success' : ""}>
                                <th>{index === hourlyTransactionTable.length - 1 ? 'Totals' : row['TimeOfDay']}</th>
                                <td>{row['TInCount']}</td>
                                <td>{row['TOutCount']}</td>
                                <td>{row['MInCount']}</td>
                                <td>{row['MOutCount']}</td>
                                <td>{row['TotalCount']}</td>
                            </tr>
                        );
                    })}
                    <tr className='table-success'>
                        <th>${total && total.toLocaleString(undefined, { minimumFractionDigits: 2 })}</th>
                    </tr>
                </tbody>
            </Table>
        </>
    );
}

export default TransactionTable;
