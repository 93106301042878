import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { addAcceptRequestToLogs, addPendingEditedRate, deleteRate } from '../../firebase';
import './EditPriceModal.scss';
import axios from 'axios';
import editIcon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/deleteIcon.png';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import { UserAuth } from '../Context/AuthContext';
import { saveAction } from '../../firebase';

export default function EditPriceModal(props) {
    const { garageName, showEditPriceModal, setShowEditPriceModal, setSnackbarInfo, setOpenSimpleSnackbar, companyForUpdate } = props;

    const { user, setNumberOfNotifications, userName, userStatusValue, userCompany } = UserAuth();

    const [transientRates, setTransientRates] = useState([]);
    const [monthlyRates, setMonthlyRates] = useState(null);
    const [rateType, setRateType] = useState("Monthlies");
    const [isLoading, setIsLoading] = useState(true);
    const [isSending, setIsSending] = useState(false);

    const [editedRate, setEditedRate] = useState({
        rateName: '',
        rateCurrentValue: '',
        rateNewValue: ''
    });

    const editRateClickHandler = (rateName, rateValue) => {
        console.log(rateName)
        setEditedRate({
            rateName,
            rateCurrentValue: rateValue,
            rateNewValue: rateValue,
        });
    }

    const handleCloseEditPriceModal = () => {
        setEditedRate({
            rateName: '',
            rateCurrentValue: '',
            rateNewValue: ''
        });
        setOpenSimpleSnackbar(false);
        setShowEditPriceModal(false);
    }

    const getRates = async (garageName) => {
        setIsLoading(true)
        const token = sessionStorage.getItem('token');
        try {
            const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
            const databaseConnection = garageInfo.databaseConnection;

            const monthlyRates = await axios.get('https://automotion-heroku-server.herokuapp.com/rates', {
                params: {
                    type: 'Monthly',
                    garageName,
                    connection: databaseConnection,
                },
                headers: {
                    authorization: 'Bearer ' + token,
                },
            });

            console.log(monthlyRates);
            setMonthlyRates(monthlyRates.data);

            const transientRates = await axios.get('https://automotion-heroku-server.herokuapp.com/rates', {
                params: {
                    type: 'UpTo',
                    garageName,
                    connection: databaseConnection
                },
                headers: {
                    authorization: 'Bearer ' + token,
                },
            });

            console.log(transientRates);
            setTransientRates(transientRates.data);
            setIsLoading(false)
        } catch (e) {
            alert(`Error: ${e.message}`);
        }
    }

    const handleInputChange = (e) => {
        setRateType(e.target.value);
    }

    const updateRateValue = (event, rateName) => {
        setEditedRate({
            rateName: rateName,
            rateCurrentValue: editedRate.rateCurrentValue,
            rateNewValue: event.target.value,
        });
    }
    const saveEditedRate = async () => {
        setIsSending(true);
        const { rateName, rateCurrentValue, rateNewValue } = editedRate
        try {
            if (userStatusValue > 1) {
                // Add edited rate to logs
                console.log(editedRate)
                const requestObject = {
                    'Company': companyForUpdate,
                    'Garage': garageName,
                    'Rate Type': rateType,
                    'Rate Name': rateName,
                    'Current Rate Value': rateCurrentValue,
                    'Rate New Value': rateNewValue,
                    'Note': 'Edited Rate submitted by super-admin'
                };
                await addAcceptRequestToLogs(requestObject, userName, userCompany); // Add await here
            } else {
                let success = await addPendingEditedRate(
                    companyForUpdate,
                    garageName,
                    rateType,
                    editedRate, // Access the correct property of editedRate
                    user.email,
                    userName,
                    userCompany
                );
                if (success === true) {
                    setSnackbarInfo({
                        text: 'Updated Rate Submitted for Review!',
                        severity: 'success',
                    });
                } else {
                    setSnackbarInfo({
                        text: 'Error: Could Not Submit Updated Rate for Review',
                        severity: 'error',
                    });
                }
            }
            setOpenSimpleSnackbar(true);
            setIsSending(false);
            setShowEditPriceModal(false);
            setEditedRate({
                rateName: '',
                rateCurrentValue: '',
                rateNewValue: ''
            });
            if (userStatusValue <= 1) {
                setNumberOfNotifications(prevNumber => prevNumber + 1);
            }
        } catch (e) {
            console.error("Error:", e);
            setSnackbarInfo({
                text: 'An error occurred. Please try again.',
                severity: 'error',
            });
            setOpenSimpleSnackbar(true);
        }
    };


    const deleteRateHandler = async (rateName, rate) => {
        setIsSending(true);
        try {
            if (userStatusValue > 1) {
                // Add deleted rate to logs
                const requestObject = {
                    'Company': companyForUpdate,
                    'Garage': garageName,
                    'Rate Type': rateType,
                    'Rate Name': rateName,
                    'Rate Delete': 'Rate Deleted',
                    'Note': 'Deleted Rate submitted by super-admin'
                };
                await addAcceptRequestToLogs(requestObject, userName, userCompany);
            } else {
                const actionDetails = {
                    action: "Delete Rate",
                    details: {
                        Rate: rate,
                        Company: companyForUpdate
                    },
                    createdBy: {
                        userName,
                        userCompany
                    },
                    dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
                };

                await saveAction(actionDetails);

                let success = await deleteRate(companyForUpdate, garageName, rateName, rate, user.email);
                if (success === true) {
                    setNumberOfNotifications(prevNumber => prevNumber + 1);
                    setSnackbarInfo({
                        text: 'Deleted Rate Submitted for Review!',
                        severity: 'success',
                    });
                } else {
                    setSnackbarInfo({
                        text: 'Error: Could Not Submit Deleted Rate for Review',
                        severity: 'error',
                    });
                }
                setOpenSimpleSnackbar(true);
            }
        } catch (e) {
            console.error("Error:", e);
            setSnackbarInfo({
                text: 'An error occurred. Please try again.',
                severity: 'error',
            });
            setOpenSimpleSnackbar(true);
        } finally {
            setIsSending(false);
            setShowEditPriceModal(false);
        }
    };


    useEffect(() => {
        getRates(garageName);
    }, [garageName]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await saveEditedRate();
    };

    return (
        <>
            <Modal centered show={showEditPriceModal} onHide={handleCloseEditPriceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{garageName} Rates </Modal.Title>
                </Modal.Header>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Modal.Body style={{ paddingTop: '0.1rem' }}>
                        <Form.Select
                            style={{ width: '9rem', marginLeft: '0.55rem', marginBottom: '0.55rem' }}
                            value={rateType}
                            onChange={(e) => handleInputChange(e)}
                        >
                            <option value="Monthlies">Monthlies</option>
                            <option value="Transients">Transients</option>
                        </Form.Select>
                        <div className="">
                            <Form onSubmit={handleFormSubmit}>
                                <div>
                                    {
                                        isSending ?
                                            <>
                                                Sending Request...
                                                <LoadingSpinner />
                                            </>
                                            :

                                            rateType === 'Transients'
                                                ? transientRates?.map((rate) => (
                                                    <div className="rate-entry" key={rate.Name}>
                                                        <div className="rate-text">
                                                            {rate.Name} ={' '}
                                                            {rate.Name === editedRate.rateName ? (
                                                                <>
                                                                    <input
                                                                        type='number'
                                                                        className="edited__rate"
                                                                        value={editedRate.rateNewValue}
                                                                        onChange={
                                                                            (e) => updateRateValue(e, rate.Name)
                                                                        }
                                                                    />
                                                                </>
                                                            ) : (
                                                                <p className="rate__amount">${rate.Rate}</p>
                                                            )}
                                                        </div>
                                                        <div className="rate__action__icons">
                                                            {rate.Name !== editedRate.rateName && (
                                                                <img
                                                                    style={{ cursor: 'pointer' }}
                                                                    src={editIcon}
                                                                    onClick={() => editRateClickHandler(rate.Name, rate.Rate)}
                                                                    alt="Edit"
                                                                />
                                                            )}
                                                            <img
                                                                style={{ cursor: 'pointer' }}
                                                                src={deleteIcon}
                                                                alt="Delete"
                                                                onClick={() => deleteRateHandler(rate.Name, rate.Rate)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                                : monthlyRates?.map((rate) => (
                                                    <div className="rate-entry" key={rate.Name}>
                                                        <div className="rate-text">
                                                            {rate.Name} ={' '}
                                                            {rate.Name === editedRate.rateName ? (
                                                                <>
                                                                    <input
                                                                        type='number'
                                                                        className="edited__rate"
                                                                        value={editedRate.rateNewValue}
                                                                        onChange={
                                                                            (e) => updateRateValue(e, rate.Name)
                                                                        }
                                                                    />
                                                                </>
                                                            ) : (
                                                                <p className="rate__amount">${rate.Rate}</p>
                                                            )}
                                                        </div>
                                                        <div className="rate__action__icons">
                                                            {rate.Name !== editedRate.rateName && (
                                                                <img
                                                                    style={{ cursor: 'pointer' }}
                                                                    src={editIcon}
                                                                    onClick={() => editRateClickHandler(rate.Name, rate.Rate)}
                                                                    alt="Edit"
                                                                />
                                                            )}
                                                            <img
                                                                style={{ cursor: 'pointer' }}
                                                                src={deleteIcon}
                                                                alt="Delete"
                                                                onClick={() => deleteRateHandler(rate.Name, rate.Rate)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                </div>
                                <Modal.Footer className='modal__footer'>
                                    <Button variant="danger" onClick={handleCloseEditPriceModal}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="primary" disabled={!editedRate.rateNewValue || editedRate.rateCurrentValue === editedRate.rateNewValue}>
                                        Apply
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal.Body>
                )}
            </Modal>
        </>
    );
}
