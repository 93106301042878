import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation'
import axios from 'axios';
import FolderCard from '../../components/FolderCard/FolderCard';
import './FormFolderPage.scss'
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
export default function FormFolderPage() {

    const [folders, setFolders] = useState(null)

    const nav = useNavigate()

    const getData = async () => {
        //get the JWT token from session storage
        const token = sessionStorage.getItem('token');
        try {
            let response = await axios.get(process.env.REACT_APP_JOT_FORMS_URL, {
                headers: {
                    authorization: 'Bearer ' + token
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    // Redirect to the login page if it's an authentication error
                    nav('/login');
                }
            })
            setFolders(response.data)
            sessionStorage.setItem("form-folders", JSON.stringify(response.data))
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <div>
            <Navigation />
            {folders ?
                <div>
                    <h4 className='folder_page_header'>Form Folders</h4>
                    <div className='folder_page cards d-flex justify-content-center'>
                        {
                            folders.map((folder, index) => {
                                return (
                                    <FolderCard
                                        key={index}
                                        name={folder.name}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                : <LoadingSpinner />
            }
        </div>
    )
}
