import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Navigation from '../Navigation/Navigation'
import JotformEmbed from 'react-jotform-embed';
import './FormComponent.scss'
export default function FormComponent() {
    const { formTitle } = useParams()

    let folders = JSON.parse(sessionStorage.getItem('form-folders'))
    let formURL;

    folders.forEach((folder) => {
        if (folder.forms.length > 0) {
            folder.forms.forEach(form => {
                if (form.title === formTitle) {
                    formURL = form.url
                }
            })
        }
    })

    const nav = useNavigate()
    const clickHandler = () => {
        // goes back to forms/:folderName
        nav(`/forms`)
    }
    return (
        <div className='report'>
            <Navigation />
            <div>
                <JotformEmbed
                    src={formURL}
                    scrolling={true}
                />
            </div>

        </div>
    )
}
