import React from 'react'
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
import deleteIcon from '../../assets/deleteIcon.png';
import './FolderCard.scss';


export default function (props) {
    const {name} = props
    console.log("name", name)
const nav = useNavigate();

const clickHandler = () => {
    // navigates to resources/:folderName or forms/:folderName
    nav(`${name}`)
}  
  
return (

    <div className='card_with_delete'>
    {/* <img className="card_with_delete_icon" src={deleteIcon}></img> */}
    <button className='report-card__button' onClick={clickHandler}>
        <Card className="m-2" style={{ width: '15rem'}}>
            <Card.Body>
                <Card.Title>{name}</Card.Title>
            </Card.Body>
        </Card>
    </button>
    </div>
  )
}
