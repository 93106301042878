import React from 'react'
import { MaterialReactTable } from 'material-react-table';
import { Box, Typography } from '@mui/material';

export default function MonthliesUsersTable({columns, data}) {
    return (
        <div>
            <MaterialReactTable columns={columns} data={data}
            enableDensityToggle={false}
            enablePagination={false}
            initialState={{ density: 'compact' }}
                renderDetailPanel={({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                        <Typography >Rate: {row.original.rate}</Typography>
                        <Typography>Year, Make, Model: {row.original.Year} {row.original.Make} {row.original.Model}</Typography>
                        <Typography>Color: {row.original.Color}</Typography>
                        <Typography>Street Address: {row.original.StreetAddress}</Typography>
                        <Typography>City, State, Zip: {row.original.City}, {row.original.State} {row.original.Zip}</Typography>
                        <Typography>Main Phone: {row.original.MainPhone}</Typography>
                        <Typography>Alt Phone: {row.original.AltPhone}</Typography>
                        <Typography>Num Allowed To Park: {row.original.NumAllowedToPark}</Typography>
                        <Typography>Num Currently Parked: {row.original.NumCurrentlyParked}</Typography>
                    </Box>
                )} />
        </div>
    )
}
