import React from "react";
import Table from "react-bootstrap/Table";
import '../../pages/AutomatedDailyReportPage/AutomatedDailyReport.scss';

export default function MonthlyOnlyDailyHeader(props) {

    const {
        currentMonthliesIn,
        spaces
    } = props;

    return (
        <Table striped bordered className="table-sm header-mobile">
            <thead>
                <tr className="table-warning">
                    <th>Total Spaces</th>
                    <th>Current M In</th>
                    <th>Free Spaces</th>
                    <th>Reserved for Monthlies</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{spaces}</td>
                    <td>{currentMonthliesIn}</td>
                    <td>{spaces - (currentMonthliesIn)}</td>
                    <td>0</td>
                </tr>
            </tbody>
        </Table>
    );
}
