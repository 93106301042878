import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { addGarageToCompany } from '../../firebase';
import GarageImageUpload from '../GarageImageUpload/GarageImageUpload';
import { newGarageValidationSchema } from '../../Validations/newGarageValidations';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import defaultGarageImage from '../../assets/Default_Garage_Image.png'

function AddNewGarageModal({
  show,
  handleClose,
  setOpenSimpleSnackbar,
  setSnackbarInfo,
  companiesAndGarages,
  setCompaniesAndGarages,
  userName, 
  userCompany,
}) {
  const [garageForm, setGarageForm] = useState({
    garageName: '',
    selectedCompany: '',
    databaseConnection: '',
    spaces: '',
    garageType: '',
    selectedImage: '',
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  const handleSaveGarage = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      newGarageValidationSchema.validateSync(garageForm, { abortEarly: false });
      // Validation successful, proceed with adding the garage.
      const garageExists = await addGarageToCompany(garageForm, userName, userCompany);
      setIsLoading(false)

      for (const company of Object.keys(companiesAndGarages)) {
        if (garageForm.selectedCompany === company) {
          setCompaniesAndGarages(prevCompaniesAndGarages => {
            const updatedGarages = {
              ...prevCompaniesAndGarages,
              [company]: [...prevCompaniesAndGarages[company], garageForm.garageName]
            };
            return updatedGarages;
          });
        }
      }
      if (garageExists) {
        // Display the "Garage Already Exists" snackbar.
        setSnackbarInfo({ text: 'Garage Already Exists', severity: 'warning' });
        handleCloseModal();
        setOpenSimpleSnackbar(true);
      } else {
        // Display the "Garage Added!" snackbar.
        setSnackbarInfo({ text: 'New Garage Added!', severity: 'success' });
        handleCloseModal();
        setOpenSimpleSnackbar(true);
        console.log(companiesAndGarages)
         // Clear all items from local storage
        localStorage.clear();
      }
    } catch (error) {
      if (Array.isArray(error.inner)) {
        // 'error.inner' is an array, so it's safe to use forEach
        const validationErrors = {};
        error.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
          console.log(validationErrors[e.path]);
        });
        setValidationErrors(validationErrors);
      } else {
        // Handle other types of errors
        alert("Error: " + error)
      }
    setIsLoading(false)
  };
}

  const handleFieldChange = (field, value) => {
    // Clear the error message for the field when it is changed.
    setValidationErrors((prevErrors) => {
      return { ...prevErrors, [field]: undefined };
    });

    // Update the garageForm with the changed value.
    setGarageForm((prevForm) => {
      return { ...prevForm, [field]: value };
    });
  };

  const handleCloseModal = () => {
    setGarageForm({
      garageName: '',
      selectedCompany: '',
      databaseConnection: '',
      spaces: '',
      garageType: '',
      selectedImage: '',
    });
    setValidationErrors({});
    handleClose();
  };

  return (
    <div>
      <Modal centered='true' show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Garage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Garage Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                autoFocus
                value={garageForm.garageName}
                onChange={(e) => handleFieldChange('garageName', e.target.value)}
                isInvalid={!!validationErrors.garageName}
              />
              {validationErrors.garageName && (
                <Form.Control.Feedback type='invalid'>
                  {validationErrors.garageName}
                </Form.Control.Feedback>)}
            </Form.Group>
            {/* Add other form fields and validation errors here */}
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                as="select"
                value={garageForm.selectedCompany}
                onChange={(e) => handleFieldChange('selectedCompany', e.target.value)}
                isInvalid={!!validationErrors.selectedCompany}
              >
                <option value="">Please select</option>
                {Object.keys(companiesAndGarages).map((company, index) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
              </Form.Control>
              {validationErrors.selectedCompany && (
                <Form.Control.Feedback type='invalid'>
                  {validationErrors.selectedCompany}
                </Form.Control.Feedback>)}
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Garage Type</Form.Label>
              <Form.Control
                as="select"
                value={garageForm.garageType}
                onChange={(e) => handleFieldChange('garageType', e.target.value)}
                isInvalid={!!validationErrors.garageType}

              >
                <option value="">Please select</option>
                <option value="Monthlies and Transients">
                  Monthlies and Transients
                </option>
                <option value="Only Monthlies">Only Monthlies</option>
              </Form.Control>
              {validationErrors.garageType && (
                <Form.Control.Feedback type='invalid'>
                  {validationErrors.garageType}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Number of Spaces</Form.Label>
              <Form.Control
                type="number"
                placeholder="Number of Spaces"
                autoFocus
                value={garageForm.spaces}
                onChange={(e) => handleFieldChange('spaces', e.target.value)}
                isInvalid={!!validationErrors.spaces}

              />
              {validationErrors.spaces && (
                <Form.Control.Feedback type='invalid'>
                  {validationErrors.spaces}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Database Connection</Form.Label>
              <Form.Control
                type="text"
                placeholder="Database Connection"
                autoFocus
                value={garageForm.databaseConnection}
                onChange={(e) => handleFieldChange('databaseConnection', e.target.value)}
                isInvalid={!!validationErrors.databaseConnection}
              />
              {validationErrors.databaseConnection && (
                <Form.Control.Feedback type='invalid'>
                  {validationErrors.databaseConnection}
                </Form.Control.Feedback>)}
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Upload Garage Logo</Form.Label>
              <GarageImageUpload
                // selectedImage={garageForm.selectedImage}
                garageForm={garageForm}
                setGarageForm={setGarageForm}
                setValidationErrors={setValidationErrors}
              />
              {/* {validationErrors.selectedImage && (
                <div className="text-danger">{validationErrors.selectedImage}</div>
              )} */}
            </Form.Group>
          </Form>
        </Modal.Body>
        {
          isLoading && <LoadingSpinner />
        }
        <Modal.Footer className='pb-5'>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveGarage}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddNewGarageModal;
