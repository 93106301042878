import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
import { Form, Button, ModalFooter } from 'react-bootstrap';
import { updateUserValidationSchema } from '../../Validations/updateUserValidations';
import { UpdateUserData } from '../../firebaseHelpers/userHelpers';
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import { saveAction } from '../../firebase';

export default function EditUserModal({ show, setShow, userToEdit, userName, userCompany }) {

    // console.log(userToEdit)
    const [formData, setFormData] = useState({
        email: '',
        newPassword: '',
        confirmPassword: '',
        userType: '',
    });
    // console.log(formData)
    const [errors, setErrors] = useState({
        email: null,
        newPassword: null,
        confirmPassword: null,
        userType: null,
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setFormData({
            email: '',
            newPassword: '',
            confirmPassword: '',
            userType: '',
        });
        setErrors({
            email: null,
            newPassword: null,
            confirmPassword: null,
            userType: null,
        });
        setShow(false);
    };

    const applyHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            //get the JWT token from session storage
            const token = sessionStorage.getItem('token');
            if (formData.newPassword !== "" || userToEdit.email !== formData.email) {
                await axios
                    .post(
                        //"http://localhost:8080/admin/update"
                        process.env.REACT_APP_ADMIN_UPDATE_URL
                        , {
                            email: userToEdit.email,
                            newEmail: formData.email,
                            newPassword: formData.newPassword
                        }, {
                        headers: {
                            authorization: 'Bearer ' + token
                        }
                    })
            }
        } catch (e) {
            console.log(e)
        }

        try {
            await updateUserValidationSchema.validate(formData, { abortEarly: false });

            setIsLoading(true);

            const actionDetails = {
                action: "User Edited",
                details: {
                    'Edited User':userToEdit.username,
                },
                createdBy: {
                    userName,
                    userCompany
                },
                dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
            }

            try {
                await saveAction(actionDetails)
            } catch (e) {
                console.log(e)
            }
            // Update user data
            console.log(userToEdit)
            await UpdateUserData({
                ...userToEdit,
                newUserType: formData.userType,
                oldUserType: userToEdit.type,
                newEmail: formData.email.toLowerCase(),
                oldEmail: userToEdit.email

            });

            setIsLoading(false);
            handleClose();
            // window.location.reload()
        } catch (error) {
            setIsLoading(false)
            const newErrors = {};
            console.log(error)
            error.inner.forEach((e) => {
                newErrors[e.path] = e.message;
            });
            setErrors(newErrors);

        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (userToEdit) {
            setFormData({
                email: userToEdit.email || '',
                newPassword: '',
                confirmPassword: '',
                userType: userToEdit.type || '',
            });
        }
    }, [userToEdit]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update User: {userToEdit?.username}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={applyHandler} className='edit-user-form'>
                    <Form.Group controlId="email">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter new email"
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                        <Form.Label className='m-0'>New Password:</Form.Label>
                        <Form.Control
                            className='mb-3'
                            type="password"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            placeholder="Enter new password"
                            isInvalid={!!errors.newPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                        <Form.Label className='m-0'>Confirm Password:</Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm new password"
                            isInvalid={!!errors.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                    </Form.Group>
                    {/* radio button selections */}
                    <Form.Group controlId="userType" className='update-type'>
                        <Form.Label>User Type:</Form.Label>
                        <div>
                            <Form.Check
                                id='adminType'
                                inline type="radio"
                                label="Admin"
                                value="Admin"
                                name="userType"
                                checked={formData.userType === "Admin"}
                                onChange={e => handleChange(e)}
                                isInvalid={!!errors.userType}
                            />
                            <Form.Check
                                id='techType'
                                inline type="radio"
                                label="Tech"
                                value="Tech"
                                name="userType"
                                checked={formData.userType === "Tech"}
                                onChange={e => handleChange(e)}
                                isInvalid={!!errors.userType}
                            />
                            <Form.Check
                                id='superAdminType'
                                inline type="radio"
                                label="Super Admin"
                                value="Super Admin"
                                name="userType"
                                checked={formData.userType === "Super Admin"}
                                onChange={e => handleChange(e)}
                                isInvalid={!!errors.userType}
                            />
                        </div>

                        {/* validates that a radio button was selected */}
                        <Form.Control.Feedback type='invalid'>
                            {errors.userType}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>Cancel</Button>
                        {isLoading ? <LoadingSpinner /> :
                            <Button variant="primary" type="submit" disabled={isLoading}>
                                Update
                            </Button>}
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
