import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from 'axios';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import Navigation from '../../components/Navigation/Navigation';
import MonthliesUsersTable from '../../components/MonthliesUsersTable/MonthliesUsersTable';
import EmailComponent from '../../components/EmailComponent/EmailComponent';
import ToolBar from '../../components/ToolBar/ToolBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import './MonthliesPage.scss';
import { PDFExport } from '@progress/kendo-react-pdf';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";
import MonthliesUsersTablePDF from '../../components/MonthliesUsersTablePDF/MonthliesUsersTablePDF';

export default function Monthlies() {

  const [selectedData, setSelectedData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false)

  const displayHandler = () => {
    setDisplayForm(!displayForm)
  }

  const container = React.useRef(null);

  const nav = useNavigate()

  const { garageName } = useParams()

  async function fetchMonthliesData() {
    const token = sessionStorage.getItem('token');
    const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
    const databaseConnection = garageInfo.databaseConnection;

    setIsLoading(true)
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_MONTHLIES,
        //"http://localhost:8080/monthlies",
        {
          params: {
            garageName, 
            connection: databaseConnection
          },
          headers: {
            authorization: 'Bearer ' + token
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
              // Redirect to the login page if it's an authentication error
              nav('/login');
          }
        })

      console.log(data)
      localStorage.setItem(`${garageName}-monthliesData`, JSON.stringify(data))
      
      setSelectedData([...data.monthliesActive, ...data.monthliesExpired])
      setIsLoading(false);
    } catch (err) {
      console.log(err)
    }
  }

  const typeHandler = (e) => {
    setType(e.target.value)
    setSearchParams({ type: e.target.value })
  }

  const typeTable = {
    "Active": 'monthliesActive', 
    "Inactive": 'monthliesExpired', 
    "InUse": 'monthliesInUse', 
    "Repo": "monthliesRepo"
  }
  
  const typeParam = searchParams.get('type');
  useEffect(() => {
    setType(typeParam)
    const data = JSON.parse(localStorage.getItem(`${garageName}-monthliesData`))
    if (data) {
      if(type == 'All' || type == null){
        setSelectedData([...data.monthliesActive, ...data.monthliesExpired])
      }else{
        setSelectedData(data[typeTable[type]])
      }
    } else {
      fetchMonthliesData();
    }
  }, [typeParam]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'CustomerNumber', //access nested data with dot notation
        header: '#',
        size: 10,
      },
      {
        accessorKey: 'CustomerName',
        header: 'Name',
        size: 10,
      },
      {
        accessorKey: 'CarBarCode', //normal accessorKey
        header: 'FOB',
        size: 10,
      },
      {
        accessorKey: 'LicensePlate',
        header: 'Plate #',
        size: 10,
      },
      {
        accessorKey: 'ContractEndDate',
        header: 'End',
        size: 10,
      },
      {
        accessorKey: 'InUse',
        header: 'In Use',
        size: 10,
      },
    ],
    [],
  );

  const today = new Date();
  today.setDate(today.getDate());
  const formattedDate = formatDate(today);
  return (
    <div>
      <Navigation />
        {isSending ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          ""
        )}
        <div className='monthliesSelect report__container'>
        <ToolBar
          displayHandler={displayHandler}
          displayForm={displayForm}
          container={container}
          csvData={selectedData}
          garageName={garageName}
          formattedDate={formattedDate}
          report={`monthlies-${type}`}
        />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className='report'>
            <div className='monthlies-header'>
              {displayForm ? (
                <EmailComponent
                  setIsSending={setIsSending}
                  fileName={`${garageName}_Monthlies_${formattedDate}`}
                />
              ) : (
                ''
              )}
              <p>Select a report from the dropdown menu</p>
              <select name="reports" onChange={(e) => typeHandler(e)}>
                <option value="All">All Monthlies</option>
                <option value="Active">Active Monthlies</option>
                <option value="Inactive">Inactive Monthlies</option>
                <option value="Repo">Repo Monthlies</option>
                <option value="InUse">In Use Monthlies</option>
              </select>
            </div>
            <div className='report monthlies-names'>
              <PDFExport
                landscape={true} // Set landscape mode
                fileName={`${garageName}_Monthlies_${type}-${formattedDate}`}
                forcePageBreak=".page-break" scale={0.68}
                paperSize="Letter"
                margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                ref={container}
              >
                <div id="PDFExport">
                  <h4>
                    {garageName} {type ? type : 'All'} Monthlies: {selectedData?.length}
                  </h4>
                    {selectedData && <MonthliesUsersTable columns={columns} data={selectedData} />}
                </div>
              </PDFExport>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
