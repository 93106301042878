import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";

function AdminCard(props) {

    //take the name of the option, passed as a prop
    const { title } = props;

    //uses the title as the URL param to redirect to the selected page, 
    //only using first word
    const urlParam = title.split(" ")[0]

    //react hook used for navigation
    const nav = useNavigate();

    //handle the Card onClick event
    const clickHandler = async () => {

        //appends the urlParam to current url and redirects to selected page
        nav(`${urlParam}`);
    }

    return (

        //renders a clickable Card component
        <button className="report-card__button" onClick={clickHandler}>
            
            {/* Card is a react-bootsrap object  */}
            <Card className="m-2" style={{ width: '15rem' }}>
                <Card.Body>
                    {/* Card Title is from the prop passed in above */}
                    <Card.Title>{title}</Card.Title>
                </Card.Body>
            </Card>
        </button>
    );
}

export default AdminCard;