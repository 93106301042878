import React, { useState, useEffect } from "react";
import TransactionTable from "../../components/TransactionTable/TransactionTable";
import { useParams, Link, useNavigate } from "react-router-dom";
import RateTable from "../../components/RateTable/RateTable";
import OverParkedTable from "../../components/OverParked/OverParkedTable";
import Navigation from "../../components/Navigation/Navigation";
import "./AutomatedDailyReport.scss";
import AutomatedDailyHeader from "../../components/AutomatedDailyHeader/AutomatedDailyHeader";
import { automatedGarageAPI, formatDate } from "./AutomatedDailyReportHelpers";
import LoadingSpinner from "../../components/LoadingWheel/LoadingWheel";
import { PDFExport } from "@progress/kendo-react-pdf";
import EmailComponent from "../../components/EmailComponent/EmailComponent";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import ToolBar from "../../components/ToolBar/ToolBar";
import MonthlyOnlyDailyPage from "../MonthlyOnlyDailyPage/MonthlyOnlyDailyPage";

function AutomatedDailyReportPage() {

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [total, setTotal] = useState(0);
  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false)

  const [parkingSpaces, setParkingSpaces] = useState(null)
  const [garageType, setGarageType] = useState(null)

  const container = React.useRef(null);

  const displayHandler = () => {
    setDisplayForm(!displayForm)
  }

  const { garageName } = useParams();
  console.log("db", garageName);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedDate = formatDate(yesterday);

  const nav = useNavigate()
  const getDailyData = async () => {
    automatedGarageAPI(
      garageName,
      setFailedToLoad,
      setResponse,
      setTotal,
      formattedDate,
      setIsLoading,
      nav
    );
    const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
    setParkingSpaces(JSON.parse(garageInfo.spaces))
    setGarageType(garageInfo.garageType)
  }
  useEffect(() => {
    getDailyData()
  }, []);

  if (failedToLoad) {
    return (
      <p>
        <Link to="/login">login</Link>
      </p>
    );
  }

  return (

    <>
      <Navigation />
      {isSending ? <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box> : ""}
      <div className="report__container">
        <ToolBar
          displayHandler={displayHandler}
          displayForm={displayForm}
          container={container}
          formattedDate={formattedDate}
          garageName={garageName}
          report={"Daily_Report"}
        />
        {displayForm ?
          <EmailComponent
            setIsSending={setIsSending}
            fileName={`${garageName}_Daily_Report_${formattedDate}`}
          />
          : ''
        }
        <div id="PDFExport">
          <PDFExport
            fileName={`${garageName}_Daily_Report_${formattedDate}`}
            forcePageBreak=".page-break"
            scale={0.68}
            paperSize="Letter" margin={{
              top: 15,
              right: 45,
              left: 45
            }}
            ref={container}>
            <p className="daily-report__header">
              {`${garageName} Daily Report for: Yesterday, ${formattedDate}, 12:00AM - 11:59PM`}
            </p>
            {isLoading ? <LoadingSpinner /> :
              <table id="table-to-xls" className="custom-table">
                {
                  garageType === "Monthlies and Transients" ?
                    <>
                      <AutomatedDailyHeader
                        ticketStart={response.ticketStats[0]?.startingTicket}
                        ticketEnd={response.ticketStats[0]?.endingTicket}
                        currentMonthliesIn={response.ticketStats[0]?.currentMonthliesIn}
                        openPrior={response.ticketStats[0]?.openPrior}
                        openTicketsToday={response.ticketStats[0]?.openTicketsToday}
                        closedTickets={response.ticketStats[0]?.closedTickets}
                        spaces={parkingSpaces}
                      />
                      <TransactionTable
                        hourlyTransactionTable={response.hourlyTransactionTable}
                        total={total}
                      />
                      <RateTable garageName={garageName} inDate={formattedDate} outDate={formattedDate} />
                      <OverParkedTable
                        overParkedData={response.overParked}
                        garageName={garageName} />
                    </> :
                    <MonthlyOnlyDailyPage
                      currentMonthliesIn={response.ticketStats[0]?.currentMonthliesIn}
                      spaces={parkingSpaces}
                      hourlyTransactionTable={response.hourlyTransactionTable}
                      overParkedData={response.overParked}
                      garageName={garageName}
                    />
                }
              </table>
            }
          </PDFExport>
        </div>
      </div>
    </>
  );
}

export default AutomatedDailyReportPage;
