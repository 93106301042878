import React, { useState, useEffect, useMemo } from 'react'
import Navigation from '../../components/Navigation/Navigation'
import MaterialReactTable from 'material-react-table';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import { getAllActionLogs } from '../../firebase';
import { Box, Typography } from '@mui/material';

export default function LogsPage() {

  const [isLoading, setIsLoading] = useState(false)
  const [logsData, setLogsData] = useState([])

  const getLogsData = async () => {
    setIsLoading(true)
    const data = await getAllActionLogs()
    console.log(data)
    setIsLoading(false)
    setLogsData(data)
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'action', //access nested data with dot notation
        header: 'Action',
        size: 150,
      },
      {
        accessorKey: 'dateCreated',
        header: 'Date',
        size: 150,
      },
      {
        accessorKey: 'createdBy.userName', //normal accessorKey
        header: 'By',
        size: 200,
      },
      {
        accessorKey: 'createdBy.userCompany', //normal accessorKey
        header: 'Company',
        size: 200,
      },
    ],
    [],
  );

  useEffect(() => {
    getLogsData()
  }, [])

  return (
    <div>
      <Navigation />
      <div className='report__container mt-4'>
        {isLoading ? <LoadingSpinner /> :
          <MaterialReactTable
            // state={}
            columns={columns}
            data={logsData}
            enableDensityToggle={false}
            initialState={{ density: 'compact' }}
            renderDetailPanel={({ row }) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  {Object.keys(row.original.details).map((key) => (
                    <Typography key={key}>
                      {key}: {row.original.details[key]}
                    </Typography>
                  ))}
                </Box>
              );
            }}
          />
        }
      </div>
    </div>
  )
}
