import './FilterByRatePage.scss'
import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import DatePicker from '../../components/DatePicker/DatePicker'
import Button from "react-bootstrap/Button";
import axios from 'axios'
import RatePicker from '../../components/RatePicker/RatePicker';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import Navigation from '../../components/Navigation/Navigation';
import NonRegularChargeTable from '../../components/NonRegularChargeTable/NonRegularChargeTable';
import AllRateTable from '../../components/AllRateTable/AllRateTable';
import RegularRateTable from '../../components/RegularRateTable/RegularRateTable';
import { PDFExport } from "@progress/kendo-react-pdf";
import ToolBar from '../../components/ToolBar/ToolBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EmailComponent from '../../components/EmailComponent/EmailComponent';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";

export default function FilterByRatePage() {

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [loading, setLoading] = useState(false)

    //rate = the rate that was selected, but before the submit button was clicked
    const [rate, setRate] = useState(null)

    //currRate = the rate the table is showing (after the submit button was clicked)
    const [currRate, setCurrRate] = useState(null)
    const [data, setData] = useState(null)

    const [displayForm, setDisplayForm] = useState(false);
    const [isSending, setIsSending] = useState(false)

    const displayHandler = () => {
        setDisplayForm(!displayForm)
    }

    const { garageName } = useParams()

    const container = React.useRef(null);

    const clickHandler = async () => {
        if (rate && startDate && endDate) {
            const token = sessionStorage.getItem('token');

            const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
            const databaseConnection = garageInfo.databaseConnection;
            
            setLoading(true)
            const promise = await axios
                .get(
                    process.env.REACT_APP_FILTER_BY_RATE_URL,
                    //'http://localhost:8080/filterByRate',
                    {
                        params: {
                            rate, 
                            startDate, 
                            endDate, 
                            garageName, 
                            connection: databaseConnection
                        },
                        headers: {
                            authorization: 'Bearer ' + token
                        }
                    })
            if (garageName === "Baxter" && rate === "Early") {
                setCurrRate(25)
            } else if (garageName === "Waverly" && rate === "Early") {
                setCurrRate(15)
            } else {
                setCurrRate(rate)
            }

            setData(promise.data)
            setLoading(false)
        } else {
            alert("please select a rate and start/end date")
        }
    }
    const today = new Date();
    today.setDate(today.getDate());
    const formattedDate = formatDate(today);
    return (
        <div>
            <Navigation />
                {isSending ? <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box> : ""}
                <div className='report__container'>
                <ToolBar
                    displayHandler={displayHandler}
                    displayForm={displayForm}
                    container={container}
                    garageName={garageName}
                    formattedDate={formattedDate}
                    report={"Filtered_By_Rate"}
                />
                <div>
                    <p className='filter-by-rate__header'>{garageName} Filter By Rate Report</p>
                </div>
                {displayForm ?
                    <EmailComponent
                        setIsSending={setIsSending}
                        fileName={`${garageName}_Filter_By_Rate_Report_${formattedDate}`}
                    />
                    : ''
                }
                <DatePicker label={'Starting Date'} setDate={setStartDate} />
                <DatePicker label={'Ending Date'} setDate={setEndDate} />
                <div className='filter-by-rate-buttons'>
                    <RatePicker
                        garage={garageName}
                        setRate={setRate}
                        setData={setData}
                    />
                    <Button onClick={clickHandler} className="display__errors__button">
                        Submit
                    </Button>
                </div>
                {loading ? <LoadingSpinner /> :
                    <>
                        <PDFExport
                            fileName={`${garageName}_Filter_By_Rate_Report_${formattedDate}`}
                            forcePageBreak=".page-break" scale={0.68}
                            paperSize="Letter"
                            margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                            ref={container}>
                            <div id="PDFExport">
                                <div className='rate-table-header'>
                                    <p className='rate-table-header__text'>{garageName} Filter by Rate Report</p>
                                    <p className='rate-table-header__text'><b>From: </b>{startDate} <b>To: </b>{endDate} </p>
                                </div>
                                <table id="table-to-xls" className="custom-table">
                                    {rate === "NC/0" || rate === "Other" ?
                                        <NonRegularChargeTable
                                            data={data} /> :
                                        rate === "All" ?
                                            <AllRateTable
                                                data={data}
                                            />
                                            :
                                            <RegularRateTable
                                                data={data}
                                            />
                                    }
                                </table>
                            </div>
                        </PDFExport>
                    </>
                }
            </div>
        </div>
    )
}
