import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default function AddRateModalBody({ inputs, setInputs }) {

    console.log(inputs)
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value)
    // Update the state based on the input's name
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <div>
      <Form.Group style={{ marginBottom: '0.75rem' }}>
        <Form.Label style={{ marginBottom: '0' }}>Rate Type</Form.Label>
        <Form.Select name="rateType" value={inputs.rateType} onChange={handleInputChange}>
          <option value="Monthlies">Monthly</option>
          <option value="Transient">Transient</option>
        </Form.Select>
      </Form.Group>

      <Form.Group style={{ marginBottom: '0.75rem' }}>
        <Form.Label style={{ marginBottom: '0' }}>Rate Name</Form.Label>
        <Form.Control
          type="text"
          name="rateName"
          value={inputs.rateName}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group style={{ marginBottom: '0.75rem' }}>
        <Form.Label style={{ marginBottom: '0' }}>Dollar Amount</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            //step="0.01" // Set step to 0.01 to allow two decimal places
            name="amount"
            value={inputs.amount}
            onChange={handleInputChange}
            style={{ appearance: 'textfield' }} // Remove up-down arrows
          />
        </InputGroup>
      </Form.Group>
    </div>
  );
}
