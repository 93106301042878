import React from "react";
import Table from "react-bootstrap/Table";
import '../../pages/AutomatedDailyReportPage/AutomatedDailyReport.scss';

export default function MonthlyOnlyFilteredHeader(props) {

    const {
        currentMonthliesIn,
        spaces, 
        utilization, 
        garageName
    } = props;

    return (
        <>
        <h5 className="d-flex justify-content-center">{garageName} Filtered Report Page</h5>
        <Table striped bordered className="table-sm header-mobile">
            <thead>
                <tr className="table-warning">
                    <th>Total Spaces</th>
                    <th>Current M In</th>
                    <th>Free Spaces</th>
                    <th>Reserved for Monthlies</th>
                    <th>Garage Utilization</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{spaces}</td>
                    <td>{currentMonthliesIn}</td>
                    <td>{spaces - (currentMonthliesIn)}</td>
                    <td>0</td>
                    <td>{utilization}%</td>
                </tr>
            </tbody>
        </Table>
        </>
    );
}
