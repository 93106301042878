import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@material-ui/core/Button';
import BarChart from '../BarChart/BarChart';
import PieChart from '../PieChart/PieChart';
import LineChart from '../LineChart/LineChart';
import ListItemText from '@mui/material/ListItemText';

// This is a function to display the barchart, pie chart, and line charts on the summary page
export default function Charts(props) {

    // nav is used to navigate to a new page
    const nav = useNavigate();

    // react state to store the count of in/out cars per hour
    const [counts, setCounts] = useState({})

    // state that toggles when the user clicks the display cars in/cars out button on the top of each summary page
    const [isCarsIn, setIsCarsIn] = useState(true);

    // function used to toggle the isCarsIn state when the button is clicked
    const toggleCars = () => {
        setIsCarsIn((prevIsCarsIn) => !prevIsCarsIn);
    };

    // props that store the current time, the garage Image, in/out data, occupancy data, and URL to select the garage reports
    const { userStatusValue, spaces, time, garageImage, data, occupancyData, urlParam } = props

    // when the garage Image is clicked, it reroutes to the garage report selection page
    const clickHandler = () => {
        nav(`/reportSelect/${urlParam}`);
    };

    // function for getting the data in the correct format for the material UI table
    const convertTo2DArray = (mCountPerhour, tCountPerhour) => {
        
        const result = [];
        const allHours = getAllHours(mCountPerhour, tCountPerhour);

        for (let i = 0; i < allHours.length; i++) {
            const hour = allHours[i];
            const mCountObj = mCountPerhour.find(obj => obj.hourofday === hour);
            const tCountObj = tCountPerhour.find(obj => obj.hourofday === hour);

            const mCount = mCountObj ? mCountObj.countperhour : 0;
            const tCount = tCountObj ? tCountObj.countperhour : 0;
            const time = formatHour(hour);

            result.push([time, mCount, tCount]);
        }
        console.log(result)
        return result;
    }

    // function to find all hours with an in car/out car
    function getAllHours(mCountPerhour, tCountPerhour) {
        const allHours = new Set();

        mCountPerhour.forEach(obj => allHours.add(obj.hourofday));
        tCountPerhour.forEach(obj => allHours.add(obj.hourofday));

        return Array.from(allHours).sort((a, b) => a - b);
    }

    // function that formats the hour from 0-24 to 12AM - 12PM
    const formatHour = (hour) => {
        if (hour === 0) {
            return '12AM';
        } else if (hour < 12) {
            return hour + 'AM';
        } else if (hour === 12) {
            return '12PM';
        } else {
            return (hour - 12) + 'PM';
        }
    }

    function processHourlyCounts(data) {
        const mHourlyCounts = {};
        const tHourlyCounts = {};

        // Process mInDateTimes
        if (data.mTimes) {
            data.mTimes.forEach((entry) => {
                mHourlyCounts[entry.hourofday] = (mHourlyCounts[entry.hourofday] || 0) + entry.countperhour;
            });
        }

        // Process tInDateTimes
        if (data.tTimes) {
            data.tTimes.forEach((entry) => {
                tHourlyCounts[entry.hourofday] = (tHourlyCounts[entry.hourofday] || 0) + entry.countperhour;
            });
        }

        return {
            mHourlyCount: Object.values(mHourlyCounts).reduce((sum, count) => sum + count, 0),
            tHourlyCount: Object.values(tHourlyCounts).reduce((sum, count) => sum + count, 0),
        };
    }

    // useEffect hook runs on initial page render and every time the cars in/out button is clicked
    useEffect(() => {
        if (data.Error) {
            return;
        }
        // the counter either counts the in cars or out cars
        const counter = isCarsIn ? processHourlyCounts({
            tTimes: data?.tInDateTimes,
            mTimes: data?.mInDateTimes
        }) : processHourlyCounts({
            tTimes: data?.tOutDateTimes,
            mTimes: data?.mOutDateTimes
        })
        setCounts(counter)
    }, [isCarsIn])

    let pieChartData = [];
    let barChartData = [];

    if (!data.Error) {
        // format the bar chart data
        barChartData = isCarsIn ?
            [...convertTo2DArray(data?.mInDateTimes, data?.tInDateTimes)] :
            [...convertTo2DArray(data?.mOutDateTimes, data?.tOutDateTimes)];

        // format the pie chart data
        pieChartData = [
            { label: "Monthlies", data: counts && counts.mHourlyCount },
            { label: "Transients", data: counts && counts.tHourlyCount },
        ];
    }

    let openPrior = 0;
    let openToday = 0;
    let currentMonthliesIn = 0;

    if (!data.Error && data?.ticketsIssuedStats[0]) {
        openPrior = data?.ticketsIssuedStats[0].openPrior;
        openToday = data?.ticketsIssuedStats[0].openTicketsToday;
        currentMonthliesIn = data?.ticketsIssuedStats[0].currentMonthliesIn;
    }

    // arrays for line chart data
    let labels = []
    let monthliesOccupancy = []
    let transientsOccupancy = []
    let totalOccupancy = []

    // function to fill the arrays for the line chart data
    occupancyData.forEach(item => {
        
        const label = item["time_part"]
        const monthly = item["Monthlies"];
        const transient = item["Transients"];
        const total = item["Total"];
        labels.push(label);
        monthliesOccupancy.push(monthly);
        transientsOccupancy.push(transient);
        totalOccupancy.push(total);
    });

    if (data.Error) {
        return (
            <div className="error-container">
                <p className="error-message">{data.Error}</p>
            </div>
        );
    }

    return (
        <div className='full__container'>
            <div className='top__container'>
                <div className='left__container'>
                    <h5 className='m-0'>{time}</h5>
                    <img
                        onClick={clickHandler}
                        className='card__image'
                        src={garageImage}
                        alt="Garage"
                        loading="lazy"
                    />
                    {userStatusValue > 0 && <div className='table__metrics__revenue__container'>
                        <p className='table__metrics__revenue__text'>
                            Today's Total Revenue: ${data?.ticketsIssuedStats[0].Total?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) || "0.00"}
                        </p>
                    </div>}
                    <div className='ticket__info__container'>
                        <div>
                            <ListItemText primary="Starting Ticket" secondary={`${data?.ticketsIssuedStats[0].startingTicket || "N/A"}`} />
                            <ListItemText primary="Ending Ticket" secondary={`${data?.ticketsIssuedStats[0].endingTicket || "N/A"}`} />
                            <ListItemText primary="Tickets Issued" secondary={`${data?.ticketsIssuedStats[0].ticketsIssued || 0}`} />
                            <ListItemText primary="Open from Prior" secondary={`${openPrior}`} />
                            <ListItemText primary="Open from Today" secondary={`${openToday}`} />
                        </div>
                        <div>
                            <ListItemText primary="Closed Today" secondary={`${data?.ticketsIssuedStats[0].closedTickets || 0}`} />
                            <ListItemText primary="Current M. In" secondary={`${data?.ticketsIssuedStats[0].currentMonthliesIn}`} />
                            <ListItemText primary="Current T. In" secondary={`${openPrior + openToday}`} />
                            <ListItemText primary="Open Spaces" secondary={`${spaces - (openPrior + openToday + currentMonthliesIn)}`} />
                            <ListItemText primary="Total Spaces" secondary={`${spaces}`} />
                        </div>
                    </div>
                </div>

                <div className='right__container'>
                    <div className='right__container__upper'>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={toggleCars}
                        >
                            {isCarsIn ? 'Display Cars Out Data' : 'Display Cars In Data'}
                        </Button>
                        <PieChart
                            title={isCarsIn ? "Today's Cars In" : "Today's Cars Out"}
                            data={pieChartData}
                            chartId={"Chart1"}
                        />
                    </div>
                    <div className="right__container__lower">
                        <BarChart
                            title={isCarsIn ? "Today's Cars In" : "Today's Cars Out"}
                            data={barChartData}
                        />
                    </div>
                </div>
            </div>
            <div className='bottom__container'>
                <LineChart
                    title={"Today's Occupancy Data"}
                    labels={labels}
                    dataSet1={monthliesOccupancy}
                    dataSet2={transientsOccupancy}
                    dataSet3={totalOccupancy}
                />
            </div>
        </div >
    );
}
