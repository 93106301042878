import React from 'react'
import Table from "react-bootstrap/Table";

export default function AutomomatedFilteredHeader(props) {

  const {headerStats, avgTicketPrice, spaces, utilization } = props

  return (
    <Table striped bordered className="table-sm header-mobile">
      <thead>
        <tr className="table-warning">
          <th>Total Spaces</th>
          <th>Total T. Parked</th>
          <th>Total M. Parked</th>
          <th>Total Parked</th>
          <th>Garage Utilization</th>
          <th>Avg. Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{spaces}</td>
          <td>{headerStats[headerStats.length -1].TInCount}</td>
          <td>{headerStats[headerStats.length -1].MInCount}</td>
          <td>{headerStats[headerStats.length -1].MInCount + headerStats[headerStats.length -1].TInCount}</td>
          <td>{((utilization/spaces)*100).toFixed(2)}%</td>
          <td>${avgTicketPrice}</td>
        </tr>
      </tbody>
    </Table>
  )
}
