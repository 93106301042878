import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Button } from "react-bootstrap";
import TransactionTable from "../../components/TransactionTable/TransactionTable";
import { useParams, Link, useNavigate } from "react-router-dom";
import RateTable from "../../components/RateTable/RateTable";
import OverParkedTable from "../../components/OverParked/OverParkedTable";
import "./AutomatedFilteredReportPage.scss";
import LoadingSpinner from "../../components/LoadingWheel/LoadingWheel";
import Navigation from "../../components/Navigation/Navigation";
import AutomatedFilteredHeader from "../../components/AutomatedFilteredHeader/AutomatedFilteredHeader";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs';
import TransientData from "../../components/TransientData/TransientData";
import ToolBar from "../../components/ToolBar/ToolBar";
import EmailComponent from "../../components/EmailComponent/EmailComponent";
import { PDFExport } from "@progress/kendo-react-pdf";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";
import MonthlyOnlyFilteredPage from "../MonthlyOnlyFilteredPage/MonthlyOnlyFilteredPage";

function AutomatedFilteredReportPage() {

  const [inDate, setInDate] = useState(null);
  const [outDate, setOutDate] = useState(null);
  const [response, setResponse] = useState(null);
  const [failedtoLoad, setFailedtoLoad] = useState(false);
  const [err, setErr] = useState(null);

  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false)
  const [displayForm, setDisplayForm] = useState(false)

  const { garageName } = useParams();

  const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
  const parkingSpaces = (JSON.parse(garageInfo.spaces))
  const garageType = (garageInfo.garageType)

  const container = React.useRef(null);

  const nav = useNavigate()

  const displayHandler = () => {
    setDisplayForm(!displayForm)
  }

  const getData = async () => {
    const token = sessionStorage.getItem("token");

    let data = null;
    if (inDate == null || outDate == null) {
      alert("in/out dates must be selected");
    }
    if (inDate !== null && outDate !== null) {
      try {
        setIsLoading(true);

        const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
        const databaseConnection = garageInfo.databaseConnection;

        const promise = await axios.get(
          process.env.REACT_APP_TRANSACTIONS_URL,
          //"http://localhost:8080/garagedata/transactions",
          {
            params: {
              inDate: inDate,
              outDate: outDate,
              garage: garageName,
              connection: databaseConnection,
              spaces: parkingSpaces,
              garageType
            },
            headers: {
              authorization: "Bearer " + token,
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              //Redirect to the login page if it's an authentication error
              nav('/login');
            }
          })

        data = promise.data;
        console.log(data);
        setResponse(data);
        setIsLoading(false);
        if (data.ticketStats[0].Total != null) {
          const ticketsTotalString = data?.ticketStats[0]?.Total; // Assuming this is a string like "6,610.00"

          // Remove commas from the string and then parse it into a float
          const ticketsTotal = parseFloat(ticketsTotalString.replace(/,/g, ''));

          setTotal(ticketsTotal)
        }
      } catch (err) {
        console.log(err)
        setFailedtoLoad(true);
        setErr(err);
      }
    }
  };

  if (!!failedtoLoad) {
    return (
      <p>
        {err} <Link to="/login">login</Link>
      </p>
    );
  }

  const today = new Date();
  today.setDate(today.getDate());
  const formattedDate = formatDate(today);
  return (
    <>
      <Navigation />
      {isSending ? <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box> : ""}
      <div className="report__container">
        <Tabs
          defaultActiveKey="main"
          id="filtered-report-tabs"
          className="mb-3 mt-3"
        >
          <Tab eventKey="main" title="Main">
            <ToolBar
              displayHandler={displayHandler}
              displayForm={displayForm}
              container={container}
              garageName={garageName}
              formattedDate={formattedDate}
              report={"Filtered_Report"}
            />
            <p className="report_title">{garageName} Garage Filtered Report</p>
            <div>
              <DatePicker label={"In-Date - 12:00AM"} setDate={setInDate} />
              <DatePicker label={"Out-Date - 11:59PM"} setDate={setOutDate} />
            </div>
            <Button className="filtered-button" onClick={getData}>
              Submit{" "}
            </Button>
            {displayForm ?
              <EmailComponent
                setIsSending={setIsSending}
                fileName={`${garageName}_Filtered_Report_${formattedDate}`}
              />
              : ""}
            {isLoading ? (
              <LoadingSpinner />
            ) : response ? (
              <div id="PDFExport">
                <PDFExport
                  fileName={`${garageName}_Filtered_Report_${formattedDate}`}
                  forcePageBreak=".page-break"
                  scale={0.68}
                  paperSize="Letter"
                  margin={{
                    top: 15,
                    right: 45,
                    left: 45
                  }}
                  ref={container}
                >
                  {
                    garageType === "Monthlies and Transients" ?
                      <>
                        <div className='automated-filtered-header'>
                          <p className='automated-filtered-header__text'>{garageName} Filtered Report</p>
                          <p className='automated-filtered-header__text'><b>From: </b>{inDate} <b>To: </b>{outDate}</p>
                        </div>
                        <table id="table-to-xls" className="custom-table">
                          <AutomatedFilteredHeader
                            headerStats={response.hourlyTransactionTable}
                            avgTicketPrice={total === 0 ? 0.00 : (total / response?.ticketStats[0]?.ticketsIssued).toFixed(2)}
                            spaces={parkingSpaces}
                            utilization={(response.ticketStats[0].TransactionCount / response.ticketStats[0].NumberOfDays).toFixed(2)}
                          />
                          <TransactionTable
                            garageName={garageName}
                            inDate={inDate}
                            outDate={outDate}
                            hourlyTransactionTable={response.hourlyTransactionTable}
                            total={total}
                          />

                          <RateTable garageName={garageName} inDate={inDate} outDate={outDate} />
                          <OverParkedTable overParkedData={response.overParked} garageName={garageName} />
                        </table>
                      </> :
                      <MonthlyOnlyFilteredPage
                        currentMonthliesIn={response.ticketStats[0]?.currentMonthliesIn}
                        spaces={parkingSpaces}
                        hourlyTransactionTable={response.hourlyTransactionTable}
                        overParkedData={response.overParked}
                        garageName={garageName}
                        utilization={(response.ticketStats[0].TransactionCount / response.ticketStats[0].NumberOfDays).toFixed(2)}
                      />
                  }
                </PDFExport>
              </div>
            ) : (
              ""
            )}
          </Tab>
          {garageType === "Monthlies and Transients" ?
            <Tab eventKey="transients" title="Transient Data">
              <TransientData setIsSending={setIsSending} />
            </Tab>
            : ""
          }
        </Tabs>
      </div>
    </>
  );

}
export default AutomatedFilteredReportPage;
