import React, { useState } from "react";
import AdminCard from "../../components/AdminCard/AdminCard";
import Navigation from "../../components/Navigation/Navigation";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Card from 'react-bootstrap/Card';
import './AdminMainPanelPage.scss';
import AddCompanyModal from "../../components/AddCompanyModal/AddCompanyModal";
import { UserAuth } from "../../components/Context/AuthContext";

//Admin options to select
const options = [
    {
        title: "Create User",
    },
    {
        title: "Update User",
    },
    {
        title: "Schedules"
    },
    {
        title: "Garages"
    },

];

function AdminMainPanelPage() {

    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false)

    const { 
        userStatusValue, 
        userName, 
        userCompany, 
    } = UserAuth()

    const handleClick = () => {
        setShowAddCompanyModal(true)
    }

    return (
        <>
            <Navigation />
            <div className="cards d-flex justify-content-center">
                <p>Admin Selections</p>

                {/* Loops through the options and createas a clickable card */}
                {
                    options.map((task, index) => (
                        <AdminCard
                            key={index}
                            title={task.title}
                        />
                    ))
                }
                {(userStatusValue > 1) && <button className="report-card__button" onClick={handleClick}>
                    {/* Card is a react-bootsrap object  */}
                    <Card className="m-2" style={{ width: '15rem' }}>
                        <Card.Body>
                            {/* Card Title is from the prop passed in above */}
                            <Card.Title>Add Company</Card.Title>
                        </Card.Body>
                    </Card>
                </button>
                }
                {(userStatusValue > 1) && <AdminCard
                            key={'logs'}
                            title={'Logs'}
                        />
                }
                <AddCompanyModal
                    showAddCompanyModal={showAddCompanyModal}
                    setShowAddCompanyModal={setShowAddCompanyModal}
                    userName={userName}
                    userCompany={userCompany}
                />
            </div>
        </>
    );
}

export default AdminMainPanelPage;
