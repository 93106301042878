import React, { useState, useEffect } from 'react';
import './CreateUserPage.scss';
import { v4 as uuid } from 'uuid';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, getAllCompanies } from '../../firebase';
import CRUDConfirmationModal from '../../components/CRUDConfirmationModal/CRUDConfirmationModal';
import { writeUserData } from '../../firebaseHelpers/userHelpers';
import Navigation from '../../components/Navigation/Navigation';
import { Button, Form } from 'react-bootstrap';
import { userSchema } from '../../Validations/userValidations';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import { UserAuth } from '../../components/Context/AuthContext';

export default function CreateUserPage() {
    const { userName, userCompany } = UserAuth()
    
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        name: '',
        email: '',
        company: '',
        password: '',
        confirm: '',
        userType: '',
    });
    const [showUserCreatedModal, setShowUserCreatedModal] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [errors, setErrors] = useState({
        name: null,
        email: null,
        company: null,
        password: null,
        confirm: null,
        userType: null,
    });

    const handleClose = () => setShowUserCreatedModal(false);

    const handleShow = () => {
        setForm({
            name: '',
            email: '',
            company: '',
            password: '',
            confirm: '',
            userType: '',
        });
        setErrors({});
        setShowUserCreatedModal(true);
    };

    const authApp = initializeApp(firebaseConfig, 'authApp');
    const detachedAuth = getAuth(authApp);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await userSchema.validate(form, { abortEarly: false });
            submitForm(event);
        } catch (error) {
            const errors = {};
            error.inner.forEach((e) => {
                errors[e.path] = e.message;
            });
            setErrors(errors);
        }
    };

    const submitForm = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let unique_id = uuid();
        await createUserWithEmailAndPassword(detachedAuth, form.email, form.password);
        writeUserData(unique_id, form.name, form.email, form.userType, form.company, userName, userCompany);
        setIsLoading(false);
        handleShow();
    };

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        });
    };

    const getCompanyList = async () => {
        let companies = await getAllCompanies();
        setCompanies(companies);
    };

    useEffect(() => {
        getCompanyList();
    }, []);

    return (
        <div>
            {showUserCreatedModal && (
                <CRUDConfirmationModal
                    show={showUserCreatedModal}
                    handleClose={handleClose}
                    body={'New User Created'}
                />
            )}

            <Navigation />
            <div className="report__container">
                <Form className="new-user-form" onSubmit={handleSubmit}>
                    <h6 className="new-user-form__header">Create New User</h6>
                    <Form.Group className="user-input">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="First Last"
                            value={form.name}
                            onChange={(e) => setField('name', e.target.value)}
                            name="fullName"
                            isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="user-input">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email Address"
                            value={form.email}
                            onChange={(e) => setField('email', e.target.value)}
                            name="email"
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="user-input">
                        <Form.Label>Company:</Form.Label>
                        <Form.Control
                            as="select"
                            value={form.company}
                            onChange={(e) => setField('company', e.target.value)}
                            name="company"
                            isInvalid={!!errors.company}
                        >
                            {userCompany === "Automotion Parking LLC" ?
                                <>
                                    <option value="">Select Company</option>
                                    {companies.map((company, index) => (
                                        <option key={index} value={company}>
                                            {company}
                                        </option>
                                    ))}
                                </>:
                                <>
                                    <option value="">Select Company</option>
                                    <option value={userCompany}>{userCompany}</option>
                                </>
                            }
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="user-input">
                        <Form.Label>Password:</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={form.password}
                            onChange={(e) => setField('password', e.target.value)}
                            name="password"
                            isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="user-input">
                        <Form.Label>Confirm Password:</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            value={form.confirm}
                            onChange={(e) => setField('confirm', e.target.value)}
                            name="confirmPassword"
                            isInvalid={!!errors.confirm}
                        />
                        <Form.Control.Feedback type="invalid">{errors.confirm}</Form.Control.Feedback>
                    </Form.Group>
                    <p className="radio-buttons-title">User Type:</p>
                    <Form.Group className="radio-buttons">
                        <div className="radio-selectios">
                            <div className="type-radio">
                                <Form.Check
                                    className="type-input"
                                    type="radio"
                                    label="Admin"
                                    value="Admin"
                                    name="userType"
                                    checked={form.userType === "Admin"}
                                    onChange={(e) => setField('userType', e.target.value)}
                                    isInvalid={!!errors.userType}
                                />
                            </div>
                            <div className="type-radio">
                                <Form.Check
                                    className="type-input"
                                    type="radio"
                                    label="Tech"
                                    value="Tech"
                                    name="userType"
                                    checked={form.userType === "Tech"}
                                    onChange={(e) => setField('userType', e.target.value)}
                                    isInvalid={!!errors.userType}
                                />
                            </div>
                            {form.company === "Automotion Parking LLC" && <div className="type-radio">
                                <Form.Check
                                    className="type-input"
                                    type="radio"
                                    label="Super Admin"
                                    value="Super Admin"
                                    name="userType"
                                    checked={form.userType === "Super Admin"}
                                    onChange={(e) => setField('userType', e.target.value)}
                                    isInvalid={!!errors.userType}
                                />
                            </div>}
                        </div>
                        <Form.Control.Feedback type="invalid">{errors.userType}</Form.Control.Feedback>
                    </Form.Group>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <Button type="submit" className="button">
                            Create User
                        </Button>
                    )}
                </Form>
            </div>
        </div>
    );
}
