import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate} from "react-router-dom";
import axios from 'axios';
import Navigation from '../../components/Navigation/Navigation';
import MonthliesRevenueTable from '../../components/MonthliesRevenueTable/MonthliesRevenueTable';
import './MonthliesRevenuePage.scss';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import MonthliesRevenueConflictsQBTable from '../../components/MonthliesRevenueConflictsQBTable/MonthliesRevenueConflictsQBTable';
import MonthliesRevenueConflictsGSTable from '../../components/MonthliesRevenueConflictsGSTable/MonthliesRevenueConflictsGSTable';
import { PDFExport } from "@progress/kendo-react-pdf";
import ToolBar from '../../components/ToolBar/ToolBar';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import EmailComponent from '../../components/EmailComponent/EmailComponent';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";

export default function MonthliesRevenuePage() {

  const [payments, setPayments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [displayForm, setDisplayForm] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const displayHandler = () => {
    setDisplayForm(!displayForm);
  };

  const { garageName } = useParams();

  const container = React.useRef(null);

  const nav = useNavigate()

  const getMonthliesRevenue = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.get(process.env.REACT_APP_GOOGLE_SHEETS_URL, {
        params: {
          garageName,
        },
        headers: {
          authorization: 'Bearer ' + token,
        },
      })
      .catch(error => {
        if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
        }
      })
      sessionStorage.setItem(`${garageName}-monthliesRevenue`, JSON.stringify(response.data));
      setPayments(response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const typeParam = searchParams.get('type');
  useEffect(() => {
    setType(typeParam);
    const data = JSON.parse(sessionStorage.getItem(`${garageName}-monthliesRevenue`));

    if (data) {
      setPayments(data);
      setIsLoading(false);
    } else {
      getMonthliesRevenue();
    }
  }, [typeParam]);

  const typeHandler = (e) => {
    setType(e.target.value);
    setSearchParams({ type: e.target.value });
  };

  let statusIndex;
  let fobIndex;
  let nameIndex;
  let typeIndex;
  let rateIndex;
  let QBNameIndex;
  let QBFobIndex;
  let QBRateIndex;
  switch (garageName) {
    case 'Baxter':
      nameIndex = 0;
      fobIndex = 2;
      statusIndex = 5;
      typeIndex = 13;
      rateIndex = 17;
      QBNameIndex = 27;
      QBRateIndex = 28;
      QBFobIndex = 29;
      break;
    case 'VanVorst':
      nameIndex = 1;
      fobIndex = 4;
      statusIndex = 5;
      typeIndex = 12;
      rateIndex = 18;
      QBNameIndex = 26;
      QBRateIndex = 27;
      QBFobIndex = 28;
      break;
    case 'Waverly':
      nameIndex = 0;
      fobIndex = 3;
      statusIndex = 4;
      typeIndex = 11;
      rateIndex = 17;

      QBNameIndex = 26;
      QBRateIndex = 27;
      QBFobIndex = 28;
      break;
    case '24th Street':
      nameIndex = 0;
      fobIndex = 2;
      statusIndex = 3;
      typeIndex = 11;
      rateIndex = 15;
      QBNameIndex = 27;
      QBRateIndex = 28;
      QBFobIndex = 29;
      break;
    default:
      // Default case
      break;
  }

  const today = new Date();
  today.setDate(today.getDate());
  const formattedDate = formatDate(today);
  return (
    <div>
      <Navigation />
      {isSending ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        ""
      )}
      <div className="report__container">
        <ToolBar displayHandler={displayHandler} displayForm={displayForm} container={container} garageName={garageName} formattedDate={formattedDate} report={"Monthlies_Revenue"} />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            {displayForm ? (
              <EmailComponent setIsSending={setIsSending} fileName={`${garageName}_Monthlies_Revenue_${formattedDate}`} />
            ) : (
              ''
            )}
            <div className='monthlies-header'>
              <select name="reports" onChange={(e) => typeHandler(e)}>
                <option value="Select">Select Report</option>
                <option value="Active">Active</option>
                <option value="Conflicts (Not in QuickBooks)">Conflicts - not on QB</option>
                <option value="Conflicts (Not in GoogleSheets)">Conflicts - not on GS</option>
              </select>
            </div>
            <PDFExport
              fileName={`${garageName}_Monthlies_Revenue_${formattedDate}`}
              forcePageBreak=".page-break"
              scale={0.68}
              paperSize="Letter"
              margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
              ref={container}
            >
              <div id="PDFExport">
                <div className='monthlies_revenue_header'>
                  <p className='monthlies_revenue_header__text'>{garageName} Monthly Revenue - {type}</p>
                </div>
                <table id="table-to-xls" className="custom-table">
                  {type === "Active" ? (
                    <div className='monthlies'>
                      <MonthliesRevenueTable
                        payments={payments}
                        fobStatusIndex={statusIndex}
                        fob={fobIndex}
                        name={nameIndex}
                        type={typeIndex}
                        rate={rateIndex}
                      />
                    </div>
                  ) : type === "Conflicts (Not in QuickBooks)" ? (
                    <div className='monthlies'>
                      <MonthliesRevenueConflictsQBTable
                        payments={payments}
                        fobStatusIndex={statusIndex}
                        fob={fobIndex}
                        name={nameIndex}
                        type={typeIndex}
                        rate={rateIndex}
                        QBFobIndex={QBFobIndex}
                        QBNameIndex={QBNameIndex}
                      />
                    </div>
                  ) : type === "Conflicts (Not in GoogleSheets)" ? (
                    <div className='monthlies'>
                      <MonthliesRevenueConflictsGSTable
                        payments={payments}
                        fobStatusIndex={statusIndex}
                        fob={fobIndex}
                        name={nameIndex}
                        type={typeIndex}
                        rate={rateIndex}
                        QBFobIndex={QBFobIndex}
                        QBRateIndex={QBRateIndex}
                        QBNameIndex={QBNameIndex}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </table>
              </div>
            </PDFExport>
          </div>
        )}
      </div>
    </div>
  );
}
