import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Navigation from '../../components/Navigation/Navigation';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import deleteIcon from '../../assets/deleteIcon.png';
import editIcon from '../../assets/editIcon.png';
import AddNewGarageModal from '../../components/AddNewGarageModal/AddNewGarageModal';
import EditPriceModal from '../../components/EditPriceModal/EditPriceModal';
import AddRateModal from '../../components/AddRateModal/AddRateModal';
import DeleteGarageModal from '../../components/DeleteGarageModal/DeleteGarageModal';
import { UserAuth } from '../../components/Context/AuthContext';
import GarageFeedback from '../../components/GarageFeedback/GarageFeedback';
import { getAllCompaniesAndGarages, getGarageMetaData } from '../../firebase';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import './AdminGaragesPage.scss';
import EditGarageDataModal from '../../components/EditGarageDataModal/EditGarageDataModal';
import DeleteCompanyModal from '../../components/DeleteCompanyModal/DeleteCompanyModal';

export default function AdminGaragesPage() {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewGarageModal, setShowNewGarageModal] = useState(false);
    const [showEditPriceModal, setShowEditPriceModal] = useState(false);
    const [showEditGarageDataModal, setShowEditGarageDataModal] = useState(false)
    const [garageName, setGarageName] = useState('');
    const [showAddRateModal, setShowAddRateModal] = useState(false);
    const [garageForUpdates, setGarageForUpdates] = useState('');
    const [garageToBeDeleted, setGarageToBeDeleted] = useState('');
    const [companyForUpdate, setCompanyForUpdate] = useState('')
    const [garageToEdit, setGarageToEdit] = useState('')
    const [companyToEdit, setCompanyToEdit] = useState('')
    const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false)
    const [companyToDelete, setCompanyToDelete] = useState('')

    const [openSimpleSnackbar, setOpenSimpleSnackbar] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({
        text: '',
        severity: 'success',
    });

    const [companiesAndGarages, setCompaniesAndGarages] = useState({});
    const { userStatusValue, userCompany, userName } = UserAuth();

    const [isLoading, setIsLoading] = useState(true)

    const handleEditPrice = async (garage, company) => {
        setCompanyForUpdate(company)
        setOpenSimpleSnackbar(false); // Close the Snackbar
        setGarageName(garage);
        setShowEditPriceModal(true);
    };

    const handleAddNewGarage = () => {
        setOpenSimpleSnackbar(false); // Close the Snackbar
        setShowNewGarageModal(true);
    };

    const handleCloseNewGarageModal = () => {
        setShowNewGarageModal(false);
    };

    const handleAddPendingDeleteGarage = (garage, company) => {
        setCompanyForUpdate(company)
        setOpenSimpleSnackbar(false); // Close the Snackbar
        setGarageToBeDeleted(garage);
        setShowDeleteModal(true);
    };

    const handleAddRateClick = (garage, company) => {
        setOpenSimpleSnackbar(false); // Close the Snackbar
        setCompanyForUpdate(company)
        setGarageForUpdates(garage);
        setShowAddRateModal(true);
    };

    const handleEditGarage = async (garage, company) => {
        console.log(garage, company)
        setShowEditGarageDataModal(true)
        setGarageToEdit(garage)
        setCompanyToEdit(company)
    }

    const handleCloseEditGarageDataModal = () => {
       setShowEditGarageDataModal(false) 
    }

    const getData = async () => {
        setIsLoading(true)
        let allData = await getAllCompaniesAndGarages();
        setCompaniesAndGarages(allData);
        setIsLoading(false)
    };

    const deleteCompanyModal = (companyName) => {
        setShowDeleteCompanyModal(true)
        setCompanyToDelete(companyName)
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <React.Fragment>
            <Navigation />
            {isLoading ? <LoadingSpinner /> :
                <div className="report__container">
                    {/* only Automotion Super admins can access this feature */}
                    {userStatusValue > 1 && userCompany === "Automotion Parking LLC" && (
                        <h4 className="add__new__garage">
                            Add New Garage
                            <AddCircleIcon
                                style={{ cursor: "pointer", margin: "12px" }}
                                color="success"
                                fontSize="large"
                                onClick={handleAddNewGarage}
                            />
                        </h4>
                    )}
                    <GarageFeedback
                        openSimpleSnackbar={openSimpleSnackbar}
                        setOpenSimpleSnackbar={setOpenSimpleSnackbar}
                        snackbarInfo={snackbarInfo}
                    />
                    {showEditGarageDataModal && (
                        <EditGarageDataModal
                            garage={garageToEdit}
                            company={companyToEdit}
                            showEditGarageDataModal={showEditGarageDataModal}
                            handleCloseEditGarageDataModal={handleCloseEditGarageDataModal}
                            userName={userName}
                            userCompany={userCompany}
                        />
                    )}
                    {showAddRateModal && (
                        <AddRateModal
                            showAddRateModal={showAddRateModal}
                            setShowAddRateModal={setShowAddRateModal}
                            garageName={garageForUpdates}
                            companyForUpdate={companyForUpdate}
                            setOpenSimpleSnackbar={setOpenSimpleSnackbar}
                            setSnackbarInfo={setSnackbarInfo}
                            userName={userName}
                            userCompany={userCompany}
                        />
                    )}

                    {showDeleteModal && (
                        <DeleteGarageModal
                            show={showDeleteModal}
                            setShowDeleteModal={setShowDeleteModal}
                            garageName={garageToBeDeleted}
                            setOpenSimpleSnackbar={setOpenSimpleSnackbar}
                            setSnackbarInfo={setSnackbarInfo}
                            companyForUpdate={companyForUpdate}
                            userName={userName}
                            userCompany={userCompany}
                            companiesAndGarages={companiesAndGarages}
                            setCompaniesAndGarages={setCompaniesAndGarages}
                        />
                    )}

                        <DeleteCompanyModal
                            show={showDeleteCompanyModal}
                            setShowDeleteModal={setShowDeleteCompanyModal}
                            companyName={companyToDelete}
                            userName={userName}
                            userCompany={userCompany}
                        />

                    {/* for super admins */}
                    {userStatusValue === 2 && Object.keys(companiesAndGarages).map((company, i) => (
                        <div key={i}>
                            <div className='d-flex'>
                                <h3 className='company__title'>{company}</h3>
                                <button 
                                    onClick={()=>deleteCompanyModal(company)}
                                    type="button" 
                                    className="btn btn-danger p-1 m-3"
                                >
                                    Delete 
                                </button>
                            </div>
                            <ul className="company__garage__list">
                                {companiesAndGarages[company].map((garage, j) => (
                                    <div className="edit-user d-flex justify-content-between" key={j}>
                                        <p className="edit-user_email">{garage}</p>
                                        <div>
                                            <img
                                                className="editGarage__icon"
                                                onClick={() => handleEditGarage(garage, company)}
                                                src={editIcon}
                                                style={{ cursor: "pointer" }}
                                            />
                                            <AddCircleIcon
                                                fontSize="large"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleAddRateClick(garage, company)}
                                            />
                                            <MonetizationOnIcon
                                                onClick={() => handleEditPrice(garage, company)}
                                                style={{ cursor: "pointer", margin: "12px" }}
                                                color="success"
                                                fontSize="large"
                                            />
                                            <img
                                                className="delete-user_button"
                                                onClick={() => handleAddPendingDeleteGarage(garage, company)}
                                                src={deleteIcon}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    ))}

                    {/* the page for admins */}
                    {userStatusValue === 1 &&
                        <ul className="company__garage__list">
                            <h4 className='mt-4'>{userCompany}</h4>
                            {companiesAndGarages[userCompany].map((garage, j) => (
                                <div className="company__garage__list__item" key={j}>
                                    <p className="m-0">{garage}</p>
                                    <div>
                                        <AddCircleIcon
                                            fontSize="large"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleAddRateClick(garage, userCompany)}
                                        />
                                        <MonetizationOnIcon
                                            onClick={() => handleEditPrice(garage, userCompany)}
                                            style={{ cursor: "pointer", margin: "12px" }}
                                            color="success"
                                            fontSize="large"
                                        />
                                        <img
                                            className="delete-user_button"
                                            onClick={() => handleAddPendingDeleteGarage(garage, userCompany)}
                                            src={deleteIcon}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </ul>
                    }

                    <AddNewGarageModal
                        show={showNewGarageModal}
                        handleClose={handleCloseNewGarageModal}
                        setOpenSimpleSnackbar={setOpenSimpleSnackbar}
                        setSnackbarInfo={setSnackbarInfo}
                        companiesAndGarages={companiesAndGarages}
                        setCompaniesAndGarages={setCompaniesAndGarages}
                        userName={userName}
                        userCompany={userCompany}
                    />

                    {garageName && (
                        <EditPriceModal
                            garageName={garageName}
                            showEditPriceModal={showEditPriceModal}
                            setShowEditPriceModal={setShowEditPriceModal}
                            setSnackbarInfo={setSnackbarInfo}
                            setOpenSimpleSnackbar={setOpenSimpleSnackbar}
                            companyForUpdate={companyForUpdate}
                            userName={userName}
                            userCompany={userCompany}
                        />
                    )}
                </div>
            }
        </React.Fragment>
    );
}
