import { getAuth } from "@firebase/auth";
import axios from 'axios';
import { initializeApp } from "firebase/app";
import { v4 as uuid } from 'uuid';
import { doc, deleteDoc, setDoc, updateDoc, getDoc, getFirestore, collection, getDocs, query } from "firebase/firestore";
import { addImageToFirebaseStorage } from "./firebaseStorage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(app);

function formatNumberToTwoDecimals(number) {
  console.log(number)
  // Use the toFixed method to round the number to 2 decimal places
  const roundedNumber = number.toFixed(2);

  // Convert the result back to a number (from a string)
  return parseFloat(roundedNumber);
}

//function to create task in scheduler
async function addToTaskScheduler(id, selectedGarages, type, time, subject, recipients, userName, userStatusValue) {
  try {
    const docRef = await setDoc(doc(firestoreDB, `tasks`, id), {
      createdBy: userName,
      userStatusValue,
      selectedGarages,
      type,
      time,
      subject,
      recipients,
      worker: "sendEmailAPI",
    });
    console.log("Document written with ID: ");
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function getSuperAdminEmails() {
  const querySnapshot = await getDocs(collection(firestoreDB, "companies", 'Automotion Parking LLC', 'Super Admins'));
  let allSuperAdmins = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    allSuperAdmins.push(doc.data().email)
  });
  return allSuperAdmins;
}

//function to add schedule in the real-time database
async function makeSchedule(selectedGarages, type, time, subject, recipients, userName, userStatusValue) {
  console.log(selectedGarages)
  let unique_id = uuid();
  await addToTaskScheduler(unique_id, selectedGarages, type, time, subject, recipients, userName, userStatusValue)
}

async function deleteSchedule(scheduleId) {
  console.log(scheduleId)
  await deleteDoc(doc(firestoreDB, "tasks", scheduleId));
};

async function editSchedule(scheduleId, time, recipients, subject) {
  const scheduleRef = doc(firestoreDB, "tasks", scheduleId);
  await updateDoc(scheduleRef, {
    time,
    recipients,
    subject
  });
}

//function to edit garage data
async function getGarageMetaData(garage, company) {
  console.log(garage, company)
  try {
    const docRef = doc(firestoreDB, 'companies', company, "Garages", garage);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data()
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  } catch (e) {
    console.log(e)
    alert("Error: " + e.message)
  }
}

//function to get list of companies 
async function getAllCompanies() {
  const q = (collection(firestoreDB, "companies"));
  let companies = []
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    companies.push(doc.id);
  });
  return companies
}

async function updateGarage(newGarageObject, noNewImageProvided) {
  console.log(newGarageObject)
  const { garageName, selectedCompany, databaseConnection, spaces, garageType, selectedImage } = newGarageObject //console.log(newGarageObject)
  // Get the garage document.
  const garageRef = doc(firestoreDB, "companies", selectedCompany, "Garages", garageName);

  let imageURL = noNewImageProvided ? selectedImage : await addImageToFirebaseStorage(garageName, selectedImage)
  await setDoc(garageRef, {
    data: {
      garageName,
      selectedCompany,
      databaseConnection,
      spaces,
      garageType,
      selectedImage: imageURL
    },
  });
}

///
async function addGarageToCompany(newGarageObject, userName, userCompany) {

  const { garageName, selectedCompany, databaseConnection, spaces, garageType, selectedImage } = newGarageObject //console.log(newGarageObject)
  // Get the garage document.
  console.log(newGarageObject)
  const actionDetails = {
    action: "Garage Created",
    details: {
      Name: garageName,
    },
    createdBy: {
      userName,
      userCompany
    },
    dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
  }

  try {
    await saveAction(actionDetails)
  } catch (e) {
    console.log(e)
  }

  console.log(selectedImage)
  const garageRef = doc(firestoreDB, "companies", selectedCompany, "Garages", garageName);
  const garageDoc = await getDoc(garageRef);

  // If the garage document doesn't exist, create it.
  const docExists = garageDoc.exists();
  if (!docExists) {
    let imageURL = await addImageToFirebaseStorage(garageName, selectedImage)
    await setDoc(garageRef, {
      data: {
        garageName,
        selectedCompany,
        databaseConnection,
        spaces,
        garageType,
        selectedImage: imageURL
      },
    });
  }
  return docExists;
}

async function saveNewRate(companyId, garageName, newRateObject, userEmail) {

  const unique_id = uuid();
  const { rateName, amount, rateType } = newRateObject;
  console.log(newRateObject)
  console.log(rateName)
  const formattedNewValue = formatNumberToTwoDecimals(parseFloat(amount))
  // Create the data object
  const data = {
    company: companyId,
    requestedBy: userEmail,
    Garage: garageName,
    Request: "Add New Rate",
    rateName,
    rateType,
    Price: formattedNewValue,
    dateSubmitted: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
  };

  const GarageDocRef = doc(firestoreDB, "companies", companyId, "Pending Changes", unique_id);

  // Prepare the email content
  const emailContent = `<div>
    <table>
      <tbody>
        <tr><strong>Company:</strong> ${data.company}</tr>
        <tr><strong>Requested By:</strong> ${data.requestedBy}</tr>
        <tr><strong>Request:</strong> ${data.Request}</tr>
        <tr><strong>Garage:</strong> ${data.Garage}</tr>
        <tr><strong>Rate Type:</strong> ${data.rateType}</tr>
        <tr><strong>Rate Name:</strong> ${data.rateName}</tr>
        <tr><strong>Price:</strong> $${data.Price}</tr>
        <tr><strong>Request Submit Date:</strong> ${data.dateSubmitted}</tr>
      </tbody>
    </table>
  </div>`;

  try {
    const token = sessionStorage.getItem('token');
    let emailList = await getSuperAdminEmails()
    // Send the email
    await axios.post('https://automotion-heroku-server.herokuapp.com/emailGenerator/pendingChangeEmail', {
      to: emailList,
      message: {
        text: 'This is the plaintext section of the email body.',
        html: emailContent,
      },
    }, {
      headers: {
        authorization: 'Bearer ' + token,
      },
    });

    // Email sent successfully, now update the database
    await setDoc(GarageDocRef, data);

    return true; // Return true if both operations were successful
  } catch (error) {
    // Handle the error
    console.error(error);
    return false; // Return false if any operation fails
  }
}

//function to add a pending edited rate 
async function addPendingEditedRate(companyId, garageName, rateType, editedRateObject, userEmail, userName, userCompany) {
  const { rateName, rateCurrentValue, rateNewValue } = editedRateObject
  const formattedNewValue = formatNumberToTwoDecimals(parseFloat(rateNewValue))
  const unique_id = uuid();
  const GarageDocRef = doc(firestoreDB, "companies", companyId, "Pending Changes", unique_id);

  // Create the data object
  const data = {
    company: companyId,
    requestedBy: userEmail,
    Garage: garageName,
    Request: "Edit Existing Rate",
    rateName,
    rateType,
    oldPrice: rateCurrentValue,
    newPrice: formattedNewValue,
    dateSubmitted: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
  };

  // Prepare the email content
  const emailContent = `<div>
    <table>
      <tbody>
        <tr><strong>Company:</strong> ${data.company}</tr>
        <tr><strong>Requested By:</strong> ${data.requestedBy}</tr>
        <tr><strong>Request:</strong> ${data.Request}</tr>
        <tr><strong>Garage:</strong> ${data.Garage}</tr>
        <tr><strong>Rate Type:</strong> ${data.rateType}</tr>
        <tr><strong>Rate Name:</strong> ${data.rateName}</tr>
        <tr><strong>Current Price:</strong> $${data.oldPrice}</tr>
        <tr><strong>Suggested New Price:</strong> $${data.newPrice}</tr>
        <tr><strong>Request Submition Date:</strong> ${data.dateSubmitted}</tr>
      </tbody>
    </table>
  </div>`;

  try {
    const token = sessionStorage.getItem('token');
    let emailList = await getSuperAdminEmails()
    // Execute both operations in parallel
    await Promise.all([
      axios.post('https://automotion-heroku-server.herokuapp.com/emailGenerator/pendingChangeEmail', {
        to: emailList,
        message: {
          text: 'This is the plaintext section of the email body.',
          html: emailContent,
        },
      }, {
        headers: {
          authorization: 'Bearer ' + token
        }
      }),
      setDoc(GarageDocRef, data),
    ]);
    const actionDetails = {
      action: "Edit Rate Request",
      details: {
        'Garage Name': garageName,
        'Rate Name': rateName,
        'Rate Type': rateType,
        'Old Price': `$${rateCurrentValue}`,
        'New Price': `$${formattedNewValue}`,
      },
      createdBy: {
        userName,
        userCompany
      },
      dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
    }
    try {
      await saveAction(actionDetails)
    } catch (e) {
      console.log(e)
    }
    return true
  } catch (error) {
    console.error("Error occurred: ", error);
    return false
  }
}

async function addAcceptRequestToLogs(requestObject, userName, userCompany) {
  const actionDetails = {
    action: "Request Accepted",
    details: {
      ...requestObject
    },
    createdBy: {
      userName,
      userCompany
    },
    dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
  }
  try {
    await saveAction(actionDetails)
  } catch (e) {
    console.log(e)
  }
}

//function to add pending delete garage in Firebase
async function addPendingDeleteGarage(companyId, garageName, userEmail, userName, userCompany) {
  console.log(companyId, garageName)
  const unique_id = uuid()

  const GarageDocRef = doc(firestoreDB, "companies", companyId, "Pending Changes", unique_id)

  // Create the data object
  const data = {
    company: companyId,
    requestedBy: userEmail,
    Garage: garageName,
    Request: "Delete Garage",
    dateSubmitted: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
  };

  const emailContent = `<div>
  <table>
    <tbody>
      <tr><strong>Company:</strong> ${data.company}</tr>
      <tr><strong>Requested By:</strong> ${data.requestedBy}</tr>
      <tr><strong>Request:</strong> ${data.Request}</tr>
      <tr><strong>Garage:</strong> ${data.Garage}</tr>
      <tr><strong>Request Submition Date:</strong> ${data.dateSubmitted}</tr>
    </tbody>
  </table>
</div>`;
  try {
    const token = sessionStorage.getItem('token');
    let emailList = await getSuperAdminEmails()
    // Execute both operations in parallel
    await Promise.all([
      axios.post('https://automotion-heroku-server.herokuapp.com/emailGenerator/pendingChangeEmail', {
        to: emailList,
        message: {
          text: 'This is the plaintext section of the email body.',
          html: emailContent,
        },
      },
        {
          headers: {
            authorization: 'Bearer ' + token
          }
        }),
      setDoc(GarageDocRef, data)

    ]);
    const actionDetails = {
      action: "Delete Garage Request",
      details: {
        Garage: garageName,
      },
      createdBy: {
        userName,
        userCompany
      },
      dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
    }

    try {
      await saveAction(actionDetails)
    } catch (e) {
      console.log(e)
    }
    return true
  } catch (error) {
    console.error("Error occurred: ", error);
    return false
  }
}

//function to add pending delete garage in Firebase
async function deleteRate(companyId, garageName, rateName, rate, userEmail) {
  console.log(companyId, garageName, rateName)
  const unique_id = uuid()
  const GarageDocRef = doc(firestoreDB, "companies", companyId, "Pending Changes", unique_id)

  // Create the data object
  const data = {
    company: companyId,
    requestedBy: userEmail,
    Garage: garageName,
    rateName,
    Price: rate,
    Request: "Delete Rate",
    dateSubmitted: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
  };

  const emailContent = `<div>
  <table>
    <tbody>
      <tr><strong>Company:</strong> ${data.company}</tr>
      <tr><strong>Requested By:</strong> ${data.requestedBy}</tr>
      <tr><strong>Request:</strong> ${data.Request}</tr>
      <tr><strong>Garage:</strong> ${data.Garage}</tr>
      <tr><strong>Rate To Delete:</strong> ${data.rateName}</tr>
      <tr><strong>Rate Price:</strong> $${data.Price}</tr>
      <tr><strong>Request Submition Date:</strong> ${data.dateSubmitted}</tr>
    </tbody>
  </table>
</div>`;
  try {
    const token = sessionStorage.getItem('token');
    let emailList = await getSuperAdminEmails()
    // Execute both operations in parallel
    await Promise.all([
      axios.post('https://automotion-heroku-server.herokuapp.com/emailGenerator/pendingChangeEmail', {
        to: emailList,
        message: {
          text: 'This is the plaintext section of the email body.',
          html: emailContent,
        },
      }, {
        headers: {
          authorization: 'Bearer ' + token
        }
      }),
      setDoc(GarageDocRef, data),
    ]);
    return true
  } catch (error) {
    console.error("Error occurred: ", error);
    return false
  }
}

//function to get list of all garages and their connections in a company
async function getAllGarages(companyId) {
  const garagesSnapshot = await getDocs(collection(firestoreDB, "companies", companyId, "Garages"));
  let garageInfo = []
  garagesSnapshot.forEach((doc) => {
    console.log(doc.id, " => ", doc.data());
    garageInfo.push([doc.id, doc.data().data])
  });
  return garageInfo
}

// Function to get all company notifications
async function getAllNotifications(userStatusValue, userCompany) {
  let allNotifications = [];
  let companies = [];
  if (userStatusValue > 1) {
    // Get list of companies
    const querySnapshot = await getDocs(collection(firestoreDB, "companies"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      companies.push(doc.id);
    });
    // Get pending changes from each company
    for (const company of companies) {
      const notificationsSnapshot = await getDocs(
        collection(firestoreDB, "companies", company, "Pending Changes")
      );
      if (notificationsSnapshot) {
        notificationsSnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          allNotifications.push({ docId: doc.id, ...doc.data() });
        });
      }
    }
  } else {
    const notificationsSnapshot = await getDocs(
      collection(firestoreDB, "companies", userCompany, "Pending Changes")
    );
    if (notificationsSnapshot) {
      notificationsSnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        allNotifications.push({ docId: doc.id, ...doc.data() });
      });
    }
  }
  return allNotifications;
}

async function deleteGarageFromFB(companyId, garageName, userName, userCompany) {
  const actionDetails = {
    action: "Garage Deleted",
    details: {
      Garage: garageName,
    },
    createdBy: {
      userName,
      userCompany
    },
    dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
  }

  try {
    await saveAction(actionDetails)
  } catch (e) {
    console.log(e)
  }

  await deleteDoc(doc(firestoreDB, "companies", companyId, "Garages", garageName));
  return
}

async function deleteCompany(companyName, userName, userCompany) {
  const actionDetails = {
    action: "Company Deleted",
    details: {
      Company: companyName,
    },
    createdBy: {
      userName,
      userCompany
    },
    dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
  }

  try {
    await saveAction(actionDetails)
  } catch (e) {
    console.log(e)
  }
  await deleteDoc(doc(firestoreDB, "companies", companyName));

}

async function deletePendingRequest(requestId, companyId, userName, userCompany, selectedRowObject) {
  const actionDetails = {
    action: "Request Rejected",
    details: {
      ...selectedRowObject
    },
    createdBy: {
      userName,
      userCompany
    },
    dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
  }

  try {
    await saveAction(actionDetails)
  } catch (e) {
    console.log(e)
  }
  console.log(requestId)
  await deleteDoc(doc(firestoreDB, "companies", companyId, "Pending Changes", requestId));
};

async function sendRejectPendingRequestEmail(dataObject, rejectionNote) {
  let dataArr = Object.entries(dataObject);
  console.log(dataArr);

  let dataHtml = dataArr.map(entry => `<tr><strong>${entry[0]}: </strong> ${entry[1]}</tr><br/>`)
  console.log(dataHtml);

  console.log(dataObject.requestedBy)
  const emailContent = `<strong>CHANGE REJECTED</strong>
  <div>
  <table>
  <tbody>
      ${dataHtml.join('')}
      <p>${rejectionNote}</p>
    </tbody>
  </table>
</div>`;

  try {
    const token = sessionStorage.getItem('token');
    // Execute both operations in parallel
    await Promise.all([
      axios.post('https://automotion-heroku-server.herokuapp.com/emailGenerator/pendingChangeEmail', {
        //axios.post('http://localhost:8080/emailGenerator/pendingChangeEmail', {
        to: `${dataObject.requestedBy}`,
        //to: 'shmuelweinfeld@gmail.com',
        message: {
          text: 'This is the plaintext',
          html: emailContent,
        },
      }, {
        headers: {
          authorization: 'Bearer ' + token
        }
      }),
    ]);
  } catch (error) {
    console.error("Error occurred: ", error);
    alert("Error: " + error.message)
  }
}

async function sendAcceptPendingRequestEmail(dataObject) {
  console.log(dataObject)
  let dataArr = Object.entries(dataObject);
  console.log(dataArr);

  let dataHtml = dataArr.map(entry => `<tr><strong>${entry[0]}: </strong> ${entry[1]}</tr><br/>`)
  console.log(dataHtml);

  const emailContent = `
  <strong>CHANGE ACCEPTED!</strong>
  <div>
    <table>
      <tbody>
        ${dataHtml.join('')}
      </tbody>
    </table>
  </div>`;
  try {
    const token = sessionStorage.getItem('token');
    // Execute both operations in parallel
    await Promise.all([
      axios.post('https://automotion-heroku-server.herokuapp.com/emailGenerator/pendingChangeEmail', {
        //axios.post('http://localhost:8080/emailGenerator/pendingChangeEmail', {
        to: `${dataObject.requestedBy}`,
        //to: 'shmuelweinfeld@gmail.com',
        message: {
          text: 'This is the plaintext',
          html: emailContent,
        },
      }, {
        headers: {
          authorization: 'Bearer ' + token
        }
      }),
    ]);
  } catch (error) {
    console.error("Error occurred: ", error);
    alert("Error: " + error.message)
  }
}

async function findCompanyBasedOnGarageName(garageName) {
  const companiesCollection = collection(firestoreDB, "companies");
  const companiesSnapshot = await getDocs(companiesCollection);
  for (const companyDoc of companiesSnapshot.docs) {
    const companyId = companyDoc.id;
    const companyGarages = await getGarages(companyId);
    for (const garage of companyGarages) {
      if (garage === garageName) {
        console.log(companyId)
        return companyId
      }
    }
  }
}

async function getAllCompaniesAndGarages() {
  const companiesCollection = collection(firestoreDB, "companies");
  const companiesSnapshot = await getDocs(companiesCollection);
  let allCompaniesAndGarages = {};

  for (const companyDoc of companiesSnapshot.docs) {
    const companyId = companyDoc.id;
    const companyGarages = await getGarages(companyId);

    allCompaniesAndGarages[companyId] = companyGarages;
  }

  return (allCompaniesAndGarages);
}

async function getGarages(company) {
  const garagesSnapshot = await getDocs(collection(firestoreDB, 'companies', company, 'Garages'));
  const garages = [];

  garagesSnapshot.forEach((doc) => {
    garages.push(doc.id);
  });

  return garages;
}

async function saveAction(actionDetails) {
  console.log(actionDetails)
  let actionId = uuid()
  setDoc(doc(firestoreDB, `companies/${actionDetails.createdBy.userCompany}/actions`, actionId), {
    ...actionDetails
  });
}

async function getAllActionLogs() {
  const q = collection(firestoreDB, "companies", "Automotion Parking LLC", "actions");
  const querySnapshot = await getDocs(q);
  let logDocs = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    logDocs.push(doc.data());
  });
  return logDocs
}

async function saveNewCompany(companyName, userName, userCompany) {
  const actionDetails = {
    action: "Company Created",
    details: {
      Company: companyName,
    },
    createdBy: {
      userName,
      userCompany
    },
    dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
  }

  try {
    await saveAction(actionDetails)
  } catch (e) {
    console.log(e)
  }

  const companyRef = doc(firestoreDB, "companies", companyName);
  const garageDoc = await getDoc(companyRef);

  // If the garage document doesn't exist, create it.
  const docExists = garageDoc.exists();
  if (!docExists) {
    // Add a new document in collection "cities"
    await setDoc(doc(firestoreDB, "companies", companyName), {

    })
  }
  return docExists;
}

export {
  makeSchedule,
  editSchedule,
  deleteSchedule,
  addToTaskScheduler,
  firebaseConfig,
  firestoreDB,
  addGarageToCompany,
  saveNewRate,
  addPendingEditedRate,
  addPendingDeleteGarage,
  deleteCompany,
  deleteRate,
  getAllCompanies,
  getAllNotifications,
  deleteGarageFromFB,
  deletePendingRequest,
  sendRejectPendingRequestEmail,
  sendAcceptPendingRequestEmail,
  getAllCompaniesAndGarages,
  getGarageMetaData,
  updateGarage,
  getAllGarages,
  findCompanyBasedOnGarageName,
  saveNewCompany,
  getAllActionLogs,
  saveAction,
  addAcceptRequestToLogs
}

export const auth = getAuth(app)
export default app