import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function LineChart({ title, labels, dataSet1, dataSet2, dataSet3 }) {
  const chartRef = useRef(null); // Create a ref to store the chart instance

  useEffect(() => {
    // Create the chart data
    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Monthlies',
          data: dataSet1,
          fill: false,
          borderColor: 'rgba(0, 61, 122, 0.6)',
          backgroundColor: 'rgba(0, 61, 122, 0.4)',
          tension: 0.1,
        },
        {
          label: 'Transients',
          data: dataSet2,
          fill: false,
          borderColor: 'rgba(128, 128, 128, 0.5)',
          tension: 0.1,
        },
        {
          label: 'Total',
          data: dataSet3,
          fill: false,
          borderColor: 'rgba(1, 1, 1, 0.9)',
          backgroundColor: 'rgba(1, 1, 1, 0.7)',
          tension: 0.1,
        },
      ],
    };

    // Destroy the previous chart instance, if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Create a new chart instance
    const ctx = document.getElementById('line-chart').getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false, // Remove fixed aspect ratio
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
            title: {  // Adding the title plugin
              display: true,
              text: title, // Set the title text from the props
            },
            legend: {
              position: 'bottom',
            },
          },
      },
    });

    // Cleanup: Destroy the chart when the component is unmounted
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [labels, dataSet1, dataSet2]);

  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      <canvas id="line-chart" width="100%" height="200"></canvas>
    </div>
  );
}
