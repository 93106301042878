import axios from 'axios';
const automatedGarageAPI = async (garageName, automatedSetFailedtoLoad, setResponse, setTotal, formattedDate, setIsLoading, nav) => {
    console.log(formattedDate);

  let data = null;
  const token = sessionStorage.getItem('token');
  const garageInfo = JSON.parse(localStorage.getItem(`${garageName}Info`))
  const databaseConnection = garageInfo.databaseConnection;
  const spaces = garageInfo.spaces; 
  const garageType = garageInfo.garageType;

  try {
    const promise = await axios.get(
      process.env.REACT_APP_TRANSACTIONS_URL,
      {
        params: {
          inDate: formattedDate,
          outDate: formattedDate,
          garage: garageName,
          connection: databaseConnection, 
          spaces, 
          garageType,
        },
        headers: {
          authorization: 'Bearer ' + token
        }

      })
      .catch(error => {
        if (error.response.status === 401) {
            // Redirect to the login page if it's an authentication error
            nav('/login');
        }
    })
    data = promise.data;
    console.log(data);
    setResponse(data);
    setIsLoading(false)
    if (data.ticketStats[0].Total != null) {
      setTotal(data.ticketStats[0].Total);
    }
  } catch (err) {
    console.log(err)
    automatedSetFailedtoLoad(true);
  }
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
}

export {padTo2Digits, formatDate, automatedGarageAPI};