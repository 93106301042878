import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { removeUserData } from '../../firebaseHelpers/userHelpers';
import axios from 'axios'
import LoadingSpinner from '../LoadingWheel/LoadingWheel';
import { saveAction } from '../../firebase';

// confirmation modal for deleting a user
function DeleteUsersModal({
  show,
  userToDelete,
  handleClose,
  setUserData,
  userName, 
  userCompany
}) {

  const [isDeletionLoading, setIsDeletionLoading] = useState(false)

  // function to delete the user from both firebase DBs
  const applyHandler = async () => {

    //get the JWT token from session storage
    const token = sessionStorage.getItem('token');

    //delete from auth users database
    try {
      setIsDeletionLoading(true)
      let deletedFromAuth = await axios
        .post(
          //"http://localhost:8080/admin/delete"
          process.env.REACT_APP_ADMIN_DELETE_URL
          , {
            email: userToDelete.email
          },
          {
            headers: {
              authorization: 'Bearer ' + token
            }
          })

      if (deletedFromAuth.status === 201) {
        // firebase function to delete user from the firestore DB
        const actionDetails = {
          action: "User Deleted",
          details: {
            'Deleted User': userToDelete.username,
          },
          createdBy: {
              userName,
              userCompany
          },
          dateCreated: new Date().toLocaleString('en-us', { timezone: 'Americal/New_York' })
      }
  
      try {
          await saveAction(actionDetails)
      } catch (e) {
          console.log(e)
      }
        await removeUserData(userToDelete.company, userToDelete.type, userToDelete.id)

        setIsDeletionLoading(false)
        // close the pop up modal
        handleClose()

        setUserData(prevUserData => {
          // Create a new copy of userData with the userToDelete removed from the corresponding company's user list
          const updatedUserData = { ...prevUserData };
          updatedUserData[userToDelete.company][`${userToDelete.type}s`] = 
          updatedUserData[userToDelete.company][`${userToDelete.type}s`]?.filter(user => user?.id != userToDelete?.id)
          return updatedUserData;
        });
        
      }
    } catch (e) {
      handleClose()
      console.log(e)
      setIsDeletionLoading(false)
      alert("Error deleting user")
      return
    }
  }

  return (

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
          <Modal.Body>
            { isDeletionLoading ? <LoadingSpinner /> 
              : `Are you sure you want to delete: ${userToDelete?.username}?`
            }
          </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={applyHandler}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteUsersModal