import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './ErrorLogsPage.scss';
import axios from 'axios';
import { Table, Button, Collapse } from 'react-bootstrap';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import Navigation from '../../components/Navigation/Navigation';
import DatePicker from '../../components/DatePicker/DatePicker';
import { Chart } from "react-google-charts";
import downButton from '../../assets/downButton.png';
import EmailComponent from '../../components/EmailComponent/EmailComponent';
import ToolBar from '../../components/ToolBar/ToolBar';
import { PDFExport } from '@progress/kendo-react-pdf';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { formatDate } from "../../pages/AutomatedDailyReportPage/AutomatedDailyReportHelpers";

function ErrorLogsPage() {
    const [expanded, setExpanded] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [errorLogs, setErrorLogs] = useState([])
    const [mostRecentDate, setMostRecentUpdate] = useState(null)
    const [isLoading, setIsloading] = useState(false)
    const [isLastUpdateLoading, setIsLastUpdateLoading] = useState(true)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isPieChartVisible, setPieChartVisible] = useState(false);
    const [isBarChartVisible, setBarChartVisible] = useState(false);
    const [displayForm, setDisplayForm] = useState(false);
    const [isSending, setIsSending] = useState(false)

    const container = React.useRef(null);

    const displayHandler = () => {
        setDisplayForm(!displayForm)
    }

    const { garageName } = useParams()

    const getErrors = async () => {
        setIsloading(true)
        try {
            //get the JWT token from session storage
            const token = sessionStorage.getItem('token');
            
            const response = await axios
                .get(
                    //"http://localhost:8080/googleSheets/errorLogs"
                    process.env.REACT_APP_ERROR_LOG_URL
                    , {
                        params: {
                            startDate,
                            endDate,
                            garageName
                        }, 
                        headers: {
                            authorization: 'Bearer ' + token
                        }
                    })
            setErrorLogs(response.data)
            setIsloading(false)
        } catch (e) {
            console.log(e)
        }
    }

    const findLastUpdate = async () => {
        try {
            //get the JWT token from session storage
            const token = sessionStorage.getItem('token');

            const response = await axios
                .get(
                    //"http://localhost:8080/googleSheets/lastUpdate"
                    process.env.REACT_APP_LAST_ERROR_LOG_URL
                    , {
                        params: {
                            garageName
                        }, 
                        headers: {
                            authorization: 'Bearer ' + token
                        }
                    })
            setMostRecentUpdate(response.data)
            setIsLastUpdateLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        findLastUpdate()
    }, [])
    const newArray = [];

    errorLogs && errorLogs.forEach(obj => {
        const key = `${obj.MsgNumber}-${obj.MsgText}`;
        const [number, message] = key.split('-');
        if (!newArray.find(o => o.number === number && o.message === message)) {
            newArray.push({
                number,
                message,
                dates: [obj.TimeString]
            });
        } else {
            newArray.find(o => o.number === number && o.message === message).dates.push(obj.TimeString);
        }
    });

    const sortedErrorArray = newArray.sort((a, b) => a.number - b.number);
    console.log("sortedErrorArray", sortedErrorArray)

    const counts = errorLogs && errorLogs.reduce((counts, errorLog) => {
        const key = errorLog.MsgNumber
        counts[key] = (counts[key] || 0) + 1;
        return (counts);
    }, {});

    const countArray = (Object.entries(counts));
    const totalErrorCount = countArray.reduce((totalErrorCount, count) => {
        return totalErrorCount + count[1]
    }, 0)
    const data = [
        ["Error Number", "Count"],
        ...countArray
    ];

    const options = {
        title: "Frequency of Error Numbers",
        sliceVisibilityThreshold: 0.01, // 1%
    };

    const today = new Date();
    today.setDate(today.getDate());
    const formattedDate = formatDate(today);

    return (
        <div>
            <Navigation />
                {isSending ? <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box> : ""}
            <div className="report__container">
                <ToolBar
                    displayHandler={displayHandler}
                    displayForm={displayForm}
                    container={container}
                    garageName={garageName}
                    formattedDate={formattedDate}
                    report={"Error_Logs"}

                />
                {!isLastUpdateLoading ? <h6 className='last__update'>{garageName} Errors Last Update: {mostRecentDate}</h6> : <div className="update__loader"><LoadingSpinner /><h5> ...Finding Last Update</h5></div>}
                {displayForm ?
                    <EmailComponent
                        setIsSending={setIsSending}
                        fileName={`${garageName}_Errors_Report_${formattedDate}`}
                    />
                    : ''
                }
                <DatePicker label={"Start-Date 12:00 AM"} setDate={setStartDate} />
                <DatePicker label={"End-Date 11:59 PM"} setDate={setEndDate} />
                <div>
                    <Button className="m-2" variant="primary" onClick={getErrors}>
                        Submit
                    </Button>
                    <Button
                        className="m-2"
                        variant="primary"
                        onClick={() => setPieChartVisible(!isPieChartVisible)}
                    >
                        {isPieChartVisible ? "Hide Pie Chart" : "Show Pie Chart"}
                    </Button>
                    <Button
                        className="m-2"
                        variant="primary"
                        onClick={() => setBarChartVisible(!isBarChartVisible)}
                    >
                        {isBarChartVisible ? "Hide Bar Chart" : "Show Bar Chart"}
                    </Button>
                </div>
                <PDFExport
                    fileName={`${garageName}_Errors_Report_${formattedDate}`}
                    forcePageBreak=".page-break" scale={0.68}
                    paperSize="Letter"
                    margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
                    ref={container}>
                    <div id="PDFExport">
                        <div>
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : (
                            <>
                                <div>
                                <h4>{garageName} Total Errors: {totalErrorCount > 0 && totalErrorCount.toLocaleString(undefined, {
                                })}</h4>
                                {isPieChartVisible && (
                                    <Chart
                                        chartType="PieChart"
                                        data={data}
                                        options={options}
                                        width={"100%"}
                                        height={"400px"}
                                    />
                                )}
            
                                {isBarChartVisible && (
                                    <Chart
                                        chartType="Bar"
                                        width="100%"
                                        height="400px"
                                        data={data}
                                        options={options}
                                    />
                                )}
                            </div>
                                <table id="table-to-xls" className="custom-table">
                                    <Table striped bordered className="report table-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Message</th>
                                                <th>
                                                    {/* <img width='25px' src={graphIcon} /> */}
                                                    Total
                                                </th>
                                                <th>
                                                    {/* <img width='25px' src={calendarIcon} /> */}
                                                    Days
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedErrorArray.map((error, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr className="error__row">
                                                            <td className="error__row__data">{error.number}</td>
                                                            <td>{error.message}</td>
                                                            <td>{error.dates.length}</td>
                                                            <td>
                                                                <span onClick={() => {
                                                                    if (expanded && selectedRow === index) {
                                                                        setExpanded(!expanded);
                                                                    } else {
                                                                        setExpanded(true);
                                                                        setSelectedRow(index);
                                                                    }
                                                                }}
                                                                className="error__dates"
                                                                >
                                                                {expanded && selectedRow === index ? '-' : '+'}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="expanded-row" colSpan="5">
                                                                <Collapse in={expanded && selectedRow === index}>
                                                                    <ul>
                                                                        {error.dates.map((date, i) => (
                                                                            <li className="list-unstyled" key={i}>
                                                                                {date}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Collapse>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </table>
                            </>)}
                        </div>
                    </div>
                </PDFExport>
            </div>
        </div>
    );
}

export default ErrorLogsPage;