import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import { getStorage, ref, deleteObject } from "firebase/storage";

function DeleteModal(props) {
  const { folderName, file, show, setShowDeleteModal } = props

  const handleClose = () => setShowDeleteModal(false);

  const confirmDeleteHandler = (e) => {
    //e.preventDefault()

    const storage = getStorage();
    const deleteRef = ref(storage, `${folderName}/${file}`);

    // Delete the file
    deleteObject(deleteRef).then(() => {
      // File deleted successfully
      handleClose()
      // alert('file uploaded!');
      window.location.reload()
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error)
    });
  }
  
  return (

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete: "{file}"?</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={confirmDeleteHandler}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal