import React from 'react'
import Table from 'react-bootstrap/Table'
import './SchemehornVolumeComponent.scss'

export default function SchemehornVolumeComponent(props) {

    const {dateRange, header, hoursRows, totals} = props
  return (
    <div>
      <p className="schemerhorn__summary__header">{header}</p>
      <p className="schemerhorn__summary__header">{dateRange}</p>
      <Table striped bordered className="table-sm table-font schemehorn">
        <thead>
          <tr className="table-warning">
            <th>Hour</th>
            <th>Park In</th>
            <th>Park Out</th>
            <th>Productivity</th>
          </tr>
        </thead>
        <tbody>
          {hoursRows.map((row, i) => {
            return (
              <tr key={i}>
                <td>{row.hour}</td>
                <td>{row.parkIn}</td>
                <td>{row.parkOut}</td>
                <td>{row.productivity}</td>
              </tr>
            );
          })}
          <tr className="table-success">
            <td>
              <b>Totals:</b>
            </td>
            <th>{totals.parkInTotal}</th>
            <th>{totals.parkOutTotal}</th>
            <th>{totals.productivityTotal}</th>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
