import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { listFiles } from '../../firebaseStorage'
import './ResourceFiles.scss'
import UploadModal from '../../components/UploadModal/UploadModal';
import ResourceFilesComponent from '../../components/ResourceFilesComponent/ResourceFilesComponent';



export default function ResourceFiles() {

    const { folderName } = useParams()

    const [files, setFiles] = useState(null)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getData = async () => {
        let res = await listFiles(folderName)
        console.log("res", res)
        setFiles(res)
        console.log("length", res.length)
    }

    useEffect(() => {
        getData()
    }, []);

    const clickHandler = (file) => {
        window.location.href = `${file}`;
    }

    return (
        show ?
            <>
                <UploadModal
                    show={show}
                    handleShow={handleShow}
                    handleClose={handleClose}
                    folderName={folderName}
                />
                <ResourceFilesComponent
                    folderName={folderName}
                    handleShow={handleShow}
                    files={files}
                    clickHandler={clickHandler}
                />
            </>
            :
            <ResourceFilesComponent
                folderName={folderName}
                handleShow={handleShow}
                files={files}
                clickHandler={clickHandler}
            />
    )
}
