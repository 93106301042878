import React, { useState, useEffect } from 'react';
import { listFolders } from '../../firebaseStorage';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import Navigation from '../../components/Navigation/Navigation';
import FolderCard from '../../components/FolderCard/FolderCard';
import LoadingSpinner from '../../components/LoadingWheel/LoadingWheel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './ResourcesFolderPage.scss';

export default function ResourcesFolderPage() {
  const [folders, setFolders] = useState(null);
  const [folderName, setFolderName] = useState(null);

  const getData = async () => {
    let res = await listFolders();
    setFolders(res);
  };

  useEffect(() => {
    getData();
  }, []);

  const addFolderHandler = (e) => {
    e.preventDefault();

    const storage = getStorage();
    const storageRef = ref(storage, `${folderName}/`);

    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, `test.pdf`).then((snapshot) => {
      console.log('folder created!');
    });
  };

  return (
    <>
      <Navigation />
      {folders ? (
        <div>
          <h4 className="folder_page_header">Resource Folders</h4>
          <div className="folder_page cards d-flex justify-content-center">
            {folders.map((folder, index) => {
              console.log(folder);
              return <FolderCard key={index} name={folder} />;
            })}
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}
