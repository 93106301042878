import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function PieChart({ title, data, chartId }) {
  const chartRef = useRef(null); // Create a ref to store the chart instance

  useEffect(() => {
    // Extracting data from the given format
    const labels = data.map(item => item.label);
    const dataset = data.map(item => item.data);

    // Create the chart data
    const chartData = {
      labels: labels,
      datasets: [{
        data: dataset,
        backgroundColor: ['rgba(0, 61, 122, 0.5)', 'rgba(128, 128, 128, 0.4)', 'rgba(0, 0, 0, 0.9)'],
        borderColor: ['rgba(0, 61, 122, 1)', 'rgba(128, 128, 128, 1)', 'rgba(256, 256, 256, 1)'],
        borderWidth: 1,
      }],
    };

    // Destroy the previous chart instance, if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Create a new chart instance
    const ctx = document.getElementById(chartId);
    chartRef.current = new Chart(ctx, {
      type: 'pie',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false, // Remove fixed aspect ratio
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              padding: 20, // Adjust the padding around the legend
            },
          },
          title: {
            display: true,
            text: title,
            fontSize: 16,
            fontColor: '#333', // You can customize the title font color here
            padding: { top: 10, bottom: 20 } // Adjust the title's padding
          }
        },
      },
    });

    // Cleanup: Destroy the chart when the component is unmounted
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data, chartId, title]);

  return (
    <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
      <canvas id={chartId} width="100%" height="200"></canvas>
    </div>
  );
}
